import React, { Component } from "react";
import { Row, Col, Form, Modal, Button, Spinner } from "react-bootstrap";

class ModalInvite extends Component {
  state = {};

  render() {
    return (
      <React.Fragment>
        <Modal
          onEntered={this.handleOpen}
          show={this.props.showInviteModal}
          onHide={this.props.handleClose}
          dialogClassName="editModal"
        >
          <Form onSubmit={this.props.onFormSubmitInviteUser}>
            <Modal.Header closeButton>
              <h3>Invite a new User</h3>
            </Modal.Header>
            <Modal.Body>
              <div style={{ fontSize: "13px", fontWeight: "500" }}>
                <Row>
                  <Col md={12}>
                    <Row>
                      <Col md={6}>
                        <Form.Group controlId="formGroupEmail">
                          <Form.Label>Email address</Form.Label>
                          <Form.Control type="email" name="teamEmail" placeholder="Enter email" />
                        </Form.Group>

                        <Form.Group controlId="formGroupTeamName">
                          <Form.Label>Team Name</Form.Label>
                          <Form.Control type="text" name="teamName" placeholder="Enter Team Name" />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </Modal.Body>

            <Modal.Footer>
              {this.props.isInvitingNewUser === true ? (
                <Spinner animation="border" role="status"></Spinner>
              ) : (
                <Button variant="primary" type="submit">
                  Invite
                </Button>
              )}
            </Modal.Footer>
          </Form>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ModalInvite;
