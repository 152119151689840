import React, { Component } from "react";
import { Row, Col, Form, Modal, Button, Spinner } from "react-bootstrap";

class ModalAdd extends Component {
  state = {};

  render() {
    return (
      <Modal show={this.props.showAddModal} onHide={this.props.handleClose} dialogClassName="editModal">
        <Form onSubmit={this.props.onFormSubmitAddUser}>
          <Modal.Header closeButton>
            <h3>Add New User</h3>
          </Modal.Header>
          <Modal.Body>
            <div style={{ fontSize: "13px", fontWeight: "500" }}>
              <Row>
                <Col md={12}>
                  <Row>
                    <Col md={this.props.teams !== false && this.props.teams.length >= 1 ? 4 : 6}>
                      <Form.Group controlId="formFirstName">
                        <Form.Label>First Name *</Form.Label>
                        <Form.Control required type="text" name="firstName" />
                      </Form.Group>
                    </Col>
                    <Col md={this.props.teams !== false && this.props.teams.length >= 1 ? 4 : 6}>
                      <Form.Group controlId="formLastName">
                        <Form.Label>Last Name *</Form.Label>
                        <Form.Control required type="text" name="lastName" />
                      </Form.Group>
                    </Col>

                    {this.props.teams !== false && this.props.teams.length >= 1 && (
                      <Col md={4}>
                        <Form.Group controlId="formTeamId">
                          <Form.Label>Team</Form.Label>
                          <Form.Control as="select" name="teamId">
                            {this.props.teams.map((teamGroup) => {
                              return (
                                <option key={"teamGroup_" + teamGroup.id} value={teamGroup.id}>
                                  {teamGroup.team_name}
                                </option>
                              );
                            })}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                    )}
                  </Row>

                  <Row>
                    <Col md={6}>
                      <Form.Group controlId="formTriggerBatch">
                        <Form.Label>Trigger Batch</Form.Label>
                        <Form.Control as="select" name="triggerBatch">
                          {this.props.triggerBatches.map((triggerBatch) => {
                            return (
                              <option key={"triggerbatch_" + triggerBatch.id} value={triggerBatch.id}>
                                {triggerBatch.name}
                              </option>
                            );
                          })}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId="formTeamId">
                        <Form.Label>Shipping Rate Plan</Form.Label>
                        <Form.Control as="select" name="shippingPlanId">
                          <option value={0}>Choose One</option>
                          {this.props.shippingPlans.map((shippingPlan) => {
                            return <option value={shippingPlan.id}>{shippingPlan.plan_name}</option>;
                          })}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6}>
                      <Form.Group controlId="formEmail">
                        <Form.Label>Email Address *</Form.Label>
                        <Form.Control required type="email" name="emailAddress" />
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group controlId="formPassword">
                        <Form.Label>Password *</Form.Label>
                        <Form.Control required type="text" name="password" />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={4}>
                      <Form.Group controlId="formPerPoundShipment">
                        <Form.Label>Per Pound Shipment Rate</Form.Label>
                        <Form.Control type="number" step="0.01" placeholder="0.3" name="perPound" />
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group controlId="formMWSSeller">
                        <Form.Label>MWS Seller ID</Form.Label>
                        <Form.Control
                          type="text"
                          name="mwsSellerId"
                          defaultValue={this.props.adminData.mws_seller_id}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group controlId="formMWSAuth">
                        <Form.Label>MWS Auth</Form.Label>
                        <Form.Control
                          type="text"
                          name="mwsAuthToken"
                          defaultValue={this.props.adminData.mws_auth_token}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row style={{ marginTop: "20px" }}>
                    <Col md={3}>
                      <Form.Check type="switch" defaultChecked={true} name="isActive" id="is-active" label="Active?" />
                    </Col>
                    <Col md={3}>
                      <Form.Check type="switch" name="isHiddenMode" id="is-hidden" label="Hidden Mode?" />
                    </Col>
                    <Col md={3}>
                      <Form.Check
                        type="switch"
                        name="isSalesRank"
                        id="is-show-rank"
                        label="Show Rank?"
                        defaultChecked={true}
                      />
                    </Col>
                    <Col md={3}>
                      <Form.Check
                        type="switch"
                        name="isInfoBoxes"
                        id="is-info-boxes"
                        label="InfoBoxes?"
                        defaultChecked={true}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {this.props.invalidUserAddMessage !== "" && (
              <div className="mr-auto" style={{ color: "red", fontSize: "12.5px" }}>
                {this.props.invalidUserAddMessage}
              </div>
            )}
            {this.props.inProgressAdd === true ? (
              <Spinner animation="border" role="status"></Spinner>
            ) : (
              <Button variant="primary" type="submit">
                Save Changes
              </Button>
            )}
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

export default ModalAdd;
