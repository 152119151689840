export const DummySKUList = [
  {
    QuantityShipped: ["2"],
    ReleaseDate: ["2009-11-18"],
    ShipmentId: ["FBA15JD78WYJ"],
    PrepDetailsList: [
      {
        PrepDetails: [
          {
            PrepOwner: ["SELLER"],
            PrepInstruction: ["Labeling"],
          },
        ],
      },
    ],
    FulfillmentNetworkSKU: ["X002D00YSJ"],
    SellerSKU: ["Cherished-Dec17-3"],
    QuantityReceived: ["0"],
    QuantityInCase: ["0"],
  },
  {
    QuantityShipped: ["12"],
    ReleaseDate: ["2009-11-18"],
    ShipmentId: ["FBA15JD78WYJ"],
    PrepDetailsList: [
      {
        PrepDetails: [
          {
            PrepOwner: ["SELLER"],
            PrepInstruction: ["Labeling"],
          },
        ],
      },
    ],
    FulfillmentNetworkSKU: ["X002D00YSJ"],
    SellerSKU: ["Cherished-Dec17-8"],
    QuantityReceived: ["0"],
    QuantityInCase: ["0"],
  },
];
