import React, { Component } from "react";
import TopMenu from "../components/TopMenu";
import { Row, Col, Container, FormControl, Spinner, Button, Form, ProgressBar } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import {
  getStatsCount,
  getStatsRejects,
  getSources,
  getTimeSeries,
  getUsers,
  getRawStats,
  fetchTeamList,
  updateMaxProfitAPI,
  abortRequests,
  getBenchmark,
} from "../includes/HelperDashboard";
import { PieChart, LineChart } from "react-chartkick";
import "chart.js";
import "../assets/style/theme_minimal.css";
import Select from "react-select";
import auth from "../Auth";
import CustomContentProgressbar from "../components/Others/CustomCircularProgressbar";
import "react-dates/initialize";
import { DateRangePicker, isInclusivelyBeforeDay } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import moment from "moment";
moment().format();

class Dashboard extends Component {
  state = {
    start_date: "",
    end_date: "",
    selectedUsers: [],
    selectedTeams: [],
    scan_data: [],
    isSavingMaxProfit: false,
    sourcesData: [],
    selectedSources: [],
    filtered_scan_data: [],
    log_sources: [],
    users_list: [],
    list_user_or_team: "users",
    teams_list: null,
    isLoadingSources: false,
    isLoadingUsers: false,
    isLoadingTeams: false,
    excludeDuplicate: false,
    currentTSDFrequency: "day",
    total_scans: 0,
    timeSeriesData: [],
    apiProgress: 0,
    amz_vs_marketplace: { count: 0, profit: 0 },
    mod_count: {
      amazon: { count: -1, profit: -1 },
      marketplace: { count: -1, profit: -1 },
      amazon_ca: { count: -1, profit: -1 },
      marketplace_ca: { count: -1, profit: -1 },
      bookstore: { count: 0, profit: 0 },
      bookscouter: { count: 0, profit: 0 },
      wholesale: { count: 0, profit: 0 },
      hitlist: { count: 0, profit: 0 },
      rejects: { count: 0 },
    },
    max_profit: 0,
    columns: [
      {
        dataField: "Name",
        text: "Name",
        headerStyle: { cursor: "pointer", outline: "none" },
        footer: "",
      },
    ],
    scan_counts: { today: -1, week: -1, month: -1 },
    reject_counts: { today: -1, week: -1, month: -1 },
    isLoadingTableStats: false,
    admin_data: auth.getAdminData(),
  };

  handleLogout() {
    auth.logout(() => {
      this.props.history.push("/");
    });
  }

  getFormattedNumber(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  getFormattedDate(dt) {
    var dd = dt.getDate();
    var mm = dt.getMonth() + 1;

    var yyyy = dt.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }

    return yyyy + "-" + mm + "-" + dd;
  }

  constructor() {
    super();
    this.handleChangeStartDate = this.handleChangeStartDate.bind(this);
    this.handleChangeEndDate = this.handleChangeEndDate.bind(this);
    this.handleSourcesChange = this.handleSourcesChange.bind(this);
    this.handleSelectedUsersChange = this.handleSelectedUsersChange.bind(this);
    this.handleSelectedTeamsChange = this.handleSelectedTeamsChange.bind(this);
    this.onToggleUsersTeams = this.onToggleUsersTeams.bind(this);
    this.handleExcludeDuplicateToggle = this.handleExcludeDuplicateToggle.bind(this);
    this.onChangeFrequency = this.onChangeFrequency.bind(this);
  }

  componentDidMount() {
    // Check if it's a direct link with token parameter

    console.log(this.state.admin_data);
    if (this.state.admin_data.token === undefined || this.state.admin_data.admin_id === undefined) {
      auth.logout(() => {
        this.props.history.push("/");
      });
    } else {
      const today = new Date();
      //const first_of_month = new Date(today.getFullYear(), today.getMonth(), 1);
      this.setState(
        {
          startDate: moment(),
          endDate: moment(),
          start_date: this.getFormattedDate(today),
          end_date: this.getFormattedDate(today),
        },
        () => {
          this.initiate();
        }
      );
      //this.getSources();
    }
  }

  processStats(combResponse) {
    const respData = "raw" in combResponse ? combResponse.raw : [];
    // Total Count
    const totalScans = respData.reduce((sum, x) => sum + x.total_scans, 0);
    this.setState({ total_scans: totalScans });

    // Individual Module Count
    const amazonCount = respData.reduce((sum, x) => sum + (x.module_winner === "AMAZON" ? x.total_scans : 0), -1);
    const amazonProfit = respData.reduce((sum, x) => sum + (x.module_winner === "AMAZON" ? x.agg_profit : 0), -1);
    const amazonCACount = respData.reduce((sum, x) => sum + (x.module_winner === "AMAZON CA" ? x.total_scans : 0), -1);
    const amazonCAProfit = respData.reduce((sum, x) => sum + (x.module_winner === "AMAZON CA" ? x.agg_profit : 0), -1);

    const marketplaceCount = respData.reduce(
      (sum, x) => sum + (x.module_winner === "MARKETPLACE" ? x.total_scans : 0),
      -1
    );
    const marketplaceProfit = respData.reduce(
      (sum, x) => sum + (x.module_winner === "MARKETPLACE" ? x.agg_profit : 0),
      -1
    );

    const wsCount = respData.reduce((sum, x) => sum + (x.module_winner === "WHOLESALE" ? x.total_scans : 0), 0);
    const wsProfit = respData.reduce((sum, x) => sum + (x.module_winner === "WHOLESALE" ? x.agg_profit : 0), 0);
    const bscouterCount = respData.reduce((sum, x) => sum + (x.module_winner === "BOOKSCOUTER" ? x.total_scans : 0), 0);
    const bscouterProfit = respData.reduce((sum, x) => sum + (x.module_winner === "BOOKSCOUTER" ? x.agg_profit : 0), 0);
    const bstoreCount = respData.reduce((sum, x) => sum + (x.module_winner === "BOOKSTORE" ? x.total_scans : 0), 0);
    const bstoreProfit = respData.reduce((sum, x) => sum + (x.module_winner === "BOOKSTORE" ? x.agg_profit : 0), 0);
    const hitlistCount = respData.reduce((sum, x) => sum + (x.module_winner === "HITLIST" ? x.total_scans : 0), 0);
    const hitlistProfit = respData.reduce((sum, x) => sum + (x.module_winner === "HITLIST" ? x.agg_profit : 0), 0);
    const rejectCount = respData.reduce((sum, x) => sum + (x.module_winner === "" ? x.total_scans : 0), 0);

    const mod_count = {
      amazon: { count: amazonCount, profit: amazonProfit },
      amazon_ca: { count: amazonCACount, profit: amazonCAProfit },
      marketplace: { count: marketplaceCount, profit: marketplaceProfit },
      bookstore: { count: bstoreCount, profit: bstoreProfit },
      bookscouter: { count: bscouterCount, profit: bscouterProfit },
      wholesale: { count: wsCount, profit: wsProfit },
      hitlist: { count: hitlistCount, profit: hitlistProfit },
      rejects: { count: rejectCount },
    };
    this.setState({ mod_count });

    // Final Stats Table Data
    var tableData = [];

    let is_fba = false,
      is_mf = false,
      is_fba_ca = false,
      is_mf_ca = false,
      is_bscouter = false,
      is_bstore = false,
      is_hitlist = false,
      is_reject = false,
      is_rms = false,
      is_sb = false,
      is_sbyb = false,
      is_ziffit = false,
      is_br = false,
      is_brb = false,
      is_ubb = false;

    for (let i = 0; i < respData.length; i++) {
      // Get Name
      const index_users_list = this.state.users_list.findIndex((d) => d.value === respData[i].user_id);
      const user_name = index_users_list >= 0 ? this.state.users_list[index_users_list].label : "";

      // Get module indexes
      const total_scans = respData.reduce((sum, d) => sum + (d.user_id === respData[i].user_id ? d.total_scans : 0), 0);
      const index_fba = respData.findIndex((d) => d.user_id === respData[i].user_id && d.hl_winner === "FBA");
      const index_mf = respData.findIndex((d) => d.user_id === respData[i].user_id && d.hl_winner === "MF");
      const index_fba_ca = respData.findIndex(
        (d) => d.user_id === respData[i].user_id && d.module_winner === "AMAZON CA" && d.hl_winner === "FBA"
      );
      const index_mf_ca = respData.findIndex(
        (d) => d.user_id === respData[i].user_id && d.module_winner === "AMAZON CA" && d.hl_winner === "MF"
      );
      const index_bscouter = respData.findIndex(
        (d) => d.user_id === respData[i].user_id && d.module_winner === "BOOKSCOUTER"
      );
      const index_bstore = respData.findIndex(
        (d) => d.user_id === respData[i].user_id && d.module_winner === "BOOKSTORE"
      );
      const index_hitlist = respData.findIndex(
        (d) => d.user_id === respData[i].user_id && d.module_winner === "HITLIST"
      );
      const index_reject = respData.findIndex((d) => d.user_id === respData[i].user_id && d.module_winner === "");

      const index_rms = respData.findIndex((d) => d.user_id === respData[i].user_id && d.hl_winner === "RMS");
      const index_sb = respData.findIndex((d) => d.user_id === respData[i].user_id && d.hl_winner === "SB");
      const index_sbyb = respData.findIndex((d) => d.user_id === respData[i].user_id && d.hl_winner === "SBYB");
      const index_ziffit = respData.findIndex((d) => d.user_id === respData[i].user_id && d.hl_winner === "ZIFFIT");
      const index_br = respData.findIndex((d) => d.user_id === respData[i].user_id && d.hl_winner === "BR");
      const index_brb = respData.findIndex((d) => d.user_id === respData[i].user_id && d.hl_winner === "BRB");
      const index_ubb = respData.findIndex((d) => d.user_id === respData[i].user_id && d.hl_winner === "UBB");

      const scans_fba = index_fba >= 0 ? respData[index_fba].total_scans : 0;
      const scans_mf = index_mf >= 0 ? respData[index_mf].total_scans : 0;
      const scans_fba_ca = index_fba_ca >= 0 ? respData[index_fba_ca].total_scans : 0;
      const scans_mf_ca = index_mf_ca >= 0 ? respData[index_mf_ca].total_scans : 0;

      if (index_fba >= 0) is_fba = true;
      if (index_mf >= 0) is_mf = true;
      if (index_fba_ca >= 0) is_fba_ca = true;
      if (index_mf_ca >= 0) is_mf_ca = true;
      if (index_bscouter >= 0) is_bscouter = true;
      if (index_bstore >= 0) is_bstore = true;
      if (index_hitlist >= 0) is_hitlist = true;
      if (index_reject >= 0) is_reject = true;
      if (index_rms >= 0) is_rms = true;
      if (index_sb >= 0) is_sb = true;
      if (index_sbyb >= 0) is_sbyb = true;
      if (index_ziffit >= 0) is_ziffit = true;
      if (index_br >= 0) is_br = true;
      if (index_brb >= 0) is_brb = true;
      if (index_ubb >= 0) is_ubb = true;

      // Perform aggregations
      let to_push = {
        UserId: respData[i].user_id,
        Name: user_name,
        "Total Scans": total_scans,
        FBA: scans_fba,
        "FBA%": index_fba >= 0 ? Number(((respData[index_fba].total_scans / total_scans) * 100).toFixed(1)) : 0,
        "Total FBA Profit": index_fba >= 0 ? Number(respData[index_fba].agg_profit.toFixed(1)) : 0,
        "Average FBA Profit": index_fba >= 0 ? Number((respData[index_fba].agg_profit / scans_fba).toFixed(1)) : 0,
        "Total FBA List Price": index_fba >= 0 ? Number(respData[index_fba].fba_list.toFixed(1)) : 0,
        "Average FBA List Price": index_fba >= 0 ? Number((respData[index_fba].fba_list / scans_fba).toFixed(1)) : 0,
        MF: scans_mf,
        "MF%": index_mf >= 0 ? Number(((respData[index_mf].total_scans / total_scans) * 100).toFixed(1)) : 0,
        "Total MF Profit": index_mf >= 0 ? Number(respData[index_mf].agg_profit.toFixed(1)) : 0,
        "Average MF Profit": index_mf >= 0 ? Number((respData[index_mf].agg_profit / scans_mf).toFixed(1)) : 0,
        "Total MF List Price": index_mf >= 0 ? Number(respData[index_mf].mf_list.toFixed(1)) : 0,
        "Average MF List Price": index_mf >= 0 ? Number((respData[index_mf].mf_list / scans_mf).toFixed(1)) : 0,
        FBA_CA: scans_fba_ca,
        "FBA_CA%":
          index_fba_ca >= 0 ? Number(((respData[index_fba_ca].total_scans / total_scans) * 100).toFixed(1)) : 0,
        "Total FBA_CA Profit": index_fba_ca >= 0 ? Number(respData[index_fba_ca].agg_profit.toFixed(1)) : 0,
        "Average FBA_CA Profit":
          index_fba_ca >= 0 ? Number((respData[index_fba_ca].agg_profit / scans_fba).toFixed(1)) : 0,
        "Total FBA_CA List Price": index_fba_ca >= 0 ? Number(respData[index_fba_ca].fba_list.toFixed(1)) : 0,
        "Average FBA_CA List Price":
          index_fba_ca >= 0 ? Number((respData[index_fba_ca].fba_list / scans_fba).toFixed(1)) : 0,
        MF_CA: scans_mf_ca,
        "MF_CA%": index_mf_ca >= 0 ? Number(((respData[index_mf_ca].total_scans / total_scans) * 100).toFixed(1)) : 0,
        "Total MF_CA Profit": index_mf_ca >= 0 ? Number(respData[index_mf_ca].agg_profit.toFixed(1)) : 0,
        "Average MF_CA Profit": index_mf_ca >= 0 ? Number((respData[index_mf_ca].agg_profit / scans_mf).toFixed(1)) : 0,
        "Total MF_CA List Price": index_mf_ca >= 0 ? Number(respData[index_mf_ca].mf_list.toFixed(1)) : 0,
        "Average MF_CA List Price":
          index_mf_ca >= 0 ? Number((respData[index_mf_ca].mf_list / scans_mf).toFixed(1)) : 0,
        SBYB: index_sbyb >= 0 ? respData[index_sbyb].total_scans : 0,
        "SBYB%": index_sbyb >= 0 ? Number(((respData[index_sbyb].total_scans / total_scans) * 100).toFixed(1)) : 0,
        "Total SBYB Profit": index_sbyb >= 0 ? Number(respData[index_sbyb].agg_profit.toFixed(1)) : 0,
        BS: index_bscouter >= 0 ? respData[index_bscouter].total_scans : 0,
        "BS%":
          index_bscouter >= 0 ? Number(((respData[index_bscouter].total_scans / total_scans) * 100).toFixed(1)) : 0,
        "Total BS Profit": index_bscouter >= 0 ? Number(respData[index_bscouter].agg_profit.toFixed(1)) : 0,
        ZIFFIT: index_ziffit >= 0 ? respData[index_ziffit].total_scans : 0,
        "ZIFFIT%":
          index_ziffit >= 0 ? Number(((respData[index_ziffit].total_scans / total_scans) * 100).toFixed(1)) : 0,
        "Total ZIFFIT Profit": index_ziffit >= 0 ? Number(respData[index_ziffit].agg_profit.toFixed(1)) : 0,
        BookStore: index_bstore >= 0 ? respData[index_bstore].total_scans : 0,
        "BookStore%":
          index_bstore >= 0 ? Number(((respData[index_bstore].total_scans / total_scans) * 100).toFixed(1)) : 0,
        Hitlist: index_hitlist >= 0 ? respData[index_hitlist].total_scans : 0,
        "Hitlist%":
          index_hitlist >= 0 ? Number(((respData[index_hitlist].total_scans / total_scans) * 100).toFixed(1)) : 0,
        Reject: index_reject >= 0 ? respData[index_reject].total_scans : 0,
        "Reject%":
          index_reject >= 0 ? Number(((respData[index_reject].total_scans / total_scans) * 100).toFixed(1)) : 0,
      };

      if (tableData.findIndex((d) => d.UserId === respData[i].user_id) >= 0) continue;
      else tableData.push(to_push);
    }

    // console.log(tableData);

    this.setState({ scan_data: tableData, isLoadingTableStats: false }, () => {
      let columns = [
        {
          dataField: "Name",
          text: "Name",
          headerStyle: { cursor: "pointer", outline: "none" },
          footer: "",
        },
        {
          dataField: "Total Scans",
          text: "Total Scans",
          sort: true,
          headerStyle: { cursor: "pointer", outline: "none" },
          footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0),
        },
      ];

      if (is_fba) {
        columns = columns.concat([
          {
            dataField: "FBA",
            text: "FBA",
            sort: true,
            style: { background: "#00743F", color: "#FFF" },
            headerStyle: { cursor: "pointer", outline: "none" },
            footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0),
          },
          {
            dataField: "FBA%",
            text: "FBA%",
            sort: true,
            style: { background: "#00743F", color: "#FFF" },
            headerStyle: { cursor: "pointer", outline: "none" },
            footer: (columnData) =>
              (this.state.scan_data.length >= 1
                ? (this.state.scan_data.reduce((acc, item) => acc + item["FBA"], 0) /
                    this.state.scan_data.reduce((acc, item) => acc + item["Total Scans"], 0)) *
                  100
                : 0
              ).toFixed(2) + "%",
          },
          {
            dataField: "Total FBA Profit",
            text: "Total FBA",
            sort: true,
            style: { background: "#00743F", color: "#FFF" },
            headerStyle: { cursor: "pointer", outline: "none" },
            footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0).toFixed(2),
          },
          {
            dataField: "Average FBA Profit",
            text: "Avg FBA",
            sort: true,
            style: { background: "#00743F", color: "#FFF" },
            headerStyle: { cursor: "pointer", outline: "none" },
            footer: (columnData) =>
              (this.state.filtered_scan_data.length >= 1
                ? this.state.filtered_scan_data.reduce((acc, item) => acc + item["Total FBA Profit"], 0) /
                  this.state.filtered_scan_data.reduce((acc, item) => acc + item["FBA"], 0)
                : 0
              ).toFixed(2),
          },
        ]);
      }

      if (is_mf) {
        columns = columns.concat([
          {
            dataField: "MF",
            text: "MF",
            sort: true,
            style: { background: "#F4874B", color: "#FFF" },
            headerStyle: { cursor: "pointer", outline: "none" },
            footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0),
          },
          {
            dataField: "MF%",
            text: "MF%",
            sort: true,
            style: { background: "#F4874B", color: "#FFF" },
            headerStyle: { cursor: "pointer", outline: "none" },
            footer: (columnData) =>
              (this.state.scan_data.length >= 1
                ? (this.state.scan_data.reduce((acc, item) => acc + item["MF"], 0) /
                    this.state.scan_data.reduce((acc, item) => acc + item["Total Scans"], 0)) *
                  100
                : 0
              ).toFixed(2) + "%",
          },
          {
            dataField: "Total MF Profit",
            text: "Total MF",
            sort: true,
            style: { background: "#F4874B", color: "#FFF" },
            headerStyle: { cursor: "pointer", outline: "none" },
            footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0).toFixed(2),
          },
          {
            dataField: "Average MF Profit",
            text: "Avg MF",
            sort: true,
            style: { background: "#F4874B", color: "#FFF" },
            headerStyle: { cursor: "pointer", outline: "none" },
            footer: (columnData) =>
              (this.state.filtered_scan_data.length >= 1
                ? this.state.filtered_scan_data.reduce((acc, item) => acc + item["Total MF Profit"], 0) /
                  this.state.filtered_scan_data.reduce((acc, item) => acc + item["MF"], 0)
                : 0
              ).toFixed(2),
          },
        ]);
      }

      if (is_fba_ca) {
        columns = columns.concat([
          {
            dataField: "FBA_CA",
            text: "FBA CA",
            sort: true,
            style: { background: "#125134", color: "#FFF" },
            headerStyle: { cursor: "pointer", outline: "none" },
            footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0),
          },
          {
            dataField: "FBA_CA%",
            text: "FBA CA%",
            sort: true,
            style: { background: "#125134", color: "#FFF" },
            headerStyle: { cursor: "pointer", outline: "none" },
            footer: (columnData) =>
              (this.state.scan_data.length >= 1
                ? (this.state.scan_data.reduce((acc, item) => acc + item["FBA"], 0) /
                    this.state.scan_data.reduce((acc, item) => acc + item["Total Scans"], 0)) *
                  100
                : 0
              ).toFixed(2) + "%",
          },
          {
            dataField: "Total FBA_CA Profit",
            text: "Total FBA CA",
            sort: true,
            style: { background: "#125134", color: "#FFF" },
            headerStyle: { cursor: "pointer", outline: "none" },
            footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0).toFixed(2),
          },
          {
            dataField: "Average FBA_CA Profit",
            text: "Avg FBA CA",
            sort: true,
            style: { background: "#125134", color: "#FFF" },
            headerStyle: { cursor: "pointer", outline: "none" },
            footer: (columnData) =>
              (this.state.filtered_scan_data.length >= 1
                ? this.state.filtered_scan_data.reduce((acc, item) => acc + item["Total FBA Profit"], 0) /
                  this.state.filtered_scan_data.reduce((acc, item) => acc + item["FBA"], 0)
                : 0
              ).toFixed(2),
          },
        ]);
      }

      if (is_mf_ca) {
        columns = columns.concat([
          {
            dataField: "MF_CA",
            text: "MF CA",
            sort: true,
            style: { background: "#F4874B", color: "#FFF" },
            headerStyle: { cursor: "pointer", outline: "none" },
            footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0),
          },
          {
            dataField: "MF_CA%",
            text: "MF CA%",
            sort: true,
            style: { background: "#F4874B", color: "#FFF" },
            headerStyle: { cursor: "pointer", outline: "none" },
            footer: (columnData) =>
              (this.state.scan_data.length >= 1
                ? (this.state.scan_data.reduce((acc, item) => acc + item["MF"], 0) /
                    this.state.scan_data.reduce((acc, item) => acc + item["Total Scans"], 0)) *
                  100
                : 0
              ).toFixed(2) + "%",
          },
          {
            dataField: "Total MF_CA Profit",
            text: "Total MF CA",
            sort: true,
            style: { background: "#F4874B", color: "#FFF" },
            headerStyle: { cursor: "pointer", outline: "none" },
            footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0).toFixed(2),
          },
          {
            dataField: "Average MF_CA Profit",
            text: "Avg MF CA",
            sort: true,
            style: { background: "#F4874B", color: "#FFF" },
            headerStyle: { cursor: "pointer", outline: "none" },
            footer: (columnData) =>
              (this.state.filtered_scan_data.length >= 1
                ? this.state.filtered_scan_data.reduce((acc, item) => acc + item["Total MF Profit"], 0) /
                  this.state.filtered_scan_data.reduce((acc, item) => acc + item["MF"], 0)
                : 0
              ).toFixed(2),
          },
        ]);
      }

      if (is_rms)
        columns = columns.concat([
          {
            dataField: "RMS",
            text: "RMS",
            sort: true,
            style: { background: "#A7414A", color: "#FFF" },
            headerStyle: { cursor: "pointer", outline: "none" },
            footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0),
          },
          {
            dataField: "RMS%",
            text: "RMS%",
            sort: true,
            style: { background: "#A7414A", color: "#FFF" },
            headerStyle: { cursor: "pointer", outline: "none" },
            footer: (columnData) =>
              (this.state.filtered_scan_data.length >= 1
                ? (this.state.filtered_scan_data.reduce((acc, item) => acc + item["RMS"], 0) /
                    this.state.filtered_scan_data.reduce((acc, item) => acc + item["Total Scans"], 0)) *
                  100
                : 0
              ).toFixed(2) + "%",
          },
          {
            dataField: "Total RMS Profit",
            text: "Total RMS",
            sort: true,
            style: { background: "#A7414A", color: "#FFF" },
            headerStyle: { cursor: "pointer", outline: "none" },
            footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0).toFixed(2),
          },
        ]);

      if (is_sbyb)
        columns = columns.concat([
          {
            dataField: "SBYB",
            text: "SBYB",
            sort: true,
            style: { background: "#6A8A82", color: "#FFF" },
            headerStyle: { cursor: "pointer", outline: "none" },
            footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0),
          },
          {
            dataField: "SBYB%",
            text: "SBYB%",
            sort: true,
            style: { background: "#6A8A82", color: "#FFF" },
            headerStyle: { cursor: "pointer", outline: "none" },
            footer: (columnData) =>
              (this.state.filtered_scan_data.length >= 1
                ? (this.state.filtered_scan_data.reduce((acc, item) => acc + item["SBYB"], 0) /
                    this.state.filtered_scan_data.reduce((acc, item) => acc + item["Total Scans"], 0)) *
                  100
                : 0
              ).toFixed(2) + "%",
          },
          {
            dataField: "Total SBYB Profit",
            text: "Total SBYB",
            sort: true,
            style: { background: "#6A8A82", color: "#FFF" },
            headerStyle: { cursor: "pointer", outline: "none" },
            footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0).toFixed(2),
          },
        ]);

      if (is_bscouter)
        columns = columns.concat([
          {
            dataField: "BS",
            text: "BS",
            sort: true,
            style: { background: "#A37C27", color: "#FFF" },
            headerStyle: { cursor: "pointer", outline: "none" },
            footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0),
          },
          {
            dataField: "BS%",
            text: "BS%",
            sort: true,
            style: { background: "#A37C27", color: "#FFF" },
            headerStyle: { cursor: "pointer", outline: "none" },
            footer: (columnData) =>
              (this.state.filtered_scan_data.length >= 1
                ? (this.state.filtered_scan_data.reduce((acc, item) => acc + item["SBYB"], 0) /
                    this.state.filtered_scan_data.reduce((acc, item) => acc + item["Total Scans"], 0)) *
                  100
                : 0
              ).toFixed(2) + "%",
          },
          {
            dataField: "Total BS Profit",
            text: "Total BS",
            sort: true,
            style: { background: "#A37C27", color: "#FFF" },
            headerStyle: { cursor: "pointer", outline: "none" },
            footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0).toFixed(2),
          },
        ]);

      if (is_sb)
        columns = columns.concat([
          {
            dataField: "SB",
            text: "SB",
            sort: true,
            style: { background: "#72A2C0", color: "#FFF" },
            headerStyle: { cursor: "pointer", outline: "none" },
            footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0),
          },
          {
            dataField: "SB%",
            text: "SB%",
            sort: true,
            style: { background: "#72A2C0", color: "#FFF" },
            headerStyle: { cursor: "pointer", outline: "none" },
            footer: (columnData) =>
              (this.state.filtered_scan_data.length >= 1
                ? (this.state.filtered_scan_data.reduce((acc, item) => acc + item["SB"], 0) /
                    this.state.filtered_scan_data.reduce((acc, item) => acc + item["Total Scans"], 0)) *
                  100
                : 0
              ).toFixed(2) + "%",
          },
          {
            dataField: "Total SB Profit",
            text: "Total SB",
            sort: true,
            style: { background: "#72A2C0", color: "#FFF" },
            headerStyle: { cursor: "pointer", outline: "none" },
            footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0).toFixed(2),
          },
        ]);

      if (is_ziffit)
        columns = columns.concat([
          {
            dataField: "ZIFFIT",
            text: "ZIFFIT",
            sort: true,
            style: { background: "#0444BF", color: "#FFF" },
            headerStyle: { cursor: "pointer", outline: "none" },
            footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0),
          },
          {
            dataField: "ZIFFIT%",
            text: "ZIFFIT%",
            sort: true,
            style: { background: "#0444BF", color: "#FFF" },
            headerStyle: { cursor: "pointer", outline: "none" },
            footer: (columnData) =>
              (this.state.filtered_scan_data.length >= 1
                ? (this.state.filtered_scan_data.reduce((acc, item) => acc + item["ZIFFIT"], 0) /
                    this.state.filtered_scan_data.reduce((acc, item) => acc + item["Total Scans"], 0)) *
                  100
                : 0
              ).toFixed(2) + "%",
          },
          {
            dataField: "Total ZIFFIT Profit",
            text: "Total ZIFFIT",
            sort: true,
            style: { background: "#0444BF", color: "#FFF" },
            headerStyle: { cursor: "pointer", outline: "none" },
            footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0).toFixed(2),
          },
        ]);

      if (is_br)
        columns = columns.concat([
          {
            dataField: "BR",
            text: "BR",
            sort: true,
            style: { background: "#0D3C55", color: "#FFF" },
            headerStyle: { cursor: "pointer", outline: "none" },
            footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0),
          },
          {
            dataField: "BR%",
            text: "BR%",
            sort: true,
            style: { background: "#0D3C55", color: "#FFF" },
            headerStyle: { cursor: "pointer", outline: "none" },
            footer: (columnData) =>
              (this.state.filtered_scan_data.length >= 1
                ? (this.state.filtered_scan_data.reduce((acc, item) => acc + item["BR"], 0) /
                    this.state.filtered_scan_data.reduce((acc, item) => acc + item["Total Scans"], 0)) *
                  100
                : 0
              ).toFixed(2) + "%",
          },
          {
            dataField: "Total BR Profit",
            text: "Total BR",
            sort: true,
            style: { background: "#0D3C55", color: "#FFF" },
            headerStyle: { cursor: "pointer", outline: "none" },
            footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0).toFixed(2),
          },
        ]);

      if (is_brb)
        columns = columns.concat([
          {
            dataField: "BRB",
            text: "BRB",
            sort: true,
            style: { background: "#1ABC9C", color: "#FFF" },
            headerStyle: { cursor: "pointer", outline: "none" },
            footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0),
          },
          {
            dataField: "BRB%",
            text: "BRB%",
            sort: true,
            style: { background: "#1ABC9C", color: "#FFF" },
            headerStyle: { cursor: "pointer", outline: "none" },
            footer: (columnData) =>
              (this.state.filtered_scan_data.length >= 1
                ? (this.state.filtered_scan_data.reduce((acc, item) => acc + item["BRB"], 0) /
                    this.state.filtered_scan_data.reduce((acc, item) => acc + item["Total Scans"], 0)) *
                  100
                : 0
              ).toFixed(2) + "%",
          },
          {
            dataField: "Total BRB Profit",
            text: "Total BRB",
            sort: true,
            style: { background: "#1ABC9C", color: "#FFF" },
            headerStyle: { cursor: "pointer", outline: "none" },
            footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0).toFixed(2),
          },
        ]);

      if (is_ubb)
        columns = columns.concat([
          {
            dataField: "UBB",
            text: "UBB",
            sort: true,
            style: { background: "#D14B8F", color: "#FFF" },
            headerStyle: { cursor: "pointer", outline: "none" },
            footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0),
          },
          {
            dataField: "UBB%",
            text: "UBB%",
            sort: true,
            style: { background: "#D14B8F", color: "#FFF" },
            headerStyle: { cursor: "pointer", outline: "none" },
            footer: (columnData) =>
              (this.state.filtered_scan_data.length >= 1
                ? (this.state.filtered_scan_data.reduce((acc, item) => acc + item["UBB"], 0) /
                    this.state.filtered_scan_data.reduce((acc, item) => acc + item["Total Scans"], 0)) *
                  100
                : 0
              ).toFixed(2) + "%",
          },
          {
            dataField: "Total UBB Profit",
            text: "Total UBB",
            sort: true,
            style: { background: "#D14B8F", color: "#FFF" },
            headerStyle: { cursor: "pointer", outline: "none" },
            footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0).toFixed(2),
          },
        ]);

      if (is_bstore)
        columns = columns.concat([
          {
            dataField: "BookStore",
            text: "BookStore",
            sort: true,
            style: { background: "#040C0E", color: "#FFF" },
            headerStyle: { cursor: "pointer", outline: "none" },
            footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0),
          },
          {
            dataField: "BookStore%",
            text: "BookStore%",
            sort: true,
            style: { background: "#040C0E", color: "#FFF" },
            headerStyle: { cursor: "pointer", outline: "none" },
            footer: (columnData) =>
              (this.state.filtered_scan_data.length >= 1
                ? this.state.filtered_scan_data.reduce((acc, item) => acc + item["BookStore"], 0) /
                  this.state.filtered_scan_data.reduce((acc, item) => acc + item["Total Scans"], 0)
                : 0
              ).toFixed(2) + "%",
          },
        ]);

      if (is_hitlist)
        columns = columns.concat([
          {
            dataField: "Hitlist",
            text: "Hitlist",
            sort: true,
            style: { background: "#6465A5", color: "#FFF" },
            headerStyle: { cursor: "pointer", outline: "none" },
            footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0),
          },
          {
            dataField: "Hitlist%",
            text: "Hitlist%",
            sort: true,
            style: { background: "#6465A5", color: "#FFF" },
            headerStyle: { cursor: "pointer", outline: "none" },
            footer: (columnData) =>
              (this.state.filtered_scan_data.length >= 1
                ? this.state.filtered_scan_data.reduce((acc, item) => acc + item["Hitlist"], 0) /
                  this.state.filtered_scan_data.reduce((acc, item) => acc + item["Total Scans"], 0)
                : 0
              ).toFixed(2) + "%",
          },
        ]);

      if (is_reject) {
        columns = columns.concat([
          {
            dataField: "Reject",
            text: "Reject",
            sort: true,
            style: { background: "#F20050", color: "#FFF" },
            headerStyle: { cursor: "pointer", outline: "none" },
            footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0),
          },
          {
            dataField: "Reject%",
            text: "Reject%",
            sort: true,
            style: { background: "#F20050", color: "#FFF" },
            headerStyle: { cursor: "pointer", outline: "none" },
            footer: (columnData) =>
              (this.state.filtered_scan_data.length >= 1
                ? this.state.filtered_scan_data.reduce((acc, item) => acc + item["Reject"], 0) /
                  this.state.filtered_scan_data.reduce((acc, item) => acc + item["Total Scans"], 0)
                : 0
              ).toFixed(2) + "%",
          },
        ]);
      }

      this.setState({ columns });
    });
  }

  updateStatsData(initialProgress) {
    this.setState({
      amz_vs_marketplace: {
        count: 0,
        profit: 0,
      },
      mod_count: {
        amazon: { count: -1, profit: -1 },
        marketplace: { count: -1, profit: -1 },
        amazon_ca: { count: -1, profit: -1 },
        marketplace_ca: { count: -1, profit: -1 },
        bookstore: { count: 0, profit: 0 },
        bookscouter: { count: 0, profit: 0 },
        wholesale: { count: 0, profit: 0 },
        hitlist: { count: 0, profit: 0 },
        rejects: { count: 0 },
      },
    });
    if (undefined !== initialProgress) this.setState({ apiProgress: initialProgress });
    getStatsCount(
      this.state.admin_data,
      this.state.selectedSources,
      this.state.selectedUsers,
      this.state.selectedTeams,
      this.state.list_user_or_team,
      this.state.excludeDuplicate,
      (response) => {
        if (response !== false) this.setState({ scan_counts: response, apiProgress: this.state.apiProgress + 10 });
      }
    );

    getStatsRejects(
      this.state.admin_data,
      this.state.selectedSources,
      this.state.selectedUsers,
      this.state.selectedTeams,
      this.state.list_user_or_team,
      this.state.excludeDuplicate,
      (response) => {
        if (response !== false) this.setState({ reject_counts: response, apiProgress: this.state.apiProgress + 10 });
      }
    );

    getTimeSeries(
      this.state.admin_data,
      this.state.start_date,
      this.state.end_date,
      this.state.selectedSources,
      this.state.selectedUsers,
      this.state.selectedTeams,
      this.state.list_user_or_team,
      this.state.excludeDuplicate,
      this.state.currentTSDFrequency,
      (response) => {
        if (response !== false)
          this.setState({ apiProgress: this.state.apiProgress + 10 }, () => {
            this.updateTimeSeriesChart(response);
          });
      }
    );

    getRawStats(
      this.state.admin_data,
      this.state.start_date,
      this.state.end_date,
      this.state.selectedSources,
      this.state.selectedUsers,
      this.state.selectedTeams,
      this.state.list_user_or_team,
      this.state.excludeDuplicate,
      (response) => {
        console.log(`RAW STATS HERE`);
        this.setState({ apiProgress: this.state.apiProgress + 30 }, () => {
          this.processStats(response);
        });
      }
    );

    getBenchmark(
      this.state.admin_data,
      this.state.start_date,
      this.state.end_date,
      this.state.selectedSources,
      this.state.selectedUsers,
      this.state.selectedTeams,
      this.state.list_user_or_team,
      (response) => {
        console.log(`BENCHMARK STATS HERE`);

        if (response.status && undefined !== response.data) {
          this.setState({
            amz_vs_marketplace: {
              count: "count" in response.data ? response.data.count : 0,
              profit: "profit" in response.data ? response.data.profit : 0,
            },
          });
        }

        // this.setState({ apiProgress: this.state.apiProgress + 30 }, () => {

        // });
      }
    );
  }

  async initiate() {
    this.setState({
      isLoadingTableStats: true,
      isLoading: true,
      scan_counts: { today: -1, week: -1, month: -1 },
      reject_counts: { today: -1, week: -1, month: -1 },
      scan_data: [],
    });

    getSources(this.state.admin_data, (response) => {
      const sourcesData = response.data.map((sd) => {
        return { value: sd.source, label: sd.source };
      });
      this.setState({ sourcesData, isLoadingSources: false, apiProgress: this.state.apiProgress + 20 });
    });

    getUsers(this.state.admin_data, (response) => {
      if (response !== false) {
        const users_list = response.data.map((ul) => {
          return { value: ul.id, label: ul.first_name + " " + ul.last_name };
        });
        this.setState({ users_list, isLoadingUsers: false, apiProgress: this.state.apiProgress + 20 }, () => {
          this.updateStatsData();
        });
      }
    });
  }

  async updateTimeSeriesChart(timeSeriesData) {
    const filteredData = [
      { name: "Scans", prefix: "$", data: {} },
      { name: "Profit", data: {} },
    ];
    timeSeriesData.forEach((tsd) => {
      const dfSplits = tsd.df.split("-");
      if (dfSplits.length === 2) {
        const splitYear = dfSplits[0];
        var months = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];
        const splitMonth = dfSplits[1];
        filteredData[0].data[`${months[splitMonth - 1]} ${splitYear}`] = tsd.scans;
        filteredData[1].data[`${months[splitMonth - 1]} ${splitYear}`] = tsd.profit;
      }
      if (dfSplits.length === 3) {
        const splitYear = dfSplits[0];
        const splitMonth = dfSplits[1];
        const splitDay = dfSplits[2];
        filteredData[0].data[new Date(`${splitYear}-${splitMonth}-${splitDay}`)] = tsd.scans;
        filteredData[1].data[new Date(`${splitYear}-${splitMonth}-${splitDay}`)] = tsd.profit;
      }
    });

    this.setState({ timeSeriesData: filteredData });
  }

  async fetchStats() {
    this.setState({
      isLoadingTableStats: true,
      scan_counts: { today: -1, week: -1, month: -1 },
      reject_counts: { today: -1, week: -1, month: -1 },
      scan_data: [],
    });

    this.updateStatsData(40);
  }

  handleChangeStartDate(evt) {
    this.setState({ start_date: evt.target.value }, this.fetchStats);
  }
  handleChangeEndDate(evt) {
    this.setState({ end_date: evt.target.value }, this.fetchStats);
  }

  handleExcludeDuplicateToggle(evt) {
    this.setState({ excludeDuplicate: evt.target.checked }, this.fetchStats);
  }

  delay = async (duration) => {
    await setTimeout(console.log("Waiting for table update"), duration);
  };

  handleSourcesChange(selectedSources) {
    this.setState({ selectedSources }, this.fetchStats);
  }

  handleSelectedUsersChange(selectedUsers) {
    this.setState({ selectedUsers }, this.fetchStats);
  }
  handleSelectedTeamsChange(selectedTeams) {
    this.setState({ selectedTeams }, this.fetchStats);
  }

  async onToggleUsersTeams(e) {
    if (e.target.value === "teams") {
      if (this.state.teams_list === null) {
        const teams_response = await fetchTeamList(this.state.admin_data);
        if ("status" in teams_response && teams_response.status === "success") {
          const teams_list = teams_response.data.map((td) => {
            return { label: td.team_name, value: td.id };
          });
          this.setState({ teams_list, list_user_or_team: "teams", selectedUsers: [] });
        }
      } else this.setState({ list_user_or_team: "teams", selectedUsers: [] });
    } else this.setState({ list_user_or_team: "users", selectedTeams: [] });
  }

  async onChangeFrequency(e) {
    this.setState({ currentTSDFrequency: e.target.value }, async () => {
      getTimeSeries(
        this.state.admin_data,
        this.state.start_date,
        this.state.end_date,
        this.state.selectedSources,
        this.state.selectedUsers,
        this.state.selectedTeams,
        this.state.list_user_or_team,
        this.state.excludeDuplicate,
        this.state.currentTSDFrequency,
        (response) => {
          if (response !== false) this.updateTimeSeriesChart(response);
        }
      );
    });
  }

  updateMaxProfit = async (event) => {
    event.preventDefault();
    this.setState({ isSavingMaxProfit: true });

    let admin_data = { ...this.state.admin_data };

    const maxProfit = "maxProfit" in event.target ? event.target.maxProfit.value : "";

    console.log(`MaxProfit: ${maxProfit}`);

    if (/^\d+$/.test(maxProfit) === true) {
      await updateMaxProfitAPI(this.state.admin_data, maxProfit);
      admin_data.max_profit = parseInt(maxProfit);
      this.setState({ admin_data, isSavingMaxProfit: false }, () => {
        auth.setAdminData(this.state.admin_data);
        this.fetchStats();
      });
    } else {
      console.log(`Invalid Max Profit input.`);
      this.setState({ isSavingMaxProfit: false });
    }
  };

  render() {
    const pie_chart_data = [
      ["FBA", this.state.scan_data.reduce((sum, sd) => sum + sd.FBA, 0), "HELLO"],
      ["MF", this.state.scan_data.reduce((sum, sd) => sum + sd.MF, 0)],
      ["SBYB", this.state.scan_data.reduce((sum, sd) => sum + sd.SBYB, 0)],
      ["BOOKSCOUTER", this.state.scan_data.reduce((sum, sd) => sum + sd.BS, 0)],

      ["ZIFFIT", this.state.scan_data.reduce((sum, sd) => sum + sd.ZIFFIT, 0)],

      ["HITLIST", this.state.scan_data.reduce((sum, sd) => sum + sd.Hitlist, 0)],
      ["REJECT", this.state.scan_data.reduce((sum, sd) => sum + sd.Reject, 0)],
    ];
    const pieColors = ["#00743F", "#F4874B", "#6A8A82", "#A37C27", "#0444BF", "#6465A5", "#F20050"];

    const total_sum = Object.values(this.state.mod_count).reduce((sum, x) => sum + x.count, 0);

    const amazon_per =
      this.state.mod_count.amazon.count > 0 ? ((this.state.mod_count.amazon.count / total_sum) * 100).toFixed(1) : 0;
    const amazon_ca_per =
      this.state.mod_count.amazon_ca.count > 0
        ? ((this.state.mod_count.amazon_ca.count / total_sum) * 100).toFixed(1)
        : 0;
    const marketplace_per =
      this.state.mod_count.marketplace.count > 0
        ? ((this.state.mod_count.marketplace.count / total_sum) * 100).toFixed(1)
        : 0;

    const amz_vs_marketplace_per =
      this.state.amz_vs_marketplace.count > 0 && total_sum > 0
        ? ((this.state.amz_vs_marketplace.count / total_sum) * 100).toFixed(1)
        : 0;

    const wholesale_per =
      this.state.mod_count.wholesale.count > 0
        ? ((this.state.mod_count.wholesale.count / total_sum) * 100).toFixed(1)
        : 0;
    const hitlist_per =
      this.state.mod_count.hitlist.count > 0 ? ((this.state.mod_count.hitlist.count / total_sum) * 100).toFixed(1) : 0;
    const bookstore_per =
      this.state.mod_count.bookstore.count > 0
        ? ((this.state.mod_count.bookstore.count / total_sum) * 100).toFixed(1)
        : 0;
    const bookscouter_per =
      this.state.mod_count.bookscouter.count > 0
        ? ((this.state.mod_count.bookscouter.count / total_sum) * 100).toFixed(1)
        : 0;
    const reject_per =
      this.state.mod_count.rejects.count > 0 ? ((this.state.mod_count.rejects.count / total_sum) * 100).toFixed(1) : 0;

    // Create our number formatter.
    var formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });

    var numberWithCommas = (x) => {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    const reactSelectCustomStyles = {
      control: (styles) => ({ ...styles, backgroundColor: "white", height: "48px", borderRadius: "0.12em" }),
      // option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      //   const color = chroma(data.color);
      //   return {
      //     ...styles,
      //     backgroundColor: isDisabled ? "red" : blue,
      //     color: "#FFF",
      //     cursor: isDisabled ? "not-allowed" : "default",
      //   };
      // },
    };

    // const falseFuncAirbnb = () => false;

    return (
      <React.Fragment>
        <TopMenu
          isReevaluate={
            "is_re_evaluate" in this.state.admin_data && this.state.admin_data.is_re_evaluate === 1 ? true : false
          }
          handlenavigationroutes={(routeName) => {
            abortRequests();
            this.props.history.push(routeName);
          }}
          adminData={this.state.admin_data}
          fetchStats={this.fetchStats}
          history={this.props.history}
          sources={this.state.sources}
          activeKey="/"
        />

        {this.state.apiProgress < 100 && (
          <ProgressBar
            style={{
              borderRadius: "none",
              height: "7px",
            }}
            now={this.state.apiProgress}
          />
        )}

        <Container fluid={true} className="page-dashboard">
          <Row
            style={{
              padding: "20px",
              borderBottom: "1px solid #ddd",
              background: "#fdfdfd",
            }}
          >
            <Col md={4}>
              <PieChart data={pie_chart_data} colors={pieColors} donut={true} />
            </Col>
            <Col
              md={8}
              style={{
                display: "grid",
                alignItems: "center",
              }}
            >
              <Row>
                <Col md={4}>
                  <div className="div-scan-count">
                    <h5>TODAY</h5>
                    <div className="scan-count">
                      {this.state.scan_counts.today >= 0
                        ? this.getFormattedNumber(this.state.scan_counts.today)
                        : "..."}
                    </div>
                    <div
                      className="reject-count"
                      title={
                        this.state.reject_counts.today >= 0
                          ? this.getFormattedNumber(this.state.reject_counts.today)
                          : "..."
                      }
                    >
                      {this.state.reject_counts.today >= 0 && this.state.scan_counts.today >= 0
                        ? this.state.scan_counts.today > 0
                          ? parseInt((this.state.reject_counts.today / this.state.scan_counts.today) * 100) +
                            "% REJECTED"
                          : "0% REJECTED"
                        : "..."}
                    </div>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="div-scan-count">
                    <h5>THIS WEEK</h5>
                    <div className="scan-count">
                      {this.state.scan_counts.week >= 0 ? this.getFormattedNumber(this.state.scan_counts.week) : "..."}
                    </div>
                    <div
                      className="reject-count"
                      title={
                        this.state.reject_counts.week >= 0
                          ? this.getFormattedNumber(this.state.reject_counts.week)
                          : "..."
                      }
                    >
                      {this.state.reject_counts.week >= 0 && this.state.scan_counts.week >= 0
                        ? this.state.scan_counts.week > 0
                          ? parseInt((this.state.reject_counts.week / this.state.scan_counts.week) * 100) + "% REJECTED"
                          : "0% REJECTED"
                        : "..."}
                    </div>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="div-scan-count">
                    <h5>THIS MONTH</h5>
                    <div className="scan-count">
                      {this.state.scan_counts.month >= 0
                        ? this.getFormattedNumber(this.state.scan_counts.month)
                        : "..."}
                    </div>
                    <div
                      className="reject-count"
                      title={
                        this.state.reject_counts.month >= 0
                          ? this.getFormattedNumber(this.state.reject_counts.month)
                          : "..."
                      }
                    >
                      {this.state.reject_counts.month >= 0 && this.state.scan_counts.month >= 0
                        ? this.state.scan_counts.month > 0
                          ? parseInt((this.state.reject_counts.month / this.state.scan_counts.month) * 100) +
                            "% REJECTED"
                          : "0% REJECTED"
                        : "..."}
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row style={{ paddingBottom: "100px" }}>
            <Col className="tabletoolkit" md={12}>
              <div>
                <ToolkitProvider keyField="id" data={this.state.scan_data} columns={this.state.columns} search>
                  {(props) => (
                    <div>
                      <div className="flex-responsive">
                        <div>
                          <FormControl
                            as="select"
                            onChange={this.onChangeFrequency}
                            style={{ height: "48px", borderRadius: "0.12em" }}
                          >
                            <option value="day">Day</option>
                            <option value="hour">Hour</option>
                            <option value="month">Month</option>
                          </FormControl>
                        </div>

                        <div className="cc_dash_table_options_float_right" style={{ borderRight: "2px solid #111313" }}>
                          <div className="dash_table_options_float_right">
                            {/* <div className="dash_table_options_float_left" style={{ padding: "7px 10px 0 0" }}>
                              From
                            </div> */}
                            <div classame="dash_table_options_float_left">
                              <DateRangePicker
                                numberOfMonths={1}
                                isOutsideRange={(day) => !isInclusivelyBeforeDay(day, moment())}
                                minimumNights={0}
                                startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                                startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                                endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                                endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                                onDatesChange={({ startDate, endDate }) =>
                                  this.setState(
                                    {
                                      startDate,
                                      endDate,
                                      start_date: this.getFormattedDate(new Date(startDate)),
                                      end_date: this.getFormattedDate(new Date(endDate)),
                                    },
                                    this.fetchStats
                                  )
                                } // PropTypes.func.isRequired,
                                hideKeyboardShortcutsPanel={true}
                                focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                                onFocusChange={(focusedInput) => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                              />
                            </div>
                            {/* <div className="dash_table_options_float_left">
                              <FormControl
                                value={this.state.start_date}
                                onChange={this.handleChangeStartDate}
                                className="dash_table_inputs"
                                type="date"
                              />
                            </div>
                            <div className="dash_table_options_float_left" style={{ padding: "7px 10px 0 10px" }}>
                              To
                            </div>
                            <div className="dash_table_options_float_left">
                              <FormControl
                                value={this.state.end_date}
                                onChange={this.handleChangeEndDate}
                                min={this.state.start_date}
                                className="dash_table_inputs"
                                type="date"
                              />
                            </div> */}
                          </div>
                        </div>

                        <div className="cc_dash_table_options_float_right" style={{ flexGrow: "1" }}>
                          <Select
                            styles={reactSelectCustomStyles}
                            isMulti
                            value={this.state.selectedSources}
                            onChange={this.handleSourcesChange}
                            options={this.state.sourcesData}
                            placeholder={this.state.isLoadingSources === true ? "LOADING ..." : "Select Sources"}
                          />
                        </div>
                      </div>

                      <div className="flex-responsive">
                        <div className="cc_dash_table_options_float_right" style={{ borderRight: "2px solid #111313" }}>
                          <Form onSubmit={this.updateMaxProfit}>
                            Max Profit:{" "}
                            <Form.Control
                              style={{ display: "inline", width: "55px", height: "48px", borderRadius: "0.12em" }}
                              type="numbers"
                              defaultValue={this.state.admin_data.max_profit}
                              placeholder="Profit"
                              name="maxProfit"
                            />
                            <Button
                              variant="primary"
                              style={{
                                display: "inline",
                                // width: "50px",
                                // padding: "2px",
                                // fontSize: "14px",
                                // borderRadius: "0px",
                                marginLeft: "2px",
                              }}
                              type="submit"
                            >
                              {this.state.isSavingMaxProfit === true ? (
                                <Spinner animation="border" role="status" style={{ width: "22px", height: "22px" }}>
                                  <span className="sr-only">Loading...</span>
                                </Spinner>
                              ) : (
                                "Save"
                              )}
                            </Button>
                          </Form>
                        </div>

                        <div
                          className="cc_dash_table_options_float_right"
                          style={{ borderRight: "2px solid #111313", paddingTop: "13px" }}
                          // style={{
                          //   marginLeft: "10px",
                          //
                          //   borderRight: "2px solid #111313",
                          //   paddingRight: "10px",
                          // }}
                        >
                          <Form.Check
                            onChange={this.handleExcludeDuplicateToggle}
                            type="switch"
                            checked={this.state.excludeDuplicate}
                            id="custom-switch-team-view"
                            label="Exclude Duplicate Scans?"
                          />
                        </div>

                        {/* <SearchBar className="search-bar dash_table_inputs" {...props.searchProps} /> */}
                        {this.state.admin_data.access_level === 0 && (
                          <div className="cc_dash_table_options_float_right">
                            {/* <Form>
                              <Form.Check
                                onChange={this.props.handleLiveToggle}
                                type="switch"
                                id="custom-switch-team-view"
                                label="Team?"
                              />
                            </Form> */}
                            <FormControl
                              as="select"
                              onChange={this.onToggleUsersTeams}
                              style={{ height: "48px", borderRadius: "0.12em" }}
                            >
                              <option value="users">Users</option>
                              <option value="teams">Teams</option>
                            </FormControl>
                          </div>
                        )}
                        <div className="cc_dash_table_options_float_right" style={{ flexGrow: 1 }}>
                          {this.state.list_user_or_team === "users" ? (
                            <Select
                              isMulti
                              styles={reactSelectCustomStyles}
                              value={this.state.selectedUsers}
                              onChange={this.handleSelectedUsersChange}
                              options={this.state.users_list}
                              placeholder={this.state.isLoadingUsers === true ? "LOADING ..." : "Select Users"}
                            />
                          ) : (
                            <Select
                              isMulti
                              styles={reactSelectCustomStyles}
                              value={this.state.selectedTeams}
                              onChange={this.handleSelectedTeamsChange}
                              options={this.state.teams_list}
                              placeholder={this.state.isLoadingTeams === true ? "LOADING ..." : "Select Teams"}
                            />
                          )}
                        </div>

                        {/* <div className="div-searchbar" style={{ float: "left", marginRight: "20px" }}></div>
                          <div className="select-sources"></div>
                          <div style={{ clear: "both" }}></div> */}
                      </div>

                      <div className="flex-responsive ">
                        <div style={{ flex: 1 }}>
                          <div className="div-scan-count-overall">
                            <h5>TOTAL SCANS</h5>
                            <div className="scan-count-overall">
                              {this.state.total_scans > 0 && this.state.isLoadingTableStats === false
                                ? numberWithCommas(this.state.total_scans)
                                : "..."}
                            </div>
                            <div className="reject-count-overall">
                              Since {new Date(this.state.start_date).toDateString()}
                            </div>
                          </div>
                        </div>
                        <div style={{ flex: 4, paddingLeft: "20px" }}>
                          <LineChart colors={["#0444BF", "#00743F"]} data={this.state.timeSeriesData} />
                        </div>
                      </div>

                      <Row style={{ padding: "20px 0 20px 0" }}>
                        <Col md={12}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              flexWrap: "wrap",
                              justifyContent: "center",
                            }}
                          >
                            {this.state.mod_count.amazon.count !== -1 && (
                              <div className="moduleProgressBar">
                                <CustomContentProgressbar
                                  styles={{
                                    path: {
                                      stroke: `#00743F`,
                                    },

                                    text: {
                                      fontSize: "13px",
                                    },
                                  }}
                                  value={amazon_per}
                                >
                                  <div style={{ fontSize: "0.9em" }}>
                                    <strong>AMAZON</strong>
                                  </div>
                                  <div style={{ fontSize: "0.8em", marginTop: "5px" }}>
                                    {this.state.mod_count.amazon.count > 0 && this.state.isLoadingTableStats === false
                                      ? numberWithCommas(this.state.mod_count.amazon.count) + " (" + amazon_per + "%)"
                                      : "-"}
                                  </div>
                                  <div style={{ marginTop: "7px", fontSize: "0.9em", fontWeight: "bold" }}>
                                    {this.state.mod_count.amazon.profit > 0 && this.state.isLoadingTableStats === false
                                      ? formatter.format(this.state.mod_count.amazon.profit)
                                      : "-"}
                                  </div>
                                </CustomContentProgressbar>
                              </div>
                            )}
                            {this.state.mod_count.amazon_ca.count !== -1 && (
                              <div className="moduleProgressBar">
                                <CustomContentProgressbar
                                  styles={{
                                    path: {
                                      stroke: `#125134`,
                                    },

                                    text: {
                                      fontSize: "13px",
                                    },
                                  }}
                                  value={amazon_ca_per}
                                >
                                  <div style={{ fontSize: "0.9em" }}>
                                    <strong>AMAZON CA</strong>
                                  </div>
                                  <div style={{ fontSize: "0.8em", marginTop: "5px" }}>
                                    {this.state.mod_count.amazon_ca.count > 0 &&
                                    this.state.isLoadingTableStats === false
                                      ? numberWithCommas(this.state.mod_count.amazon_ca.count) +
                                        " (" +
                                        amazon_ca_per +
                                        "%)"
                                      : "-"}
                                  </div>
                                  <div style={{ marginTop: "7px", fontSize: "0.9em", fontWeight: "bold" }}>
                                    {this.state.mod_count.amazon_ca.profit > 0 &&
                                    this.state.isLoadingTableStats === false
                                      ? formatter.format(this.state.mod_count.amazon_ca.profit)
                                      : "-"}
                                  </div>
                                </CustomContentProgressbar>
                              </div>
                            )}
                            {this.state.mod_count.marketplace.count !== -1 && (
                              <div className="moduleProgressBar">
                                <CustomContentProgressbar
                                  styles={{
                                    path: {
                                      stroke: `#125134`,
                                    },

                                    text: {
                                      fontSize: "13px",
                                    },
                                  }}
                                  value={marketplace_per}
                                >
                                  <div style={{ fontSize: "0.9em" }}>
                                    <strong>MARKETPLACE</strong>
                                  </div>
                                  <div style={{ fontSize: "0.8em", marginTop: "5px" }}>
                                    {this.state.mod_count.marketplace.count > 0 &&
                                    this.state.isLoadingTableStats === false
                                      ? numberWithCommas(this.state.mod_count.marketplace.count) +
                                        " (" +
                                        marketplace_per +
                                        "%)"
                                      : "-"}
                                  </div>
                                  <div style={{ marginTop: "7px", fontSize: "0.9em", fontWeight: "bold" }}>
                                    {this.state.mod_count.marketplace.profit > 0 &&
                                    this.state.isLoadingTableStats === false
                                      ? formatter.format(this.state.mod_count.marketplace.profit)
                                      : "-"}
                                  </div>
                                </CustomContentProgressbar>
                              </div>
                            )}
                            {this.state.mod_count.wholesale.count > 0 && (
                              <div className="moduleProgressBar">
                                <CustomContentProgressbar
                                  styles={{
                                    path: {
                                      stroke: `#0444BF`,
                                    },

                                    text: {
                                      fontSize: "13px",
                                    },
                                  }}
                                  value={wholesale_per}
                                >
                                  <div style={{ fontSize: "0.9em" }}>
                                    <strong>WHOLESALE</strong>
                                  </div>
                                  <div style={{ fontSize: "0.8em", marginTop: "5px" }}>
                                    {this.state.mod_count.wholesale.count > 0 &&
                                    this.state.isLoadingTableStats === false
                                      ? numberWithCommas(this.state.mod_count.wholesale.count) +
                                        " (" +
                                        wholesale_per +
                                        "%)"
                                      : "-"}
                                  </div>
                                  <div style={{ marginTop: "7px", fontSize: "0.9em", fontWeight: "bold" }}>
                                    {this.state.mod_count.wholesale.profit > 0 &&
                                    this.state.isLoadingTableStats === false
                                      ? formatter.format(this.state.mod_count.wholesale.profit)
                                      : "-"}
                                  </div>
                                </CustomContentProgressbar>
                              </div>
                            )}

                            {this.state.mod_count.bookscouter.count > 0 && (
                              <div className="moduleProgressBar">
                                <CustomContentProgressbar
                                  styles={{
                                    path: {
                                      stroke: `#A37C27`,
                                    },

                                    text: {
                                      fontSize: "13px",
                                    },
                                  }}
                                  value={bookscouter_per}
                                >
                                  <div style={{ fontSize: "0.9em" }}>
                                    <strong>BOOKSCOUTER</strong>
                                  </div>
                                  <div style={{ fontSize: "0.8em", marginTop: "5px" }}>
                                    {this.state.mod_count.bookscouter.count > 0 &&
                                    this.state.isLoadingTableStats === false
                                      ? numberWithCommas(this.state.mod_count.bookscouter.count) +
                                        " (" +
                                        bookscouter_per +
                                        "%)"
                                      : "-"}
                                  </div>
                                  <div style={{ marginTop: "7px", fontSize: "0.9em", fontWeight: "bold" }}>
                                    {this.state.mod_count.bookscouter.profit > 0 &&
                                    this.state.isLoadingTableStats === false
                                      ? formatter.format(this.state.mod_count.bookscouter.profit)
                                      : "-"}
                                  </div>
                                </CustomContentProgressbar>
                              </div>
                            )}

                            {this.state.mod_count.hitlist.count > 0 && (
                              <div className="moduleProgressBar">
                                <CustomContentProgressbar
                                  styles={{
                                    path: {
                                      stroke: `#6465A5`,
                                    },

                                    text: {
                                      fontSize: "13px",
                                    },
                                  }}
                                  value={hitlist_per}
                                >
                                  <div style={{ fontSize: "0.9em" }}>
                                    <strong>HITLIST</strong>
                                  </div>
                                  <div style={{ fontSize: "0.8em", marginTop: "5px" }}>
                                    {this.state.mod_count.hitlist.count > 0 && this.state.isLoadingTableStats === false
                                      ? numberWithCommas(this.state.mod_count.hitlist.count) + " (" + hitlist_per + "%)"
                                      : "-"}
                                  </div>
                                  <div style={{ marginTop: "7px", fontSize: "0.9em", fontWeight: "bold" }}>
                                    {this.state.mod_count.hitlist.profit > 0 && this.state.isLoadingTableStats === false
                                      ? formatter.format(this.state.mod_count.hitlist.profit)
                                      : "-"}
                                  </div>
                                </CustomContentProgressbar>
                              </div>
                            )}

                            {this.state.mod_count.bookstore.count > 0 && (
                              <div className="moduleProgressBar">
                                <CustomContentProgressbar
                                  styles={{
                                    path: {
                                      stroke: `#040C0E`,
                                    },

                                    text: {
                                      fontSize: "13px",
                                    },
                                  }}
                                  value={bookstore_per}
                                >
                                  <div style={{ fontSize: "0.9em" }}>
                                    <strong>BOOKSTORE</strong>
                                  </div>
                                  <div style={{ fontSize: "0.8em", marginTop: "5px" }}>
                                    {this.state.mod_count.bookstore.count > 0 &&
                                    this.state.isLoadingTableStats === false
                                      ? numberWithCommas(this.state.mod_count.bookstore.count) +
                                        " (" +
                                        bookstore_per +
                                        "%)"
                                      : "-"}
                                  </div>
                                  <div style={{ marginTop: "7px", fontSize: "0.9em", fontWeight: "bold" }}>
                                    {this.state.mod_count.bookstore.profit > 0 &&
                                    this.state.isLoadingTableStats === false
                                      ? formatter.format(this.state.mod_count.bookstore.profit)
                                      : "-"}
                                  </div>
                                </CustomContentProgressbar>
                              </div>
                            )}

                            {this.state.mod_count.rejects.count > 0 && (
                              <div className="moduleProgressBar">
                                <CustomContentProgressbar
                                  styles={{
                                    path: {
                                      stroke: `#F20050`,
                                    },

                                    text: {
                                      fontSize: "13px",
                                    },
                                  }}
                                  value={reject_per}
                                >
                                  <div style={{ fontSize: "0.9em" }}>
                                    <strong>REJECTS</strong>
                                  </div>
                                  <div style={{ fontSize: "0.8em", marginTop: "5px" }}>
                                    {this.state.mod_count.rejects.count > 0 && this.state.isLoadingTableStats === false
                                      ? numberWithCommas(this.state.mod_count.rejects.count) + " (" + reject_per + "%)"
                                      : "-"}
                                  </div>
                                </CustomContentProgressbar>
                              </div>
                            )}
                          </div>
                        </Col>
                      </Row>

                      <div className="benchmark">
                        Benchmark Analysis: The Marketplace value for these units was{" "}
                        {this.state.amz_vs_marketplace.profit !== "" &&
                        this.state.amz_vs_marketplace.profit !== null &&
                        this.state.amz_vs_marketplace.profit !== undefined ? (
                          <span style={{ fontWeight: "bold" }}>
                            ${numberWithCommas(this.state.amz_vs_marketplace.profit.toFixed(2))}
                          </span>
                        ) : (
                          <span style={{ fontWeight: "bold" }}>$0</span>
                        )}{" "}
                        and the accept rate would have been{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {amz_vs_marketplace_per > 0 ? amz_vs_marketplace_per + "%" : "-"}
                        </span>
                        <div style={{ marginTop: "5px", fontStyle: "italic", fontSize: "12px" }}>
                          Marketplace values have been tracked since 4/15/21 - if your date range extends earlier than
                          that, your Marketplace values will be under reported
                        </div>
                      </div>
                      <Row style={{ marginTop: "20px" }}>
                        <Col md={12}>
                          {this.state.isLoadingTableStats === true ? (
                            <div style={{ textAlign: "center" }}>
                              <Spinner animation="border" role="status">
                                <span className="sr-only">Loading...</span>
                              </Spinner>
                            </div>
                          ) : (
                            <BootstrapTable
                              ref={(n) => (this.node = n)}
                              noDataIndication={() => <div style={{ textAlign: "center" }}>No Data available</div>}
                              wrapperClasses="table-responsive"
                              {...props.baseProps}
                            />
                          )}
                        </Col>
                      </Row>
                    </div>
                  )}
                </ToolkitProvider>
              </div>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default Dashboard;
