import React, { Component } from "react";
import { Row, Col, Button } from "react-bootstrap";
import printerIcon from "../../assets/images/icon_item_print.png";
import deleteIcon from "../../assets/images/icon_item_delete.png";

class ListWorkspaceItem extends Component {
  constructor() {
    super();
    this.setMoveQty = this.setMoveQty.bind(this);
    this.setMoveBox = this.setMoveBox.bind(this);
  }
  state = {
    moveQty: 0,
    moveBox: 0,
  };

  moveItemToDiffBox = () => {
    const moveQty = this.state.moveQty;
    const moveBox = this.state.moveBox;

    this.props.moveItemToDiffBox(this.props.item, moveQty, moveBox);
  };

  setMoveQty(e) {
    this.setState({ moveQty: e.target.value });
  }
  setMoveBox(e) {
    this.setState({ moveBox: e.target.value });
  }

  RenderOptions = (count) => {
    const items = [];
    items.push(
      <option value={-1} key={-1}>
        ...
      </option>
    );
    for (let i = 1; i <= count; i++) {
      items.push(
        <option value={i} key={i}>
          {i}
        </option>
      );
    }
    return items;
  };

  generateFNSKU = (item) => {
    this.props.generateFNSKU(item);
  };

  deleteItemFromWorkspace = (item) => {
    this.props.deleteItemFromWorkspace(item);
  };

  getBoxes = (item) => {
    const boxCount = this.props.getBoxes(item);
    const items = [];
    items.push(
      <option value={-1} key={-1}>
        ...
      </option>
    );
    for (let i = 1; i <= boxCount; i++) {
      if (item.BoxIdCount + 1 !== i)
        items.push(
          <option value={i} key={i}>
            {i}
          </option>
        );
    }
    return items;
  };

  render() {
    return (
      <Row className="itemInWorkspace">
        <Col md={2} className="text-center">
          <div>
            <img
              alt="Item"
              style={{ width: "70px" }}
              src={"http://images.amazon.com/images/P/" + this.props.item.Asin + ".01._SCMZZZZZZZ_.jpg"}
            />
          </div>
          <div className="mt-2">
            <small>
              <strong>{this.props.item.Asin}</strong>
            </small>
          </div>
        </Col>
        <Col md={10}>
          <div className="text-primary itemTitle">{this.props.item.Title}</div>
          <div className="itemMeta">
            Qty <strong>{this.props.item.quantityInBox}</strong> of <strong>{this.props.item.quantity}</strong> from
            Shipment <strong>{this.props.item.ShipmentId}</strong>{" "}
            <span className="badge badge-primary">{this.props.item.DestinationFulfillmentCenterId}</span> goes in Box{" "}
            <strong>#{this.props.item.BoxIdCount + 1}</strong>
          </div>
          <div className="itemActions">
            <span>
              <img
                style={{ width: "20px", marginTop: "-5px", cursor: "pointer" }}
                title="Print Bar Code"
                alt="Print Bar Code"
                onClick={this.generateFNSKU.bind(this, this.props.item)}
                src={printerIcon}
              />
              <img
                style={{ width: "20px", marginTop: "-5px", marginLeft: "13px", cursor: "pointer" }}
                title="Remove Item"
                alt="Delete"
                onClick={this.deleteItemFromWorkspace.bind(this, this.props.item)}
                src={deleteIcon}
              />
            </span>
            {this.props.getBoxes(this.props.item) > 1 && (
              <span style={{ paddingLeft: "8px", borderLeft: "1px solid #3D3D3D", marginLeft: "8px" }}>
                Move
                <select onChange={this.setMoveQty} className="selectInline">
                  {this.RenderOptions(this.props.item.quantityInBox)}
                </select>
                <span className="ml-3">to Box</span>
                <select onChange={this.setMoveBox} className="selectInline">
                  {this.getBoxes(this.props.item)}
                </select>
                <Button onClick={this.moveItemToDiffBox} className="ml-3 mb-1" variant="secondary" size="sm">
                  UPDATE
                </Button>
              </span>
            )}
          </div>
        </Col>
      </Row>
    );
  }
}

export default ListWorkspaceItem;
