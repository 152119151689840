import React, { Component } from "react";
import TopMenu from "../components/TopMenu";
import Cookies from "universal-cookie";
import { apiBaseUrlLocal } from "../includes/AppConfig";
import { Row, Col, Container, Table, Badge, Button, Spinner } from "react-bootstrap";

const cookies = new Cookies();

class ZiffitLedger extends Component {
  constructor(props) {
    super(props);
    this.state = {
      admin_data: cookies.get("admin_data"),
      isLoadingShipments: true,
      downloadingCSV: false,
      downloadCSVShipmentName: "",
      shipments: [],
    };
  }

  componentDidMount() {
    this.initiate();
  }

  async downloadCSV(shipmentName) {
    this.setState({ downloadingCSV: true, downloadCSVShipmentName: shipmentName });

    const response = await fetch(apiBaseUrlLocal + "admin/shipments/ziffit-export", {
      method: "POST",
      body: new URLSearchParams({
        user_id: this.state.admin_data.admin_id,
        app_token: this.state.admin_data.token,
        shipment_name: shipmentName,
      }),
    });
    if (response.status === 200) {
      const data = await response.json();
      if ("status" in data && data.status === true) {
        console.log(data.data);

        let csvContent = "data:text/csv;charset=utf-8,";
        csvContent += "ASIN,TITLE,IS CONFIRMED?,ESCORE,SALES RANK,ZIFFIT PRICE,SCANNED ON\r\n";

        data.data.forEach(function (rowArray) {
          const scannedDate = new Date(rowArray.scanned_on);
          const yearScanned = scannedDate.getFullYear();
          let monthScanned = (1 + scannedDate.getMonth()).toString();
          monthScanned = monthScanned.length > 1 ? monthScanned : "0" + monthScanned;
          let dayScanned = scannedDate.getDate().toString();
          dayScanned = dayScanned.length > 1 ? dayScanned : "0" + dayScanned;

          let row =
            '="' +
            rowArray.asin +
            '","' +
            rowArray.title +
            '",' +
            (rowArray.ziffit_trade_id !== null &&
            rowArray.ziffit_trade_id !== "" &&
            rowArray.ziffit_trade_id.includes("_US")
              ? "YES"
              : "NO") +
            "," +
            rowArray.escore +
            "," +
            rowArray.sales_rank +
            "," +
            rowArray.ziffit_price +
            "," +
            (monthScanned + "-" + dayScanned + "-" + yearScanned);

          csvContent += row + "\r\n";
        });

        var encodedUri = encodeURI(csvContent);
        const fixedEncodedURI = encodedUri.replaceAll("#", "%23");
        var link = document.createElement("a");
        link.setAttribute("href", fixedEncodedURI);
        link.setAttribute("download", `${shipmentName}.csv`);
        document.body.appendChild(link); // Required for FF

        link.click(); // This will download the data file named "my_data.csv".
      }
    }
    this.setState({ downloadingCSV: false, downloadCSVShipmentName: "" });
  }

  async initiate() {
    this.setState({ isLoadingShipments: true });
    const response = await fetch(apiBaseUrlLocal + "admin/shipments/ziffit-ledger", {
      method: "POST",
      body: new URLSearchParams({
        user_id: this.state.admin_data.admin_id,
        app_token: this.state.admin_data.token,
      }),
    });
    if (response.status === 200) {
      const data = await response.json();
      if ("status" in data && data.status === true) this.setState({ shipments: data.data });
    }
    this.setState({ isLoadingShipments: false });
  }

  getUSFormattedDate = (dateString) => {
    var date = new Date(dateString);
    var year = date.getFullYear();

    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : "0" + month;

    var day = date.getDate().toString();
    day = day.length > 1 ? day : "0" + day;

    return month + "-" + day + "-" + year;
  };

  render() {
    return (
      <React.Fragment>
        <TopMenu
          handlenavigationroutes={(routeName) => {
            // abortRequests();
            this.props.history.push(routeName);
          }}
          isReevaluate={
            "is_re_evaluate" in this.state.admin_data && this.state.admin_data.is_re_evaluate === 1 ? true : false
          }
          adminData={this.state.admin_data}
          history={this.props.history}
          activeKey="/shipments"
        />

        <Container fluid={true} style={{ marginTop: "15px" }}>
          <h2>Ziffit Ledger</h2>
          {this.state.shipments.length > 0 && (
            <p>Last Updated: {new Date(this.state.shipments[0].last_updated).toString()}</p>
          )}

          <Row style={{ marginTop: "20px" }}>
            <Col md={12}>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th></th>
                    <th>Trade Id</th>
                    <th>Team Id</th>
                    <th>Team Name</th>
                    <th>Created On</th>
                    <th>Closed On</th>
                    <th>Confirmed Count</th>
                    <th>Confirmed Amt</th>
                    <th>Unconfirmed Count</th>
                    <th>Unconfirmed Amt</th>
                    <th>Total Count</th>
                    <th>Total Amt</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.shipments.map((shipment) => (
                    <tr>
                      <td>
                        <Button
                          title="Download CSV"
                          size="sm"
                          style={{ marginRight: "10px" }}
                          variant="dark"
                          onClick={() => {
                            this.downloadCSV(shipment.name);
                          }}
                        >
                          {this.state.downloadingCSV === true &&
                          this.state.downloadCSVShipmentName === shipment.name ? (
                            <Spinner
                              animation="border"
                              role="status"
                              style={{ width: "15px", height: "15px" }}
                            ></Spinner>
                          ) : (
                            <i style={{ color: "white" }} className="fa fa-cloud-download" />
                          )}
                        </Button>
                      </td>
                      <td>{shipment.ziffit_trade_id}</td>
                      <td>{shipment.team_id}</td>
                      <td>{shipment.team_name}</td>
                      <td>{this.getUSFormattedDate(shipment.created_on)}</td>
                      <td>{shipment.closed_on !== null ? this.getUSFormattedDate(shipment.closed_on) : "-"}</td>
                      <td>{shipment.confirmed_count}</td>
                      <td>${shipment.confirmed_amt}</td>
                      <td>{shipment.unconfirmed_count}</td>
                      <td>${shipment.unconfirmed_amt}</td>
                      <td style={{ fontWeight: "bold" }}>
                        {(shipment.confirmed_count + shipment.unconfirmed_count).toFixed(0)}
                      </td>
                      <td style={{ fontWeight: "bold" }}>
                        ${(shipment.confirmed_amt + shipment.unconfirmed_amt).toFixed(2)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              {this.state.isLoadingShipments === true && (
                <div style={{ textAlign: "center", marginTop: "50px" }}>
                  <Spinner animation="border" role="status" style={{ width: "80px", height: "80px" }}></Spinner>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default ZiffitLedger;
