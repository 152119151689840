import React, { Component, Fragment } from "react";
import { Form, Modal, Table } from "react-bootstrap";

class ModalEmailVendorDataPosted extends Component {
  state = {};

  constructor(props) {
    super(props);
    this.initiateModal = this.initiateModal.bind(this);
  }

  initiateModal() {}

  render() {
    return (
      <Modal
        onEnter={this.initiateModal}
        show={this.props.showModal}
        onHide={this.props.handleClose}
        dialogClassName="editModal"
      >
        <Modal.Header closeButton>
          <h3>Posted to {this.props.displayName}</h3>
        </Modal.Header>

        <Modal.Body>
          <Form inline onSubmit={this.submitEmailVendor}>
            <Table bordered>
              <tbody>
                {(this.props.displayName === "FBA" || this.props.displayName === "MF") && (
                  <Fragment>
                    <tr>
                      <td>Container Type</td>
                      <td style={{ paddingLeft: "20px" }}>
                        {this.props.dataPosted !== null && "containerType" in this.props.dataPosted
                          ? this.props.dataPosted.containerType.charAt(0).toUpperCase() +
                            this.props.dataPosted.containerType.slice(1)
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Number of{" "}
                        {this.props.dataPosted !== null && "containerType" in this.props.dataPosted
                          ? this.props.dataPosted.containerType.charAt(0).toUpperCase() +
                            this.props.dataPosted.containerType.slice(1)
                          : ""}
                      </td>
                      <td style={{ paddingLeft: "20px" }}>
                        {this.props.dataPosted !== null ? this.props.dataPosted.numberOfContainers : ""}
                      </td>
                    </tr>
                  </Fragment>
                )}

                {this.props.displayName === "SELLBOOKS" && (
                  <Fragment>
                    <tr>
                      <td>Number of Boxes</td>
                      <td style={{ paddingLeft: "20px" }}>
                        {this.props.dataPosted !== null ? this.props.dataPosted.numberOfContainers : ""}
                      </td>
                    </tr>
                    <tr>
                      <td>Contact Name</td>
                      <td style={{ paddingLeft: "20px" }}>
                        {this.props.dataPosted !== null ? this.props.dataPosted.contactName : ""}
                      </td>
                    </tr>
                    <tr>
                      <td>Contact Phone</td>
                      <td style={{ paddingLeft: "20px" }}>
                        {this.props.dataPosted !== null ? this.props.dataPosted.contactPhone : ""}
                      </td>
                    </tr>
                    <tr>
                      <td>Ship from Address</td>
                      <td style={{ paddingLeft: "20px" }}>
                        {this.props.dataPosted !== null ? this.props.dataPosted.shipFromAddress : ""}
                      </td>
                    </tr>
                    <tr>
                      <td>Payment Method</td>
                      <td style={{ paddingLeft: "20px" }}>
                        {this.props.dataPosted !== null ? this.props.dataPosted.paymentMethod : ""}
                      </td>
                    </tr>
                    {this.props.dataPosted.paymentMethod === "paypal" ? (
                      <tr>
                        <td>Payment Name</td>
                        <td style={{ paddingLeft: "20px" }}>
                          {this.props.dataPosted !== null ? this.props.dataPosted.paypalEmail : ""}
                        </td>
                      </tr>
                    ) : (
                      <Fragment>
                        {" "}
                        <tr>
                          <td>Payment Name</td>
                          <td style={{ paddingLeft: "20px" }}>
                            {this.props.dataPosted !== null ? this.props.dataPosted.paymentName : ""}
                          </td>
                        </tr>
                        <tr>
                          <td>Payment Address</td>
                          <td style={{ paddingLeft: "20px" }}>
                            {this.props.dataPosted !== null ? this.props.dataPosted.paymentAddress : ""}
                          </td>
                        </tr>
                      </Fragment>
                    )}
                  </Fragment>
                )}
              </tbody>
            </Table>
          </Form>
        </Modal.Body>
      </Modal>
    );
  }
}

export default ModalEmailVendorDataPosted;
