import React, { Component } from "react";
import { Form, Modal, Button, FormControl, Spinner } from "react-bootstrap";

class ModalDuplicateTriggerBatch extends Component {
  state = {};

  constructor(props) {
    super(props);

    this.handleOpen = this.handleOpen.bind(this);
  }

  componentDidMount() {
    if (this.batchNameInput !== undefined) this.batchNameInput.select();
  }
  handleOpen() {
    console.log(this.props.tbClipboard);
  }

  render() {
    return (
      <Modal
        show={this.props.showAddModal}
        onHide={this.props.handleClose}
        dialogClassName="editModal"
        onEntered={this.handleOpen}
      >
        <Form onSubmit={this.props.onFormSubmitAddTrigger}>
          <Modal.Header closeButton>
            <FormControl
              required
              ref={(input) => {
                this.batchNameInput = input;
              }}
              type="text"
              name="triggerName"
              className="input-text-trigger-name"
              placeholder="Enter Trigger Batch Name Here"
              autoFocus
            />
          </Modal.Header>

          <Modal.Body>
            {this.props.teams !== false && this.props.teams.length >= 1 && (
              <div>
                <Form.Group controlId="formTeamId">
                  <Form.Label>Team</Form.Label>
                  <Form.Control as="select" name="teamId">
                    {this.props.teams.map((teamGroup) => {
                      return <option value={teamGroup.id}>{teamGroup.team_name}</option>;
                    })}
                  </Form.Control>
                </Form.Group>
              </div>
            )}
          </Modal.Body>

          <Modal.Footer style={{ border: "none" }}>
            <div style={{ position: "absolute", left: "15px", fontSize: "14px" }}>
              Duplicating:
              <span style={{ fontWeight: "bold", marginLeft: "10px" }}>
                {"name" in this.props.tbClipboard ? this.props.tbClipboard.name : "N/A"}
              </span>
            </div>
            {this.props.isDuplicating === true ? (
              <Spinner animation="border" role="status"></Spinner>
            ) : (
              <Button variant="primary" type="submit">
                Duplicate
              </Button>
            )}
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

export default ModalDuplicateTriggerBatch;
