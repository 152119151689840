import React, { Component } from "react";
import Select from "react-select";
import { Row, Col, Form, Modal, Button, Spinner } from "react-bootstrap";

class EditQueue extends Component {
  state = {
    currentCSVFile: null,
    taText: "",
    selectedTriggerBatch: null,
  };

  constructor() {
    super();
    this.onFormSubmitEditQueue = this.onFormSubmitEditQueue.bind(this);
    this.onChangeCSVFile = this.onChangeCSVFile.bind(this);
    this.handleChangeTaText = this.handleChangeTaText.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
  }

  handleOpen() {
    if ("isbn_list" in this.props.currentEditQueue && this.props.currentEditQueue.isbn_list !== "") {
      const recs = this.props.currentEditQueue.isbn_list.split(",");
      let parsedRecs = "";
      for (let i = 0; i < recs.length; i++) {
        parsedRecs += recs[i] + "\n";
      }
      this.setState({ taText: parsedRecs });
    }
  }

  async onFormSubmitEditQueue(event) {
    event.preventDefault();

    // shippingPlanName required fields
    const reevaluateQueueName = event.target.elements.reevaluateQueueName.value;
    const reevaluateTriggerBatchId = this.state.selectedTriggerBatch.value;
    const reevaluateFrequency = event.target.elements.frequency.value;

    new Promise((resolve, reject) => {
      let asins = [];
      if (this.state.currentCSVFile !== null) {
        var reader = new FileReader();
        reader.onload = function (e) {
          const csvLines = e.target.result.split("\n");
          const csvColumns = csvLines[0].split(",");

          let asinColIndex = 0;
          // Step 1. Identify Column
          for (let i = 0; i < csvColumns.length; i++) {
            if (csvColumns[i].toLowerCase().includes("asin") || csvColumns[i].toLowerCase().includes("isbn10")) {
              asinColIndex = i;
              break;
            }
          }

          const asinPad = "0000000000";

          for (let i = 1; i < csvLines.length; i++) {
            const asin = csvLines[i].split(",")[asinColIndex].trim();
            const paddedAsin = (asinPad + asin).slice(-asinPad.length);
            asins.push(paddedAsin);
          }
          resolve(asins);
        };
        reader.readAsText(this.state.currentCSVFile);
      } else resolve(asins);
    }).then((res) => {
      const finalArray = this.state.taText
        .split("\n")
        .filter((e1) => e1 !== "")
        .map((rec) => {
          return rec.trim();
        });

      console.log(finalArray);
      const reevaluateQueueData = res.concat(finalArray);
      this.props.onFormSubmitEditQueue(
        reevaluateQueueName,
        reevaluateTriggerBatchId,
        reevaluateFrequency,
        reevaluateQueueData
      );
    });
  }

  onChangeCSVFile = (e) => {
    if (e.target.files[0] !== undefined) {
      this.setState({ currentCSVFile: e.target.files[0] });
    }
  };

  handleChangeTaText = (e) => {
    this.setState({ taText: e.target.value });
  };

  render() {
    const ddOptions = this.props.triggerBatches.map((triggerBatch) => {
      return {
        label: triggerBatch.id + " - " + triggerBatch.team_name + " - " + triggerBatch.name,
        value: triggerBatch.id,
      };
    });
    return (
      <Modal
        onEntered={this.handleOpen}
        show={this.props.showEditModal}
        onHide={this.props.handleClose}
        dialogClassName="editModal"
      >
        <Form onSubmit={this.onFormSubmitEditQueue}>
          <Modal.Header closeButton>
            <Form.Control
              required
              style={{ paddingLeft: "0" }}
              type="text"
              name="reevaluateQueueName"
              className="input-text-trigger-name"
              defaultValue={this.props.currentEditQueue.queue_name}
              placeholder="Enter Queue Name Here"
            />
          </Modal.Header>
          <Modal.Body>
            <div style={{ fontSize: "13px", fontWeight: "500" }}>
              <Row>
                <Col md={12}>
                  <Row>
                    <Col md={12}>
                      <div>
                        <Form.Group controlId="formAudioFile">
                          <Form.Label>Choose .csv File</Form.Label>
                          <Form.Control
                            onChange={this.onChangeCSVFile}
                            type="file"
                            name="csvFile"
                            placeholder="Choose .csv File"
                          />
                        </Form.Group>
                      </div>
                      <div>
                        <div style={{ padding: "0 0 10px 0" }}>AND/OR</div>
                        <Form.Group controlId="exampleForm.ControlTextarea1">
                          <Form.Label>
                            Enter in format: ASIN E.g. <strong>1118615611</strong> separated by new line
                          </Form.Label>
                          <Form.Control
                            value={this.state.taText}
                            as="textarea"
                            rows="8"
                            onChange={this.handleChangeTaText}
                          />
                        </Form.Group>
                      </div>
                    </Col>
                  </Row>

                  <div>
                    <Select
                      value={this.state.selectedTriggerBatch}
                      placeholder="Pick a trigger batch to run this with"
                      onChange={(selectedTriggerBatch) => {
                        this.setState({ selectedTriggerBatch });
                        //this.handleChange;
                      }}
                      options={ddOptions}
                    />
                  </div>

                  <Form.Group controlId="formFrequency" style={{ marginTop: "20px" }}>
                    <Form.Label>Queue Frequency</Form.Label>
                    <Form.Control as="select" name="frequency" defaultValue={this.props.currentEditQueue.frequency}>
                      <option value="everyday">Every Day</option>
                      <option value="everysat">Every Saturday</option>
                      <option value="everytueandsat">Every Tuesday & Saturday</option>
                      <option value="alternatesat">Alternate Saturday</option>
                      <option value="firsteverymonth">1st of Every Month</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {this.props.isProcessingApi === true ? (
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            ) : (
              <Button variant="primary" type="submit">
                Save Changes
              </Button>
            )}
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

export default EditQueue;
