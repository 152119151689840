import React, { Component } from "react";
import { Form, Modal, Button, FormControl, Spinner, Table } from "react-bootstrap";

class ModalEmailVendorChangeStatus extends Component {
  state = {
    shipmentStatus: "paid",
  };

  initiateModal() {
    this.setState({ shipmentStatus: "paid" });
  }

  constructor() {
    super();
    this.onToggleShipmentStatus = this.onToggleShipmentStatus.bind(this);
    this.initiateModal = this.initiateModal.bind(this);
    this.updateStatus = this.updateStatus.bind(this);
  }

  onToggleShipmentStatus(e) {
    this.setState({ shipmentStatus: e.target.value });
  }

  updateStatus(e) {
    e.preventDefault();

    const shipmentStatus = e.target.elements.shipmentStatus.value;
    const amountPaid = shipmentStatus === "paid" ? e.target.elements.amountPaid.value : null;
    this.props.handleClose();
    this.props.updateShipmentStatus(shipmentStatus, amountPaid);
  }

  render() {
    return (
      <Modal
        onEnter={this.initiateModal}
        show={this.props.showModal}
        onHide={this.props.handleClose}
        dialogClassName="editModal"
      >
        <Modal.Header closeButton>
          <h3>Update status for {this.props.selectedShipment !== null ? this.props.selectedShipment.name : ""}</h3>
        </Modal.Header>

        <Modal.Body>
          <Form inline onSubmit={this.updateStatus}>
            <Table bordered>
              <tbody>
                <tr>
                  <td>Current Status</td>
                  <td style={{ paddingLeft: "20px" }}>
                    {this.props.selectedShipment.status === "OPEN" ? (
                      <span style={{ color: "orange" }}>OPEN</span>
                    ) : this.props.selectedShipment.status === "PAID" ? (
                      <span style={{ color: "gree" }}>PAID</span>
                    ) : (
                      <span style={{ color: "blue" }}>SHIPPED</span>
                    )}
                  </td>
                </tr>

                {this.props.selectedShipment.amount_paid !== "" && this.props.selectedShipment.amount_paid !== null ? (
                  <tr>
                    <td>Amount Received</td>
                    <td style={{ paddingLeft: "20px" }}>{`$${this.props.selectedShipmentamount_paid}`}</td>
                  </tr>
                ) : (
                  <tr>
                    <td>Update Status</td>
                    <td style={{ paddingLeft: "20px" }}>
                      <FormControl
                        as="select"
                        onChange={this.onToggleShipmentStatus}
                        name="shipmentStatus"
                        defaultValue={this.props.selectedShipment.status.toLowerCase()}
                      >
                        {/* <option value="open">Open</option>
                        <option value="shipped">Shipped</option> */}
                        <option value="paid">Paid</option>
                      </FormControl>
                    </td>
                  </tr>
                )}

                {this.state.shipmentStatus === "paid" && (
                  <tr>
                    <td>Amount Paid</td>
                    <td style={{ paddingLeft: "20px" }}>
                      <Form.Control required type="number" step="any" name="amountPaid" />
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
            {this.props.isSendingEmail === true ? (
              <Spinner animation="border" role="status"></Spinner>
            ) : (
              <Button variant="primary" type="submit">
                Update
              </Button>
            )}
          </Form>
        </Modal.Body>
      </Modal>
    );
  }
}

export default ModalEmailVendorChangeStatus;
