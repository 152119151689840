import React, { Component } from "react";
import {
  loadShipments,
  loadSKUsInShipment,
  resolveSKUs,
  itemDetail,
  uploadState,
  fetchUser,
} from "../includes/HelperBoxContent";
//import "../assets/style/BoxContent.scoped.css";
import shipmentPreloader from "../assets/images/preloader_01_01.gif";
import { DummySKUList } from "../includes/DummyData";
import { Container, Row, Col } from "react-bootstrap";
import { certString } from "../includes/StaticLiterals";
import TopMenu from "../components/TopMenu";
import ShipmentSelectOption from "../components/BoxContent/ShipmentSelectOption";
import ItemSearch from "../components/BoxContent/ItemSearch";
import AllBoxItems from "../components/BoxContent/AllBoxItems";
import bwipjs from "bwip-js";
import * as qz from "qz-tray";
import Cookies from "universal-cookie";
const cookies = new Cookies();

qz.security.setCertificatePromise(function (resolve, reject) {
  resolve(certString);
});

qz.api.setPromiseType(function promise(resolver) {
  return new Promise(resolver);
});

qz.security.setSignaturePromise(function (toSign) {
  return async function (resolve, reject) {
    // fetch("https://lavneet.com/caleb/sign-message.php?request=" + toSign)
    //   .then(r => r.text())
    //   .then(text => {
    //     resolve(text);
    //   });
    resolve();
  };
});

class BoxContent extends Component {
  constructor() {
    super();
    this.onAsinSelect = this.onAsinSelect.bind(this);
    this.getBoxCountInShipment = this.getBoxCountInShipment.bind(this);
    this.moveItemToDiffBox = this.moveItemToDiffBox.bind(this);
    this.connectToQZ = this.connectToQZ.bind(this);
    this.loadState = this.loadState.bind(this);
    this.generateFNSKU = this.generateFNSKU.bind(this);
    this.deleteItemFromWorkspace = this.deleteItemFromWorkspace.bind(this);
  }

  state = {
    admin_data: cookies.get("admin_data"),

    /** List of all shipments pulled from amazon */
    shipments: [],

    /** To show preloader while shipments are being pulled  */
    loading: true,

    /** To show preloader while item detail (Title & Weight) is being pulled */
    loading_item: false,

    /** List of all items combined from all the shipments, that are yet to be assigned to a box */
    activeItems: [],

    /** User's printer Configuration */
    currentFNSKUPrinter: "",
    fnskuWidth: 0,
    fnskuHeight: 0,
    barcodeHeight: 0,
    fnskuFontSize: 0,

    /** Is QZ Connected? */
    isQZConnected: false,

    /** Sync-Date-Time String */
    syncDateTime: "",

    /** If Connected to QZ, what's the selected printer (printer object; not the name) */
    QZPrinter: null,
  };

  /** Handles connection to QZ Client tool.
   * If the client software is exited, the `isQZConnected` in state will be updated to False
   */
  connectToQZ() {
    const currentFNSKUPrinter = this.state.currentFNSKUPrinter;
    !this.state.isQZConnected &&
      qz.websocket
        .connect()
        .then(() => {
          this.setState({ isQZConnected: true });
          return qz.printers.find(currentFNSKUPrinter); // Pass the printer name into the next Promise
        })
        .then((printer) => {
          this.setState({ QZPrinter: printer });
        })
        .catch(function (e) {
          console.error(e);
        });

    qz.websocket.setClosedCallbacks((evt) => {
      this.setState({ isQZConnected: false });
    });
  }

  async componentDidMount() {
    // Fetch Data
    console.log("Component Mounted. Calling api ...");
    this.setState({
      shipments: await loadShipments(this.state.admin_data.admin_id, this.state.admin_data.token),
      loading: false,
    });

    // Fetch User's current data
    const userData = await fetchUser();
    if (userData.current_printer !== null || userData.current_printer !== "") {
      this.setState({
        currentFNSKUPrinter: userData.current_printer,
        fnskuHeight: userData.fnsku_height,
        fnskuWidth: userData.fnsku_width,
        barcodeHeight: userData.barcode_height,
        fnskuFontSize: userData.fnsku_font_size,
      });
    }

    // Connect to QZ if available
    this.connectToQZ();
  }

  async addShipmentToWorkspace(index, e) {
    /** 1. Update status of shipment to "loading" to show ripples preloader
     *  2. Initialize Shipment's boxes to Empty Array
     *  3. Set "activeBoxIndex" to 0. i.e. All items initially must go to the first box.
     */
    let shipments = [...this.state.shipments];
    shipments[index].status = "loading";
    shipments[index].boxes = [];
    shipments[index].activeBoxIndex = 0;
    this.setState({ shipments: shipments });

    /** Get SKUs in this shipment */
    let dataSkuQuantInShipment = [];
    if (shipments[index].ShipmentId.toString() === "FBA15JD78WYJ") dataSkuQuantInShipment = DummySKUList;
    else
      dataSkuQuantInShipment = await loadSKUsInShipment(
        this.state.admin_data.admin_id,
        this.state.admin_data.token,
        shipments[index].ShipmentId
      );

    if (dataSkuQuantInShipment[0].hasOwnProperty("ShipmentId")) {
      /** Count Total Items in Workspace. Items X QuantityShipped */
      shipments[index].totalItems = dataSkuQuantInShipment.reduce((sum, val) => sum + parseInt(val.QuantityShipped), 0);

      /** 1. Resolve SKUs: Get Items ASIN, & FNSKU for a list of SKUs.
       *  2. Fill shipment.items with the response.
       */
      const allSKUs = dataSkuQuantInShipment.map((a) => a["SellerSKU"].toString());
      const itemsforShipment = await resolveSKUs(allSKUs);
      shipments[index].items = itemsforShipment;

      itemsforShipment.forEach((itemForShipment) => {
        /** Get Shipment Id & Destination FulfillmentCenter from "Shipment Data" and add to individual item  */
        itemForShipment.ShipmentId = shipments[index].ShipmentId.toString();
        itemForShipment.DestinationFulfillmentCenterId = shipments[index].DestinationFulfillmentCenterId.toString();

        /** Get Quantity from "SKUQuantity Data" and add to individual item  */
        const indexIteminSkuQuantData = dataSkuQuantInShipment.findIndex((i) => {
          return i.SellerSKU.toString() === itemForShipment.SKU;
        });
        itemForShipment.quantity = parseInt(dataSkuQuantInShipment[indexIteminSkuQuantData].QuantityShipped.toString());
        itemForShipment.assignedToBoxes = 0;

        /** Finally, check if item already exists in state.activeItems. Append if it doesn't  */
        if (this.state.activeItems.filter((e) => e.SKU === itemForShipment.SKU).length <= 0) {
          this.setState({ activeItems: [...this.state.activeItems, itemForShipment] });
        }
      });
    } else {
      shipments[index].totalItems = 0;
    }

    /** Finally, mark the status of shipment to "Checked" to hide the preloader and show circular progress bar */
    shipments[index].status = "checked";
    this.setState({ shipments: shipments });
  }

  removeShipmentFromWorkspace(index, e) {
    let shipments = [...this.state.shipments];

    const ShipmentIdToRemove = shipments[index].ShipmentId.toString();

    /** Delete shipment from shipments[] */
    shipments[index].status = "unchecked";

    shipments[index].boxes = [];
    shipments[index].items = [];
    shipments[index].activeBoxIndex = 0;
    shipments[index].totalItems = 0;

    /** Remove Items from ActiveItems */

    let activeItems = [...this.state.activeItems];
    activeItems = activeItems.filter((item) => item.ShipmentId !== ShipmentIdToRemove);

    this.setState({ shipments: shipments, activeItems: activeItems });
  }

  /** Generate FNSKU Label */
  generateFNSKU(item) {
    let canvas = document.createElement("canvas");
    let FNSKUFontSize = this.state.fnskuFontSize;
    if (FNSKUFontSize === "" || FNSKUFontSize === 0) FNSKUFontSize = 16;
    new Promise((resolve, reject) => {
      bwipjs(
        canvas,
        {
          bcid: "code128",
          text: item.Fnsku,
          scale: 3, // 3x scaling factor
          height: this.state.barcodeHeight, // Bar height, in millimeters
          includetext: true,
          textxalign: "center",
        },
        function (err, cvs) {
          if (err) {
            return err;
          } else {
            // Get context to original canvas
            let ctx = canvas.getContext("2d");
            const originalCanvasHeight = canvas.height;

            // Make temporary canvas
            let inMemCanvas = document.createElement("canvas");
            let inMemCtx = inMemCanvas.getContext("2d");

            // Resize canvas
            inMemCanvas.width = canvas.width;
            inMemCanvas.height = canvas.height;
            inMemCtx.drawImage(canvas, 0, 0);
            canvas.height = 500;
            ctx.drawImage(inMemCanvas, 0, 0);
            ctx.font = FNSKUFontSize + "px Arial";

            // Add Title, FNSKU & Item Condition to the Label
            const yFNSKU = originalCanvasHeight + 5;
            const textStringFNSKU = item.Fnsku;
            const textWidthFNSKU = ctx.measureText(textStringFNSKU).width;
            ctx.fillText(textStringFNSKU, canvas.width / 2 - textWidthFNSKU / 2, yFNSKU);

            const itemTitle = item.Title.substr(0, 40 - 1) + (item.Title.length > 40 ? "..." : "");

            const yTitle = originalCanvasHeight + 30;
            const textStringTitle = itemTitle + " - " + item.Condition;
            const textWidthTitle = ctx.measureText(textStringTitle).width;
            ctx.fillText(textStringTitle, canvas.width / 2 - textWidthTitle / 2, yTitle);

            const dataURL = canvas.toDataURL("image/png");
            resolve(dataURL);
          }
        }
      );
    }).then((b64) => {
      /** Send to QZ */
      if (this.state.isQZConnected && this.state.fnskuWidth > 0 && this.state.fnskuHeight > 0) {
        var config = qz.configs.create(this.state.QZPrinter, {
          size: { width: this.state.fnskuWidth, height: this.state.fnskuHeight },
          units: "in",
        }); // Create a default config for the found printer
        var data = [
          {
            type: "image",
            format: "base64",
            data: b64.split(",")[1],
          },
        ];
        qz.print(config, data).catch(function (e) {
          console.error(e);
        });
      } else {
        //this.connectToQZ();
      }
    });
  }

  /** Add Selected Item From 'activeItems' to item.boxes.box
   *  Boxes[] is an array of Individual Boxes in Every Shipment. Key ranges from 0 to N
   *  Where N is the total number of boxes.
   */
  async moveToBox(selectedAsin, callback) {
    if (selectedAsin !== undefined) {
      const shipmentIdOfAsin = selectedAsin.ShipmentId;

      /** Get Shipment Associated with Selected ITEM */
      const shipmentIndex = this.state.shipments.findIndex((shipment) => {
        return shipment.ShipmentId.toString() === shipmentIdOfAsin;
      });
      let shipmentsClone = [...this.state.shipments];
      let thisShipment = shipmentsClone[shipmentIndex];

      /** Get number of boxes & activeBoxIndex in the Shipment */
      const boxesInShipment = thisShipment.boxes.length;
      const activeBoxIndex = thisShipment.activeBoxIndex;

      /** Check if the item exists in one of the boxes. If not, get Title and  */
      let isFoundInBoxes = false;
      thisShipment.boxes.forEach((box, indexBox) => {
        box.items.forEach((item, indexItem) => {
          if (item.Asin === selectedAsin.Asin) {
            console.log("Book Found in Box #" + indexBox + ". Getting Weight & Title");
            selectedAsin.Title = item.Title;
            selectedAsin.Weight = item.Weight;
            isFoundInBoxes = true;
            return;
          }
        });
      });
      if (!isFoundInBoxes) {
        /** Call item_detail to get title and weight */
        this.setState({ loading_item: true });
        const itemDetails = await itemDetail(selectedAsin.SKU);
        selectedAsin.Weight = itemDetails.Weight;
        selectedAsin.Title = itemDetails.Title;
        this.setState({ loading_item: false });
      }

      /** Generate FNSKU Label */
      this.generateFNSKU(selectedAsin);

      if (boxesInShipment > 0) {
        /** Check if ITEM already exist in is the `current` box. Increment quantityInBox if it does. */
        let isFoundInBox = false;
        thisShipment.boxes[activeBoxIndex].items.forEach((item, index) => {
          if (item.Asin === selectedAsin.Asin) {
            console.log("Book Found in Box #" + activeBoxIndex + "Updating " + index + " in box " + activeBoxIndex);
            thisShipment.boxes[activeBoxIndex].items[index].quantityInBox += 1;
            thisShipment.boxes[activeBoxIndex].items[index].lastUpdated = Date.now();
            isFoundInBox = true;
            return;
          }
        });
        /** Fetch Item Title & Weight using `item_detail` endpoint & Add a New Item to the activeBox if it doesn't */
        if (!isFoundInBox) {
          selectedAsin.quantityInBox = 1;
          selectedAsin.lastUpdated = Date.now();
          thisShipment.boxes[activeBoxIndex].items.push(JSON.parse(JSON.stringify(selectedAsin)));
        }
      } else {
        /** If shipment has no boxes yet, create one and add item to the first box */
        let newBox = {
          id: thisShipment.ShipmentId.toString() + "-0",
          id_count: 0,
        };
        selectedAsin.quantityInBox = 1;
        selectedAsin.lastUpdated = Date.now();
        newBox.items = [JSON.parse(JSON.stringify(selectedAsin))];
        thisShipment.boxes.push(newBox);
      }

      /** Update shipments state with updated BOXES information */
      shipmentsClone[shipmentIndex] = thisShipment;
      this.setState({ shipments: shipmentsClone });

      /** Increment `assignedToBoxes` of item; so we know how many of it has been assigned to Boxes. */
      const indexActiveItems = this.state.activeItems.findIndex((item) => {
        return item.Asin === selectedAsin.Asin;
      });
      let activeItemsClone = [...this.state.activeItems];
      activeItemsClone[indexActiveItems].assignedToBoxes += 1;
      this.setState({ activeItems: activeItemsClone });

      /** Upload Shipments state  */
      uploadState(this.state.shipments, this.state.activeItems, () => {
        var currentdate = new Date();
        var lastSync =
          "Last Sync: " +
          currentdate.getDate() +
          "/" +
          (currentdate.getMonth() + 1) +
          "/" +
          currentdate.getFullYear() +
          " @ " +
          currentdate.getHours() +
          ":" +
          currentdate.getMinutes() +
          ":" +
          currentdate.getSeconds();
        this.setState({ syncDateTime: lastSync });
      });

      if (callback) callback();
    }
  }

  /** Gets called when user selects ITEM from the autocomplete drop down */
  onAsinSelect(selectedAsins, callback, e) {
    let selectedAsin = selectedAsins[0];
    this.moveToBox(selectedAsin, callback);
  }

  /** Flattens all the items (from every shipments' boxes) into a single array */
  flattenAllBoxContens() {
    let flattenBoxes = [];
    this.state.shipments.forEach((shipment) => {
      if (shipment.boxes !== undefined) {
        shipment.boxes.forEach((box) => {
          const BoxIdCount = box.id_count;
          box.items.forEach((item) => {
            item.BoxIdCount = BoxIdCount;
            flattenBoxes.push(item);
          });
        });
      }
    });
    return flattenBoxes;
  }

  /** Called when user clicks on 'Add New Box' option */
  addNewBoxToShipment(shipmentIndex, e) {
    let shipmentsClone = [...this.state.shipments];
    let thisShipment = shipmentsClone[shipmentIndex];

    /** newBox's `id` will be used as "key" name for ShipmentBox component */
    const lastBoxIdCount = thisShipment.boxes[thisShipment.boxes.length - 1].id_count;
    const newBoxIdCount = lastBoxIdCount + 1;

    let newBox = {
      id: thisShipment.ShipmentId.toString() + "-" + newBoxIdCount,
      id_count: newBoxIdCount,
    };
    newBox.items = [];
    thisShipment.boxes.push(newBox);

    /** Mark this Box as currently active */
    thisShipment.activeBoxIndex = newBoxIdCount;

    shipmentsClone[shipmentIndex] = thisShipment;

    this.setState({ shipments: shipmentsClone });
  }

  markActive(indexShipment, indexBox, e) {
    let shipmentsClone = [...this.state.shipments];
    shipmentsClone[indexShipment].activeBoxIndex = indexBox;
    this.setState({ shipments: shipmentsClone });
  }

  getBoxCountInShipment(item) {
    const thisShipment = this.state.shipments.filter(
      (shipment) => shipment.ShipmentId.toString() === item.ShipmentId
    )[0];
    return thisShipment.boxes.length;
  }

  /** Move item between boxes */
  moveItemToDiffBox(item, moveQty, destinationBox) {
    /** Step 1. Get Item's current Box & Shipment ID */
    let shipmentsClone = [...this.state.shipments];
    const sourceBoxIndex = item.BoxIdCount;

    const itemShipmentIndex = shipmentsClone.findIndex((d) => {
      return d.ShipmentId.toString() === item.ShipmentId;
    });

    /** Step 2. Update BoxIdCount to destination box */
    const destinationBoxIndex = destinationBox - 1;
    let itemClone = JSON.parse(JSON.stringify(item));
    itemClone.BoxIdCount = destinationBoxIndex;
    itemClone.lastUpdated = Date.now();

    /** Step 3. Add to Destination Box */
    let currentShipment = shipmentsClone[itemShipmentIndex];
    if (currentShipment.boxes[destinationBoxIndex].items.filter((e) => e.Asin === item.Asin).length <= 0) {
      /** Item Doesn't Exists in Destination Box. Push to Array */
      itemClone.quantityInBox = Number(moveQty);
      currentShipment.boxes[destinationBoxIndex].items.push(itemClone);
    } else {
      /** Item Already Exists in Destination Box. Just update the `quantityInBox` & `lastUpdated`. */
      const destinationBoxItemIndex = currentShipment.boxes[destinationBoxIndex].items.findIndex((d) => {
        return d.Asin === item.Asin;
      });
      currentShipment.boxes[destinationBoxIndex].items[destinationBoxItemIndex].quantityInBox += Number(moveQty);
      currentShipment.boxes[destinationBoxIndex].items[destinationBoxItemIndex].lastUpdated += Date.now();
    }

    /** Step 4. Subtract `moveQty` from current box. If `quantityInBox` after move = 0, remove from array */
    const sourceBoxItemIndex = currentShipment.boxes[sourceBoxIndex].items.findIndex((d) => {
      return d.Asin === item.Asin;
    });
    const newQuantity = Number(currentShipment.boxes[sourceBoxIndex].items[sourceBoxItemIndex].quantityInBox) - moveQty;
    if (newQuantity > 0) {
      currentShipment.boxes[sourceBoxIndex].items[sourceBoxItemIndex].quantityInBox = newQuantity;
    } else {
      currentShipment.boxes[sourceBoxIndex].items.splice(sourceBoxItemIndex, 1);
    }

    /** Finally, update the `shipments` state */
    shipmentsClone[itemShipmentIndex] = currentShipment;
    this.setState({ shipments: shipmentsClone });
  }

  /** Remove 'Item' from Workspace. Will be used as `prop` for `ListWorksapceItem.js` component */
  deleteItemFromWorkspace(item) {
    const shipmentIndex = this.state.shipments.findIndex((shipment) => {
      return shipment.ShipmentId.toString() === item.ShipmentId;
    });
    this.deleteItemFromBox(shipmentIndex, item.BoxIdCount, item);
  }

  /** Remove `Asin` from box and add back to `activeItems` */
  deleteItemFromBox(shipmentIndex, boxIdIndex, item) {
    console.log(shipmentIndex, boxIdIndex, item);

    /** Get QuantityInBox of ITEM (`Asin`) from the `boxIdIndex`. Remove it from the box. And Subtract `QuantityInBox` from `assignedToBox` of the `activeItem`  */

    const quantityInBox = item.quantityInBox;

    let shipmentsClone = [...this.state.shipments];
    let activeItemsClone = [...this.state.activeItems];

    const itemIndexInBox = shipmentsClone[shipmentIndex].boxes[boxIdIndex].items.findIndex((d) => {
      return d.Asin === item.Asin;
    });
    shipmentsClone[shipmentIndex].boxes[boxIdIndex].items.splice(itemIndexInBox, 1);

    const itemIndexInActive = activeItemsClone.findIndex((activeItem) => {
      return activeItem.Asin === item.Asin && activeItem.ShipmentId === item.ShipmentId;
    });

    activeItemsClone[itemIndexInActive].assignedToBoxes -= Number(quantityInBox);
    this.setState({ shipments: shipmentsClone, activeItems: activeItemsClone });
  }

  async loadState() {
    let shipments = [];
    let activeItems = [];
    this.setState({ shipments: shipments, activeItems: activeItems, loading: true });

    // Fetch User's current data
    const userData = await fetchUser();
    const shipmentsState = JSON.parse(userData.shipments_state);
    const activeItemsState = JSON.parse(userData.active_items_state);
    this.setState({
      shipments: shipmentsState,
      activeItems: activeItemsState,
      loading: false,
    });
  }

  render() {
    const filterByCallback = (option, props) =>
      option.Asin.toLowerCase().indexOf(props.text.toLowerCase()) !== -1 ||
      option.Fnsku.toLowerCase().indexOf(props.text.toLowerCase()) !== -1 ||
      option.SKU.toLowerCase().indexOf(props.text.toLowerCase()) !== -1;

    return (
      <React.Fragment>
        <TopMenu
          handlenavigationroutes={(routeName) => {
            // abortRequests();
            this.props.history.push(routeName);
          }}
          isReevaluate={
            "is_re_evaluate" in this.state.admin_data && this.state.admin_data.is_re_evaluate === 1 ? true : false
          }
          history={this.props.history}
          activeKey="/box-tool-2"
          adminData={this.state.admin_data}
        />

        <Container fluid={true}>
          <Row>
            <Col className="shipmentSelCol" md={4}>
              {this.state.loading ? (
                <div>
                  <img
                    className="preloader d-flex align-items-center justify-content-center"
                    alt="Loading Shipments"
                    src={shipmentPreloader}
                  />
                </div>
              ) : (
                this.state.shipments !== undefined &&
                this.state.shipments.map((shipment, index) => (
                  <ShipmentSelectOption
                    key={shipment.ShipmentId}
                    shipment={shipment}
                    deleteItemFromBox={this.deleteItemFromBox.bind(this, index)}
                    markActive={this.markActive.bind(this, index)}
                    addShipmentToWorkspace={this.addShipmentToWorkspace.bind(this, index)}
                    removeShipmentFromWorkspace={this.removeShipmentFromWorkspace.bind(this, index)}
                    addNewBoxToShipment={this.addNewBoxToShipment.bind(this, index)}
                  />
                ))
              )}
            </Col>
            <Col md={8} className="shipmentWorkspaceCol">
              <Row style={{ minHeight: "70px" }}>
                <Col md={12}>
                  {this.state.activeItems.length > 0 && (
                    <ItemSearch
                      key="item-search"
                      loading_item={this.state.loading_item}
                      filterByCallback={filterByCallback}
                      handleAsinSelect={this.onAsinSelect}
                      activeItems={this.state.activeItems}
                    />
                  )}
                </Col>
                <Col />
              </Row>

              <Row>
                <Col md={12}>
                  {this.state.shipments !== undefined && (
                    <AllBoxItems
                      key="all-box-items"
                      generateFNSKU={this.generateFNSKU}
                      deleteItemFromWorkspace={this.deleteItemFromWorkspace}
                      boxcontents={this.flattenAllBoxContens()}
                      getBoxes={this.getBoxCountInShipment}
                      moveItemToDiffBox={this.moveItemToDiffBox}
                    />
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default BoxContent;
