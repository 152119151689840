import React, { Component, Fragment } from "react";
import TopMenu from "../components/TopMenu";
import { Row, Col, Container, Badge, Button, Spinner, OverlayTrigger, Tooltip, Table, Popover } from "react-bootstrap";
import Cookies from "universal-cookie";
import ModalEmailVendor from "../components/Others/ModalEmailVendor";
import ModalEmailUBB from "../components/Others/ModalEmailUBB";
import ModalEmailVendorDataPosted from "../components/Others/ModalEmailVendorDataPosted";
import ModalEmailVendorChangeStatus from "../components/Others/ModalEmailVendorChangeStatus";
import ModalFedex from "../components/Others/ModalFedex";
import { apiBaseUrlLocal } from "../includes/AppConfig";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import Lottie from "react-lottie";
import ReactTooltip from "react-tooltip";
import animationData from "../assets/animations/3321-shipment.json";

// Import React Table
import ReactTable from "react-table";
import "react-table/react-table.css";

const cookies = new Cookies();

class Shipments extends Component {
  state = {
    shipmentsList: [],
    showModal: false,
    showModalUBB: false,
    showFedexModal: false,
    inProcessSubmitData: false,
    isFedexProcessing: false,
    inProcessSubmitFedex: false,
    isFedexDone: false,
    fedexResponse: {},
    showModalPostedData: false,
    showModalUpdateShipmentStatus: false,
    isLoadingShipmentsList: false,
    selectedShipment: {},
    filtered: [],
    shipmentAddress: {},
    emailBOL: "",
    admin_data: cookies.get("admin_data"),
  };

  constructor() {
    super();
    this.loadShipments = this.loadShipments.bind(this);
    this.submitShipmentData = this.submitShipmentData.bind(this);
    this.updateShipmentStatus = this.updateShipmentStatus.bind(this);
    this.pushToAmazon = this.pushToAmazon.bind(this);
    this.onFilteredChangeCustom = this.onFilteredChangeCustom.bind(this);
    this.downloadListingCSV = this.downloadListingCSV.bind(this);
    this.submitFedexLabel = this.submitFedexLabel.bind(this);
  }

  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  // testCall(x) {
  //   console.log(x);
  // }

  componentDidMount() {
    this.loadShipments();
    this.extractShipmentAddress();
  }

  extractShipmentAddress() {
    try {
      if ("shipment_address" in this.state.admin_data) {
        const shipmentAddress = JSON.parse(this.state.admin_data.shipment_address);
        this.setState({
          shipmentAddress,
          emailBOL: "email_bol" in this.state.admin_data ? this.state.admin_data.email_bol : "",
        });
      }
    } catch (error) {
      console.log(`Invalid or missing shipment address`);
    }
  }

  async pushToAmazon(selectedShipment) {
    this.setState({ pushingToAmazon: true });

    await fetch(apiBaseUrlLocal + "admin/shipments/pushtoamazon", {
      method: "POST",
      body: new URLSearchParams({
        user_id: this.state.admin_data.admin_id,
        app_token: this.state.admin_data.token,
        shipment_name: selectedShipment.name,
      }),
    });

    this.setState({ pushingToAmazon: false });
  }

  onFilteredChangeCustom = (value, accessor) => {
    let filtered = this.state.filtered;
    let insertNewFilter = 1;

    if (filtered.length) {
      filtered.forEach((filter, i) => {
        if (filter["id"] === accessor) {
          if (value === "" || !value.length) filtered.splice(i, 1);
          else filter["value"] = value;

          insertNewFilter = 0;
        }
      });
    }

    if (insertNewFilter) {
      filtered.push({ id: accessor, value: value });
    }

    this.setState({ filtered: filtered });
  };

  getUSFormattedDate = (dateString) => {
    var date = new Date(dateString);
    var year = date.getFullYear();

    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : "0" + month;

    var day = date.getDate().toString();
    day = day.length > 1 ? day : "0" + day;

    return month + "-" + day + "-" + year;
  };

  async downloadListingCSV(selectedShipment) {
    this.setState({ downloadingCSV: true, downloadCSVShipmentName: selectedShipment.name });

    const response = await fetch(apiBaseUrlLocal + "admin/shipments/export", {
      method: "POST",
      body: new URLSearchParams({
        user_id: this.state.admin_data.admin_id,
        app_token: this.state.admin_data.token,
        shipment_name: selectedShipment.name,
      }),
    });
    if (response.status === 200) {
      const data = await response.json();

      if ("status" in data && data.status === true) {
        let csvContent = "data:text/csv;charset=utf-8,";
        console.log(data);

        // csvContent += "ASIN, SKU, QUANTITY, TITLE, CONDITION, CONDITION_NOTES, BUY_COST, SELF_WEAR, SOURCE, LISTING_PROFILE";

        csvContent +=
          (selectedShipment.name.toLowerCase().includes("rms")
            ? "ASIN,TITLE,PRICE,VENDOR,SCANNED ON"
            : selectedShipment.name.toLowerCase().includes("fba") || selectedShipment.name.toLowerCase().includes("mf")
            ? "ASIN,TITLE,TARGET_PROFIT,TARGET_PRICE,SCANNED ON"
            : "ASIN,TITLE,PRICE,SCANNED ON") + "\r\n";

        data.data.forEach(function (rowArray) {
          //console.log(rowArray);
          // let row =
          //   '"' +
          //   rowArray.asin +
          //   '",' +
          //   rowArray.sku +
          //   "," +
          //   rowArray.quantity +
          //   ',"' +
          //   rowArray.title +
          //   '",' +
          //   rowArray.condition +
          //   "," +
          //   rowArray.conditionNotes +
          //   "," +
          //   rowArray.buyCost +
          //   "," +
          //   rowArray.selfWear +
          //   "," +
          //   rowArray.source +
          //   "," +
          //   rowArray.listingProfileName;

          const scannedDate = new Date(rowArray.scanned_on);
          const yearScanned = scannedDate.getFullYear();
          let monthScanned = (1 + scannedDate.getMonth()).toString();
          monthScanned = monthScanned.length > 1 ? monthScanned : "0" + monthScanned;

          let dayScanned = scannedDate.getDate().toString();
          dayScanned = dayScanned.length > 1 ? dayScanned : "0" + dayScanned;

          //return month + "-" + day + "-" + year;

          let row =
            '="' +
            rowArray.asin +
            '","' +
            rowArray.title +
            '",' +
            (selectedShipment.name.toLowerCase().includes("fba")
              ? rowArray.fba_estimated_profit + "," + rowArray.fba_target_price
              : selectedShipment.name.toLowerCase().includes("mf")
              ? rowArray.mf_estimated_profit + "," + rowArray.mf_target_price
              : rowArray.price) +
            "," +
            (selectedShipment.name.toLowerCase().includes("rms") ? rowArray.hl_winner + "," : "") +
            (monthScanned + "-" + dayScanned + "-" + yearScanned);

          csvContent += row + "\r\n";
        });

        var encodedUri = encodeURI(csvContent);
        const fixedEncodedURI = encodedUri.replaceAll("#", "%23");
        var link = document.createElement("a");
        link.setAttribute("href", fixedEncodedURI);
        link.setAttribute("download", `${selectedShipment.name}.csv`);
        document.body.appendChild(link); // Required for FF

        link.click(); // This will download the data file named "my_data.csv".

        //console.log(data);
      }
    }
    this.setState({ downloadingCSV: false, downloadCSVShipmentName: null });
  }

  async loadZiffitPrices() {
    const ziffitTradeIds = this.state.shipmentsList
      .filter((shipment) => shipment.ziffit_trade_id !== null && shipment.ziffit_trade_id !== "")
      .map((shipment) => shipment.ziffit_trade_id);

    const response = await fetch(apiBaseUrlLocal + "admin/shipments/ziffit-trades-info", {
      method: "POST",
      body: new URLSearchParams({
        user_id: this.state.admin_data.admin_id,
        app_token: this.state.admin_data.token,
        trade_ids: JSON.stringify(ziffitTradeIds),
      }),
    });
    if (response.status === 200) {
      const data = await response.json();
      if ("status" in data && data.status === true) {
        const ziffitTradesInfo = data.data;

        let shipmentsList = [...this.state.shipmentsList];
        for (let i = 0; i < shipmentsList.length; i++) {
          if (shipmentsList[i].display_name === "ZIFFIT") {
            let tradeIndex = ziffitTradesInfo.findIndex(
              (ziffitTradeInfo) => ziffitTradeInfo.trade_id === shipmentsList[i].ziffit_trade_id
            );
            if (tradeIndex > -1) {
              shipmentsList[i].ziffitInfo = ziffitTradesInfo[tradeIndex];
            }
          }
        }

        this.setState({ shipmentsList });
      }
    }
  }

  async loadShipments() {
    this.setState({ isLoadingShipmentsList: true });
    const response = await fetch(apiBaseUrlLocal + "admin/shipments/list", {
      method: "POST",
      body: new URLSearchParams({
        user_id: this.state.admin_data.admin_id,
        app_token: this.state.admin_data.token,
      }),
    });
    if (response.status === 200) {
      const data = await response.json();
      if ("status" in data && data.status === true) {
        let shipmentsList = data.data;
        // for (let i = 0; i < shipmentsList.length; i++) {
        //   if (shipmentsList[i].display_name === "ZIFFIT") {
        //     shipmentsList[i].potential_profit = -10;
        //     shipmentsList[i].item_count = -10;
        //   }
        // }
        this.setState({ shipmentsList }, () => this.loadZiffitPrices());
      }
    }
    this.setState({ isLoadingShipmentsList: false });
  }

  async updateShipmentStatus(shipmentStatus, amountPaid) {
    this.setState({ isLoadingShipmentsList: true });
    const response = await fetch(apiBaseUrlLocal + "admin/shipments/update_status", {
      method: "POST",
      body: new URLSearchParams({
        user_id: this.state.admin_data.admin_id,
        app_token: this.state.admin_data.token,
        shipment_status: shipmentStatus,
        amount_paid: amountPaid,
        shipment_id: this.state.selectedShipment.id,
      }),
    });
    if (response.status === 200) {
      const data = await response.json();
      if ("status" in data && data.status === true)
        this.setState({ shipmentsList: data.data, showModalUpdateShipmentStatus: false });
    }
    this.setState({ isLoadingShipmentsList: false });
  }

  async submitFedexLabel(shipmentData) {
    this.setState({ inProcessSubmitFedex: true });
    const response = await fetch(apiBaseUrlLocal + "admin/shipments/fedex/submit", {
      method: "POST",
      body: new URLSearchParams({
        user_id: this.state.admin_data.admin_id,
        app_token: this.state.admin_data.token,
        shipment_data: JSON.stringify(shipmentData),
        shipment_id: this.state.selectedShipment.id,
      }),
    });
    if (response.status === 200) {
      const data = await response.json();
      if ("status" in data && data.status === true) this.setState({ fedexResponse: data.data, isFedexDone: true });
    }
    this.setState({ inProcessSubmitFedex: false, isFedexDone: true });
  }

  async submitShipmentData(shipmentData) {
    if ("addressForPickup" in shipmentData) {
      this.setState({
        shipmentAddress: {
          name: shipmentData.contactName,
          phone: shipmentData.contactPhone,
          address: shipmentData.addressForPickup,
          address_type: shipmentData.addressType,
          email_bol: shipmentData.emailBOL,
        },
      });
    }
    this.setState({ inProcessSubmitData: true });
    const response = await fetch(apiBaseUrlLocal + "admin/shipments/submit", {
      method: "POST",
      body: new URLSearchParams({
        user_id: this.state.admin_data.admin_id,
        app_token: this.state.admin_data.token,
        shipment_data: JSON.stringify(shipmentData),
        shipment_id: this.state.selectedShipment.id,
      }),
    });
    if (response.status === 200) {
      const data = await response.json();
      if ("status" in data && data.status === true) this.setState({ shipmentsList: data.data });
    }
    this.setState({ inProcessSubmitData: false, showModalUBB: false });
  }

  async deleteShipment(shipmentData) {
    this.setState({ isLoadingShipmentsList: true });
    const response = await fetch(`${apiBaseUrlLocal}admin/shipments/${shipmentData.id}`, {
      method: "DELETE",
      body: new URLSearchParams({
        user_id: this.state.admin_data.admin_id,
        app_token: this.state.admin_data.token,
      }),
    });
    if (response.status === 200) {
      const data = await response.json();
      if ("status" in data && data.status === true) this.setState({ shipmentsList: data.data });
    }
    this.setState({ isLoadingShipmentsList: false });
  }

  handleDeleteShipment(shipmentData) {
    confirmAlert({
      title: "Delete Shipment?",
      message: "This will permanently unmap items associated with this Shipment. Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.deleteShipment(shipmentData),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  }

  getTrProps = (state, rowInfo, instance) => {
    if (rowInfo) {
      //console.log(rowInfo);
      return {
        style: {
          background:
            rowInfo.original.paid_on !== null && rowInfo.original.paid_on !== ""
              ? "#4caf50"
              : rowInfo.original.closed_on !== null && rowInfo.original.closed_on !== ""
              ? "#ddd"
              : rowInfo.original.is_current === 1
              ? "#c0c0ff"
              : "white",
          color:
            rowInfo.original.is_current === 1 ||
            rowInfo.original.closed_on !== null ||
            rowInfo.original.paid_on !== null
              ? "#404040"
              : "black",
          fontWeight:
            rowInfo.original.is_current === 1 ||
            rowInfo.original.closed_on !== null ||
            rowInfo.original.paid_on !== null
              ? "bold"
              : "normal",
        },
      };
    }
    return {};
  };

  render() {
    const defaultLottieOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    return (
      <Fragment>
        <TopMenu
          handlenavigationroutes={(routeName) => {
            // abortRequests();
            this.props.history.push(routeName);
          }}
          isReevaluate={
            "is_re_evaluate" in this.state.admin_data && this.state.admin_data.is_re_evaluate === 1 ? true : false
          }
          adminData={this.state.admin_data}
          history={this.props.history}
          activeKey="/shipments"
        />

        <ModalFedex
          isFedexDone={this.state.isFedexDone}
          inProcessSubmitFedex={this.state.inProcessSubmitFedex}
          fedexResponse={this.state.fedexResponse}
          submitFedexLabel={this.submitFedexLabel}
          selectedShipment={this.state.selectedShipment}
          showModal={this.state.showFedexModal}
          handleClose={() => this.setState({ showFedexModal: false })}
        />
        <ModalEmailVendor
          emailBOL={this.state.emailBOL}
          inProcessSubmitData={this.state.inProcessSubmitData}
          shipmentAddress={this.state.shipmentAddress}
          submitShipmentData={this.submitShipmentData}
          showModal={this.state.showModal}
          handleClose={() => this.setState({ showModal: false })}
          selectedShipment={this.state.selectedShipment}
        />
        <ModalEmailUBB
          inProcessSubmitData={this.state.inProcessSubmitData}
          submitShipmentData={this.submitShipmentData}
          showModal={this.state.showModalUBB}
          handleClose={() => this.setState({ showModalUBB: false })}
          selectedShipment={this.state.selectedShipment}
        />

        <ModalEmailVendorDataPosted
          showModal={this.state.showModalPostedData}
          displayName={this.state.selectedShipment.display_name}
          handleClose={() => this.setState({ showModalPostedData: false })}
          dataPosted={
            "submit_data" in this.state.selectedShipment &&
            this.state.selectedShipment.submit_data !== null &&
            this.state.selectedShipment.submit_data !== ""
              ? JSON.parse(this.state.selectedShipment.submit_data)
              : {}
          }
        />
        <ModalEmailVendorChangeStatus
          updateShipmentStatus={this.updateShipmentStatus}
          showModal={this.state.showModalUpdateShipmentStatus}
          handleClose={() => this.setState({ showModalUpdateShipmentStatus: false })}
          selectedShipment={this.state.selectedShipment}
        />

        <Container fluid={true} style={{ marginTop: "15px" }}>
          <ReactTooltip place="top" type="info" effect="solid" html={true} />

          <h2>Shipments</h2>
          {/* 
           rowInfo.original.closed_on !== null && rowInfo.original.closed_on !== ""
              ? "green"
              : rowInfo.original.closed_on !== null && rowInfo.original.closed_on !== ""
              ? "#47525d"
              : rowInfo.original.is_current === 1
              ? "#007bff"
              : "white",
      
           */}
          <Row>
            <Col md={12}>
              <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <div style={{ width: 25, height: 25, backgroundColor: "#4caf50", border: "1px solid #ccc" }}></div>
                <div style={{ marginLeft: 10, fontSize: 15 }}>Paid</div>

                <div
                  style={{
                    width: 25,
                    height: 25,
                    backgroundColor: "#DDDDDD",
                    marginLeft: 50,
                    border: "1px solid #ccc",
                  }}
                ></div>
                <div style={{ marginLeft: 10, fontSize: 15 }}>Closed</div>

                <div
                  style={{
                    width: 25,
                    height: 25,
                    backgroundColor: "#C0C0FA",
                    marginLeft: 50,
                    border: "1px solid #ccc",
                  }}
                ></div>
                <div style={{ marginLeft: 10, fontSize: 15 }}>Active</div>

                <div
                  style={{
                    width: 25,
                    height: 25,
                    backgroundColor: "#FFFFFF",
                    marginLeft: 50,
                    border: "1px solid #ccc",
                  }}
                ></div>
                <div style={{ marginLeft: 10, fontSize: 15 }}>InActive</div>
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: "20px", paddingBottom: "20px" }}>
            <Col md={12}>
              {this.state.isLoadingShipmentsList ? (
                <div>
                  <Lottie options={defaultLottieOptions} height={400} width={400} />
                </div>
              ) : (
                <ReactTable
                  showPagination={true}
                  //defaultPageSize={50}
                  pageSize={50}
                  getTrProps={this.getTrProps}
                  processing={true}
                  data={this.state.shipmentsList}
                  filterable
                  filtered={this.state.filtered}
                  onFilteredChange={(filtered, column, value) => {
                    this.onFilteredChangeCustom(value, column.id || column.accessor);
                  }}
                  defaultFilterMethod={(filter, row, column) => {
                    const id = filter.pivotId || filter.id;
                    if (typeof filter.value === "object") {
                      return row[id] !== undefined ? filter.value.toLowerCase().indexOf(row[id]) > -1 : true;
                    } else {
                      return row[id] !== undefined
                        ? String(row[id].toLowerCase()).indexOf(filter.value.toLowerCase()) > -1
                        : true;
                    }
                  }}
                  columns={[
                    {
                      Header: "Shipment Details",
                      columns: [
                        {
                          Header: () => (
                            <div
                              style={{
                                textAlign: "center",
                                fontWeight: "bold",
                              }}
                            >
                              Update Status
                            </div>
                          ),
                          width: 250,
                          id: "closed_on",
                          accessor: (d) =>
                            d.status === "PAID" ? (
                              <span></span>
                            ) : d.status === "CLOSED" ? (
                              <Button
                                style={{ color: "#3f51b5", fontWeight: "bold" }}
                                variant="link"
                                onClick={() => {
                                  this.setState({ selectedShipment: d }, () => {
                                    this.setState({ showModalUpdateShipmentStatus: true });
                                  });
                                }}
                              >
                                Mark as paid
                              </Button>
                            ) : d.status !== "CLOSED" ? (
                              <Button
                                style={{ color: "#000000", fontWeight: "bold" }}
                                variant="link"
                                onClick={() => {
                                  if (d.display_name === "ZIFFIT") {
                                    this.setState({ showModal: true, selectedShipment: d });
                                  } else {
                                    this.setState({ selectedShipment: d }, () => {
                                      this.updateShipmentStatus("closed");
                                    });
                                  }
                                }}
                              >
                                {d.display_name === "ZIFFIT" ? "Submit Batch to Ziffit" : "Mark as shipped/closed"}
                              </Button>
                            ) : (
                              d.closed_on
                            ),
                          Cell: ({ value }) => <span>{value}</span>,
                          style: { "text-align": "center", "align-self": "center", width: "170px" },
                        },
                        {
                          Header: () => (
                            <div
                              style={{
                                textAlign: "center",
                                fontWeight: "bold",
                              }}
                            >
                              Shipment Name
                            </div>
                          ),
                          id: "name",
                          accessor: (d) =>
                            d.ziffit_trade_id !== null && d.ziffit_trade_id !== ""
                              ? // <a
                                //   rel="noopener noreferrer"
                                //   target="_blank"
                                //   href={"https://www.ziffit.com/en-us/cart?scanOrigin=PALLET_IQ&cartId=" + d.cart_id}
                                // >
                                //   ZIFFIT
                                // </a>
                                d.name + ` (${d.ziffit_trade_id})`
                              : d.name,
                          Cell: ({ value }) => <span style={{ whiteSpace: "pre-wrap" }}>{value}</span>,
                          style: { "text-align": "center", "align-self": "center" },
                        },
                        {
                          Header: () => (
                            <div
                              style={{
                                textAlign: "center",
                                fontWeight: "bold",
                              }}
                            >
                              Destination
                            </div>
                          ),
                          id: "displayName",
                          // accessor: "display_name",
                          style: { "text-align": "center", "align-self": "center" },
                          accessor: (d) =>
                            d.display_name === "ZIFFIT"
                              ? // <a
                                //   rel="noopener noreferrer"
                                //   target="_blank"
                                //   href={"https://www.ziffit.com/en-us/cart?scanOrigin=PALLET_IQ&cartId=" + d.cart_id}
                                // >
                                //   ZIFFIT
                                // </a>
                                "ZIFFIT"
                              : d.display_name,
                          Cell: ({ value }) => <span>{value}</span>,
                        },
                        {
                          Header: () => (
                            <div
                              style={{
                                textAlign: "center",
                                fontWeight: "bold",
                              }}
                            >
                              Created On
                            </div>
                          ),
                          id: "createdOn",
                          style: { "text-align": "center", "align-self": "center" },
                          filterable: false,
                          accessor: (d) => this.getUSFormattedDate(d.created_on),
                        },
                      ],
                    },
                    {
                      Header: "Info",
                      columns: [
                        {
                          Header: () => (
                            <div
                              style={{
                                textAlign: "right",
                                fontWeight: "bold",
                              }}
                            >
                              Items
                            </div>
                          ),

                          id: "item_count",
                          // accessor: (d) => (d.item_count >= 0 ? d.item_count : "..."),
                          // accessor: (d) => d.item_count,
                          Cell: (cellInfo) => (
                            <div>
                              <div>
                                <span style={{ marginRight: "5px" }}>
                                  {this.numberWithCommas(cellInfo.original.item_count)}
                                </span>

                                <span style={{ cursor: "pointer" }}>
                                  {cellInfo.original.display_name === "ZIFFIT" ? (
                                    cellInfo.original.item_count === cellInfo.original.item_count_ziffit_confirmed ? (
                                      <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 0, hide: 100 }}
                                        style={{ backgroundColor: "grey" }}
                                        overlay={(props) => (
                                          <Tooltip id="button-tooltip" {...props}>
                                            <span>All confirmed at Ziffit</span>
                                          </Tooltip>
                                        )}
                                      >
                                        <i style={{ color: "#408558" }} className="fa fa-check-circle" />
                                      </OverlayTrigger>
                                    ) : (
                                      <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 0, hide: 100 }}
                                        style={{ backgroundColor: "grey" }}
                                        overlay={(props) => (
                                          <Tooltip id="button-tooltip" {...props}>
                                            <span>
                                              {cellInfo.original.item_count_ziffit_confirmed > 0
                                                ? `Only ${cellInfo.original.item_count_ziffit_confirmed} confirmed`
                                                : `None confirmed`}
                                            </span>
                                          </Tooltip>
                                        )}
                                      >
                                        <i style={{ color: "#ff9800" }} className="fa fa-exclamation-circle" />
                                      </OverlayTrigger>
                                    )
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </div>

                              {cellInfo.original.display_name === "ZIFFIT" &&
                                cellInfo.original.ziffitInfo !== undefined &&
                                "amount" in cellInfo.original.ziffitInfo && (
                                  <OverlayTrigger
                                    rootClose
                                    trigger="click"
                                    placement="right"
                                    overlay={
                                      <Popover id="popover-basic">
                                        <Popover.Title as="h3" style={{ textAlign: "center" }}>
                                          ZIFFIT STATUS
                                        </Popover.Title>
                                        <Popover.Content>
                                          <Table bordered>
                                            <tbody>
                                              <tr>
                                                <td>STATUS</td>
                                                <td style={{ fontWeight: "bold" }}>
                                                  {cellInfo.original.ziffitInfo.trade_status}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>AMOUNT</td>
                                                <td>${cellInfo.original.ziffitInfo.amount}</td>
                                              </tr>
                                              {cellInfo.original.ziffitInfo.trade_status !== "PAID" ? (
                                                <tr>
                                                  <td>ITEMS CONFIRMED</td>
                                                  <td>{cellInfo.original.ziffitInfo.total_items.confirmed}</td>
                                                </tr>
                                              ) : (
                                                <tr>
                                                  <td>ITEMS PROCESSED</td>
                                                  <td>
                                                    {Number(cellInfo.original.ziffitInfo.total_items.accepted) +
                                                      Number(cellInfo.original.ziffitInfo.total_items.rejected)}
                                                  </td>
                                                </tr>
                                              )}
                                              <tr>
                                                <td>ITEMS ACCEPTED</td>
                                                <td>{cellInfo.original.ziffitInfo.total_items.accepted}</td>
                                              </tr>
                                              <tr>
                                                <td>ITEMS REJECTED</td>
                                                <td>{cellInfo.original.ziffitInfo.total_items.rejected}</td>
                                              </tr>
                                            </tbody>
                                          </Table>
                                        </Popover.Content>
                                      </Popover>
                                    }
                                  >
                                    <Button variant="link" style={{ fontSize: "12px", fontWeight: "bold" }}>
                                      Ziffit Status
                                    </Button>
                                  </OverlayTrigger>
                                )}
                            </div>
                          ),
                          style: { "text-align": "center", "align-self": "center" },
                          filterable: false,
                        },
                        {
                          Header: () => (
                            <div
                              style={{
                                textAlign: "right",
                                fontWeight: "bold",
                              }}
                            >
                              Potential Profit
                            </div>
                          ),
                          id: "potentialProfit",
                          style: { "text-align": "right", "align-self": "center" },
                          filterable: false,
                          accessor: (d) =>
                            d.potential_profit !== "" && d.potential_profit !== null && d.potential_profit !== undefined
                              ? d.potential_profit > 0
                                ? `$${d.potential_profit.toFixed(2)}`
                                : "..."
                              : "$0",
                          Cell: ({ value }) => <span>{this.numberWithCommas(value)}</span>,
                        },
                        {
                          Header: () => (
                            <div
                              style={{
                                textAlign: "center",
                                fontWeight: "bold",
                              }}
                            >
                              Status
                            </div>
                          ),
                          width: 200,
                          id: "status",
                          style: { "text-align": "center", "align-self": "center" },
                          accessor: (d) =>
                            d.status === "PAID"
                              ? `PAID $${d.amount_paid.toFixed(2)} ON ${new Date(d.paid_on).toISOString().slice(0, 10)}`
                              : d.status === "CLOSED"
                              ? `CLOSED ON ${new Date(d.closed_on).toISOString().slice(0, 10)}`
                              : d.status === "ACTIVE"
                              ? "ACTIVE"
                              : `UA`,
                          Cell: ({ value }) =>
                            value === "PAID" ? (
                              <Badge variant="success">{value}</Badge>
                            ) : value.toString().includes("CLOSED") ? (
                              <Badge variant="warning">{value}</Badge>
                            ) : value.toString().includes("ACTIVE") ? (
                              <Badge variant="primary">{value}</Badge>
                            ) : (
                              <Badge variant="secondary">{value}</Badge>
                            ),
                          filterMethod: (filter, row) => {
                            console.log(row);
                            if (filter.value.indexOf("all") > -1) return true;
                            if (filter.value.indexOf("active") > -1) return row._original.status === "ACTIVE";
                            if (filter.value.indexOf("closed") > -1) return row._original.status === "CLOSED";
                            if (filter.value.indexOf("paid") > -1) return row._original.status === "PAID";
                          },
                          Filter: ({ filter, onChange }) => {
                            return (
                              <select
                                onChange={(event) => {
                                  let selectedOptions = [].slice.call(event.target.selectedOptions).map((o) => {
                                    return o.value;
                                  });

                                  this.onFilteredChangeCustom(selectedOptions, "status");
                                }}
                                style={{ width: "100%" }}
                                value={filter ? filter.value : ["all"]}
                                multiple={true}
                              >
                                <option value="all">All</option>
                                <option value="active">Active</option>
                                <option value="closed">Closed</option>
                                <option value="paid">Paid</option>
                              </select>
                            );
                          },
                        },
                        {
                          Header: () => (
                            <div
                              style={{
                                textAlign: "center",
                                fontWeight: "bold",
                              }}
                            >
                              Actions
                            </div>
                          ),
                          width: 200,
                          id: "actions",
                          style: { "white-space": "unset", "align-self": "center" },
                          filterable: false,
                          Cell: (cellInfo) => {
                            return (
                              <div style={{ textAlign: "center" }}>
                                {this.state.admin_data.access_level === 0 && (
                                  <Button
                                    size="sm"
                                    variant="success"
                                    target="_blank"
                                    title="Print Fedex Label"
                                    style={{ marginRight: "10px" }}
                                    onClick={() => {
                                      if (cellInfo.original.item_count === 0) alert("No ASIN in this shipments");
                                      else
                                        this.setState({
                                          isFedexDone: false,
                                          showFedexModal: true,
                                          selectedShipment: cellInfo.original,
                                        });
                                    }}
                                  >
                                    <i style={{ color: "white" }} className="fa fa-print " />
                                  </Button>
                                )}

                                {cellInfo.original.display_name === "ZIFFIT" &&
                                  "cart_id" in cellInfo.original &&
                                  (cellInfo.original.ziffit_trade_id === null || cellInfo.original.cart_id === "") && (
                                    <Button
                                      size="sm"
                                      variant="success"
                                      target="_blank"
                                      title="Close Batch"
                                      style={{ marginRight: "10px" }}
                                      href={
                                        "https://www.ziffit.com/en-us/cart?scanOrigin=PALLET_IQ&cartId=" +
                                        cellInfo.original.cart_id
                                      }
                                    >
                                      <i style={{ color: "white" }} className="fa fa-shopping-cart " />
                                    </Button>
                                  )}
                                {cellInfo.original.submit_data !== null ? (
                                  <Button
                                    style={{ marginRight: "10px" }}
                                    size="sm"
                                    title="Posted Data"
                                    variant="success"
                                    onClick={() => {
                                      this.setState({ showModalPostedData: true, selectedShipment: cellInfo.original });
                                    }}
                                  >
                                    <i style={{ color: "white" }} className="fa fa-envelope-open" />
                                  </Button>
                                ) : (cellInfo.original.display_name === "UBB" ||
                                    cellInfo.original.display_name === "RMS" ||
                                    // cellInfo.original.display_name === "SBYB" ||
                                    cellInfo.original.display_name === "ZIFFIT") &&
                                  (cellInfo.original.display_name !== "ZIFFIT" ||
                                    (cellInfo.original.ziffit_trade_id !== null &&
                                      cellInfo.original.ziffit_trade_id !== "")) &&
                                  cellInfo.original.item_count > 0 &&
                                  cellInfo.original.status === "ACTIVE" ? (
                                  <Button
                                    style={{ marginRight: "10px" }}
                                    size="sm"
                                    variant="dark"
                                    title="Email Vendor"
                                    onClick={() => {
                                      if (cellInfo.original.item_count === 0) alert("No ASIN in this shipments");
                                      else
                                        this.setState({
                                          [cellInfo.original.display_name === "UBB"
                                            ? `showModalUBB`
                                            : `showModal`]: true,
                                          selectedShipment: cellInfo.original,
                                        });
                                    }}
                                  >
                                    <i style={{ color: "white" }} className="fa fa-envelope" />
                                  </Button>
                                ) : (
                                  <span></span>
                                )}

                                {/* {(cellInfo.original.amount_paid === null || cellInfo.original.amount_paid === "") && (
                                  <Button
                                    size="sm"
                                    title="Update Status"
                                    variant="primary"
                                    style={{ marginRight: "10px" }}
                                    onClick={() => {
                                      this.setState({
                                        showModalUpdateShipmentStatus: true,
                                        selectedShipment: cellInfo.original,
                                      });
                                    }}
                                  >
                                    <i style={{ color: "white" }} className="fa fa-check-square" />
                                  </Button>
                                )} */}

                                {/* {cellInfo.original.display_name === "ZIFFIT" && "cart_id" in cellInfo.original && (
                                <span>
                                  <Button
                                    size="sm"
                                    variant="success"
                                    target="_blank"
                                    title="Close Batch"
                                    style={{ marginRight: "10px" }}
                                    href={
                                      "https://www.ziffit.com/en-us/cart?scanOrigin=PALLET_IQ&cartId=" +
                                      cellInfo.original.cart_id
                                    }
                                  >
                                    <i style={{ color: "white" }} className="fa fa-shopping-cart " />
                                  </Button>
                                </span>
                              )} */}

                                {cellInfo.original.item_count > 0 && (
                                  <Button
                                    title="Download CSV"
                                    size="sm"
                                    style={{ marginRight: "10px" }}
                                    variant="dark"
                                    onClick={() => {
                                      this.downloadListingCSV(cellInfo.original);
                                    }}
                                  >
                                    {this.state.downloadingCSV === true &&
                                    this.state.downloadCSVShipmentName === cellInfo.original.name ? (
                                      <Spinner
                                        animation="border"
                                        role="status"
                                        style={{ width: "15px", height: "15px" }}
                                      ></Spinner>
                                    ) : (
                                      <i style={{ color: "white" }} className="fa fa-cloud-download" />
                                    )}
                                  </Button>
                                )}

                                {cellInfo.original.status !== "PAID" && cellInfo.original.status !== "CLOSED" && (
                                  <Button
                                    title="Delete Shipment"
                                    size="sm"
                                    // style={{ marginRight: "20px", width: "180px", marginLeft: "20px" }}
                                    variant="danger"
                                    onClick={() => {
                                      this.handleDeleteShipment(cellInfo.original);
                                    }}
                                  >
                                    <i style={{ color: "white" }} className="fa fa-close" />
                                  </Button>
                                )}
                              </div>
                            );
                          },
                        },
                      ],
                    },
                  ]}
                  defaultPageSize={10}
                  className="-striped -highlight"
                />
              )}
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}

export default Shipments;
