import React, { Component } from "react";
import { Row, Col, Form, Button, Modal, Table, Spinner, ProgressBar } from "react-bootstrap";

class ModalDetails extends Component {
  state = {};

  handleOpen() {}

  render() {
    return (
      <React.Fragment>
        <Modal
          onEntered={this.handleOpen}
          show={this.props.showMPModal}
          onHide={this.props.handleClose}
          dialogClassName="editModal"
        >
          <Form onSubmit={this.props.onFormSubmitUpdateMPBatch}>
            <Modal.Header closeButton>
              <div style={{ flex: 1, flexDirection: "column" }}>
                <div>
                  <h2>{this.props.selectedBatch.name}</h2>
                </div>
                <div>
                  <Form.Control
                    type="text"
                    step="0.01"
                    name="mpBatchNotes"
                    max={100}
                    defaultValue={this.props.selectedBatch.pallet_notes}
                    style={{
                      width: "500px",
                      borderRadius: "0",
                      border: "none",
                      borderBottom: "1px solid #ccc",
                    }}
                    placeholder="Enter notes here"
                  />
                </div>
                <div style={{ marginTop: "20px" }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ flex: 1 }}>
                      Value: <b>${this.props.selectedBatch.pallet_value}</b>
                    </div>
                    <div style={{ flex: 1 }}>
                      Filled: <b>${parseFloat(this.props.selectedBatch.agg).toFixed(0)}</b>
                    </div>
                    <div style={{ flex: 2 }}>
                      <Form.Control as="select" name="teamId" defaultValue={this.props.selectedBatch.team_id}>
                        {this.props.teams.map((teamGroup) => {
                          return <option value={teamGroup.id}>{teamGroup.team_name}</option>;
                        })}
                      </Form.Control>
                    </div>
                    <div style={{ flex: 2 }}>
                      <ProgressBar
                        style={{ marginLeft: "15px" }}
                        variant={
                          this.props.selectedBatch.per_completed < 1
                            ? "danger"
                            : this.props.selectedBatch.per_completed >= 1 && this.props.selectedBatch.per_completed < 25
                            ? "warning"
                            : this.props.selectedBatch.per_completed >= 25 &&
                              this.props.selectedBatch.per_completed < 99
                            ? "info"
                            : "success"
                        }
                        now={this.props.selectedBatch.per_completed < 1 ? 10 : this.props.selectedBatch.per_completed}
                        label={`${this.props.selectedBatch.per_completed}%`}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Header>

            <Modal.Body>
              <div style={{ display: "flex", fontSize: "13px" }}>
                <div style={{ flex: 1 }}>
                  <Form.Check
                    type="switch"
                    name="isCompleted"
                    id="is-completed"
                    label="Completed?"
                    defaultChecked={this.props.selectedBatch.completed === 1 ? true : false}
                  />
                </div>

                <div style={{ flex: 1 }}>
                  <Form.Check
                    type="switch"
                    defaultChecked={this.props.selectedBatch.shipped === 1 ? true : false}
                    name="isShipped"
                    id="is-shipped"
                    label="Shipped?"
                  />
                </div>
                <div style={{ flex: 1 }}>
                  <Form.Check
                    type="switch"
                    name="isPaid"
                    id="is-paid"
                    label="Paid?"
                    defaultChecked={this.props.selectedBatch.paid === 1 ? true : false}
                  />
                </div>
                <div style={{ flex: 1 }}>
                  <Form.Check
                    type="switch"
                    name="isRequiresLiftGate"
                    id="requires-life-gate"
                    label="Lift Gate?"
                    defaultChecked={this.props.selectedBatch.requires_lift_gate === 1 ? true : false}
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  fontSize: "13px",
                  alignItems: "center",
                  marginTop: "8px",
                  paddingTop: "10px",
                  borderTop: "1px solid #ddd",
                }}
              >
                <div style={{ flex: 1, paddingRight: "15px" }}>
                  <Form.Check
                    type="switch"
                    name="isFreightPaid"
                    id="is-freight-paid"
                    label="Freight Paid?"
                    defaultChecked={this.props.selectedBatch.freight_paid === 1 ? true : false}
                  />
                </div>

                <div style={{ flex: 2, paddingRight: "15px" }}>
                  <Form.Group controlId="formFirstName">
                    <Form.Label>Freight Cost</Form.Label>
                    <Form.Control type="text" name="freightCost" defaultValue={this.props.selectedBatch.freight_cost} />
                  </Form.Group>
                </div>

                <div style={{ flex: 2 }}>
                  <Form.Group controlId="formFirstName">
                    <Form.Label>Freight Charge</Form.Label>
                    <Form.Control
                      type="text"
                      name="freightCharge"
                      defaultValue={this.props.selectedBatch.freight_charge}
                    />
                  </Form.Group>
                </div>
              </div>

              <div
                style={{
                  fontSize: "13px",
                  marginTop: "8px",
                  paddingTop: "10px",
                  borderTop: "1px solid #ddd",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ flex: 1, paddingRight: "15px" }}>
                    <Form.Group controlId="formBuyerName">
                      <Form.Label>Buyer's Name</Form.Label>
                      <Form.Control type="text" name="buyerName" defaultValue={this.props.selectedBatch.buyer_name} />
                    </Form.Group>
                  </div>

                  <div style={{ flex: 1, paddingRight: "15px" }}>
                    <Form.Group controlId="buyerEmail">
                      <Form.Label>Buyer's Email</Form.Label>
                      <Form.Control type="text" name="buyerEmail" defaultValue={this.props.selectedBatch.buyer_email} />
                    </Form.Group>
                  </div>

                  <div style={{ flex: 1 }}>
                    <Form.Group controlId="buyerPhone">
                      <Form.Label>Buyer's Phone</Form.Label>
                      <Form.Control
                        type="text"
                        name="buyerPhone"
                        defaultValue={this.props.selectedBatch.buyer_contact_number}
                      />
                    </Form.Group>
                  </div>
                </div>

                <div style={{ display: "flex" }}>
                  <div style={{ flex: 1, paddingRight: "15px" }}>
                    <Form.Group controlId="formBuyerName">
                      <Form.Label>Buyer's Address</Form.Label>
                      <Form.Control
                        name="buyerAddress"
                        as="textarea"
                        rows={5}
                        defaultValue={this.props.selectedBatch.buyer_address}
                      />
                    </Form.Group>
                  </div>

                  <div style={{ flex: 1, paddingRight: "15px" }}>
                    <Form.Group controlId="buyerCity">
                      <Form.Label>Buyer's City</Form.Label>
                      <Form.Control type="text" name="buyerCity" defaultValue={this.props.selectedBatch.buyer_city} />
                    </Form.Group>
                  </div>

                  <div style={{ flex: 1, paddingRight: "15px" }}>
                    <Form.Group controlId="buyerState">
                      <Form.Label>Buyer's State</Form.Label>
                      <Form.Control type="text" name="buyerState" defaultValue={this.props.selectedBatch.buyer_state} />
                    </Form.Group>
                  </div>

                  <div style={{ flex: 1 }}>
                    <Form.Group controlId="buyerZIP">
                      <Form.Label>Buyer's ZIP</Form.Label>
                      <Form.Control type="text" name="buyerZIP" defaultValue={this.props.selectedBatch.buyer_zip} />
                    </Form.Group>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    fontSize: "13px",
                    alignItems: "center",
                    marginTop: "8px",
                    paddingTop: "10px",
                    borderTop: "1px solid #ddd",
                  }}
                >
                  <div style={{ flex: 1, paddingRight: "15px" }}>
                    <Form.Group controlId="formFirstName">
                      <Form.Label>Buyer Commission</Form.Label>
                      <Form.Control
                        type="text"
                        name="buyerCommision"
                        defaultValue={this.props.selectedBatch.buyer_commission}
                      />
                    </Form.Group>
                  </div>
                  <div style={{ flex: 1, paddingRight: "15px" }}>
                    <Form.Group controlId="formFirstName">
                      <Form.Label>Seller Commission</Form.Label>
                      <Form.Control
                        type="text"
                        name="sellerCommision"
                        defaultValue={this.props.selectedBatch.seller_commission}
                      />
                    </Form.Group>
                  </div>
                  <div style={{ flex: 1, paddingRight: "15px" }}>
                    <Form.Group controlId="formFirstName">
                      <Form.Label>Total Profit</Form.Label>
                      <Form.Control
                        type="text"
                        name="totalProfit"
                        defaultValue={
                          this.props.selectedBatch.buyer_commission +
                          this.props.selectedBatch.seller_commission +
                          this.props.selectedBatch.freight_charge -
                          this.props.selectedBatch.freight_cost
                        }
                      />
                    </Form.Group>
                  </div>
                  <div style={{ flex: 1, paddingRight: "15px" }}>
                    <Form.Group controlId="formFirstName">
                      <Form.Label>Per Pound</Form.Label>
                      <Form.Control type="text" name="perPound" defaultValue={this.props.selectedBatch.per_pound} />
                    </Form.Group>
                  </div>
                </div>
              </div>
            </Modal.Body>

            <Modal.Footer>
              {this.props.isUpdatingBatch === true ? (
                <Spinner animation="border" role="status"></Spinner>
              ) : (
                <Button variant="primary" type="submit">
                  Update
                </Button>
              )}
            </Modal.Footer>
          </Form>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ModalDetails;
