import React, { Component } from "react";
import { apiBaseUrlLocal } from "../../includes/AppConfig";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CardForm from "../../components/Payments/CardForm";
import { Modal, Form, Button, Row, Col, Spinner } from "react-bootstrap";

const stripePromise = loadStripe(
  "pk_live_51IBKtGC7FxMC3n4vimf9s7YT4uZU8plqGBord2VUS7AtT2D75noDsFieCPocMz0HJKofyZWgwkHT44F7cZCZkiWq00hNTux3IB"
);

class ModalMigration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showReEnterCard: false,
      showScoutIQ: false,
      isScoutIQLogin: false,
      scoutIQEmail: "",
      scoutIQPassword: "",
      scoutIQLoginError: "",
      isSuccess: false,
    };
    this.scoutIQLogin = this.scoutIQLogin.bind(this);
  }

  scoutIQLogin = async () => {
    this.setState({ scoutIQLoginError: "", isScoutIQLogin: true });
    const response = await fetch(apiBaseUrlLocal + "admin/payments/get-scoutiq", {
      method: "POST",
      body: new URLSearchParams({
        user_id: this.props.adminData.admin_id,
        app_token: this.props.adminData.token,
        email: this.state.scoutIQEmail,
        password: this.state.scoutIQPassword,
      }),
    });
    this.setState({ isScoutIQLogin: false, isSuccess: true }, () => {
      setTimeout(function () {
        this.props.handleClose();
      }, 2000);
    });
    if (response.status === 200) {
      const data = await response.json();
    } else this.setState({ scoutIQLoginError: "Error: INVALID LOGIN. TRY AGAIN!" });
  };

  render() {
    return (
      <React.Fragment>
        <Modal
          style={{ zIndex: 999999 }}
          backdrop="static"
          keyboard={false}
          onEntered={this.handleOpen}
          show={this.props.showModalMigration}
          onHide={this.props.handleClose}
          dialogClassName="editModal"
        >
          <Form onSubmit={this.props.onFormSubmitAddMPBatch}>
            <Modal.Header style={{ textAlign: "center" }}>
              <h4>Important Billing Update</h4>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p>We're in process of merging billing for PalletIQ with ScoutIQ.</p>
                <p>Do you have an active ScoutIQ membership?</p>
                <p>
                  <Button
                    variant="success"
                    onClick={() => this.setState({ showScoutIQ: true, showReEnterCard: false })}
                  >
                    YES
                  </Button>
                  <Button
                    variant="secondary"
                    style={{ marginLeft: "20px" }}
                    onClick={() => this.setState({ showScoutIQ: false, showReEnterCard: true })}
                  >
                    NO
                  </Button>
                </p>
              </div>

              {this.state.showReEnterCard && (
                <div style={{ marginTop: "30px", borderTop: "1px solid #ddd", paddingTop: "10px" }}>
                  <div>Please re-enter your card details to proceed</div>

                  <div style={{ marginTop: "15px" }}>
                    <Elements stripe={stripePromise}>
                      <CardForm
                        updateCardErrorMessage={this.updateCardErrorMessage}
                        isAddingCard={this.state.isAddingCard}
                        admin_data={this.state.admin_data}
                        updateAdminData={this.updateAdminData}
                      />
                    </Elements>
                  </div>
                </div>
              )}

              {this.state.showScoutIQ && (
                <div style={{ marginTop: "30px", borderTop: "1px solid #ddd", paddingTop: "10px" }}>
                  <div>Please login with your ScoutIQ team admin account below</div>
                  <Row style={{ marginTop: "10px" }}>
                    <Col md={6}>
                      <Form.Group controlId="formMWSSeller">
                        <Form.Label>ScoutIQ Email</Form.Label>
                        <Form.Control
                          value={this.state.scoutIQEmail}
                          onChange={(e) => this.setState({ scoutIQEmail: e.target.value })}
                          type="email"
                          name="mwsSellerId"
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId="formMWSSeller">
                        <Form.Label>ScoutIQ Password</Form.Label>
                        <Form.Control
                          type="password"
                          name="mwsSellerId"
                          value={this.state.scoutIQPassword}
                          onChange={(e) => this.setState({ scoutIQPassword: e.target.value })}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Button variant="primary" onClick={() => this.scoutIQLogin()} style={{ width: "300px" }}>
                      {this.state.isScoutIQLogin === true ? (
                        <Spinner animation="border" role="status" style={{ width: "15px", height: "15px" }}>
                          <span className="sr-only">Loading...</span>
                        </Spinner>
                      ) : (
                        " Login & Merge Billing"
                      )}
                    </Button>

                    {this.state.scoutIQLoginError && (
                      <div style={{ color: "red", marginLeft: "10px" }}>{this.state.scoutIQLoginError}</div>
                    )}

                    {this.state.isSuccess && (
                      <div style={{ color: "green", marginLeft: "10px" }}>Migration Complete. Thanks!</div>
                    )}
                  </div>
                </div>
              )}
            </Modal.Body>
          </Form>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ModalMigration;
