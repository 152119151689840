/** Handles operates pertaining to each individual boxes.
 *  From opening `Box Contents` modal to `printing bar codes`.
 */

import React, { Component } from "react";
import iconBox from "../../assets/images/icon_box_white.png";
import iconBoxActive from "../../assets/images/icon_box_active.png";
import { Badge, Modal, Button, Table } from "react-bootstrap";
import bwipjs from "bwip-js";
import ReactToPrint from "react-to-print";
import PrintBoxBarcode from "./PrintBoxBarcode";

class ShipmentBox extends Component {
  state = {
    showBoxContent: false,
    barCodeB64: "",
    printMeta: "",
  };

  constructor() {
    super();
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }
  handleShow() {
    this.setState({ showBoxContent: true });
  }
  handleClose() {
    this.setState({ showBoxContent: false });
  }

  totalWeightOfBox() {
    let totalWeight = 0;
    this.props.box.items.forEach((itemInBox) => {
      totalWeight += Number(itemInBox.Weight) * itemInBox.quantityInBox;
    });
    return Math.round(totalWeight * 100) / 100;
  }

  totalItemsinBox() {
    let totalItems = 0;
    this.props.box.items.forEach((itemInBox) => {
      totalItems += itemInBox.quantityInBox;
    });
    return totalItems;
  }

  deleteItem = (item) => {
    this.props.deleteItemFromBox(item);
  };

  /**
   * Function gets called just before `content is gathered/rendered` for the 2D barcode printing.
   */
  handleBeforeGetContent = () => {
    return new Promise((resolve, reject) => {
      new Promise((resolve, reject) => {
        if (this.props.box.items.length > 0) {
          const shipmentId = this.props.box.items[0].ShipmentId;
          const destinationCenter = this.props.box.items[0].DestinationFulfillmentCenterId;
          const boxIdCount = Number(this.props.box.id_count) + 1;
          const totalItemsInBox = this.totalItemsinBox();
          const totalWeightOfBox = this.totalWeightOfBox();

          const printMeta = {
            shipmentId: shipmentId,
            destinationCenter: destinationCenter,
            boxIdCount: boxIdCount,
            totalItemsInBox: totalItemsInBox,
            totalWeightOfBox: totalWeightOfBox,
          };

          this.setState({ printMeta: printMeta });

          let barCodeString = "AMZN,PO:" + shipmentId;

          this.props.box.items.forEach((item) => {
            barCodeString += ",ASIN:" + item.Asin + ",QTY:" + item.quantityInBox;
          });

          new Promise((resolve, reject) => {
            let canvas = document.createElement("canvas");
            bwipjs(
              canvas,
              {
                bcid: "pdf417",
                text: barCodeString,
                scale: 5, // 3x scaling factor
                height: 10, // Bar height, in millimeters
                includetext: true,
                textxalign: "center",
              },
              function (err, cvs) {
                if (err) {
                  return err;
                } else {
                  //document.getElementById("myimg").src = canvas.toDataURL("image/png");
                  const dataURL = canvas.toDataURL("image/png");
                  resolve(dataURL);
                }
              }
            );
          }).then((dataURL) => {
            resolve(dataURL);
          });
        }
      }).then((response) => {
        const updatedB64 = response;
        this.setState({ barCodeB64: updatedB64 }, () => resolve());
      });
    });
  };

  render() {
    return (
      <div className="boxContainer">
        <Modal show={this.state.showBoxContent} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Box Contents</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table bordered>
              <thead>
                <tr>
                  <th>ASIN</th>
                  <th>Weight</th>
                  <th>Total Qty.</th>
                  <th>In Box</th>
                  <th>Options</th>
                </tr>
              </thead>
              <tbody>
                {this.props.box.items.map((item, index) => {
                  return (
                    <tr key={this.props.box.id_count + "_" + item.Asin}>
                      <td>{item.Asin}</td>
                      <td>{item.Weight} lb</td>
                      <td>{item.quantity}</td>
                      <td>{item.quantityInBox}</td>
                      <td>
                        <span onClick={this.deleteItem.bind(this, item)} className="spanClickable">
                          Delete
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer>
            <ReactToPrint
              trigger={() => (
                <Button size="sm" variant="primary">
                  Print Barcode
                </Button>
              )}
              pageStyle="@media print{@page {size: 8.5in 5.5in;size: landscape;}}"
              onBeforeGetContent={() => this.handleBeforeGetContent()}
              content={() => this.componentRef}
            />
            <div style={{ display: "none" }}>
              <PrintBoxBarcode
                b64={this.state.barCodeB64}
                printMeta={this.state.printMeta}
                ref={(el) => (this.componentRef = el)}
              />
            </div>
            <Button size="sm" variant="secondary" onClick={this.handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <div>
          <span className="boxIdCountDisplay">{this.props.box.id_count + 1}</span>
          <img
            alt="box"
            className="boxIcon"
            onClick={this.handleShow}
            src={this.props.box.id_count === this.props.activeBox ? iconBoxActive : iconBox}
          />
          <Badge variant={this.props.box.id_count === this.props.activeBox ? "success" : "secondary"}>
            {this.totalItemsinBox()}
          </Badge>
        </div>
        <div className="boxWeight">
          <small>
            <strong>{this.totalWeightOfBox()} lb</strong>
          </small>
        </div>
      </div>
    );
  }
}

export default ShipmentBox;
