import Cookies from "universal-cookie";
import { apiBaseUrlLocal } from "./includes/AppConfig";

class Auth {
  constructor() {
    this.cookies = new Cookies();
  }

  login(response, cb) {
    this.cookies.set("admin_data", response.data, { path: "/" });
    cb();
  }

  logout(cb) {
    this.cookies.remove("admin_data");
    cb();
  }

  getAdminData() {
    return this.cookies.get("admin_data");
  }
  setAdminData(userData) {
    this.cookies.set("admin_data", userData, { path: "/" });
  }

  async isAuthenticated() {
    const adminData = this.cookies.get("admin_data");

    if (undefined !== adminData && "token" in adminData) {
      try {
        console.log(`Validating Token`);
        const response = await fetch(`${apiBaseUrlLocal}admin/validate-token?id=${adminData.token}`);
        const responseJson = await response.json();
        if (response.status === 200) {
          this.setAdminData(responseJson.data);
          return true;
        } else {
          console.log(`Didn't match`);
          return false;
        }
      } catch (error) {
        return false;
      }
    } else return false;
  }
}

// Exporting singleton class
export default new Auth();
