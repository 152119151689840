import React, { Component } from "react";
import { Row, Col, Container, Form, ProgressBar, Button, Spinner } from "react-bootstrap";
import { apiBaseUrlLocal } from "../includes/AppConfig";
import Cookies from "universal-cookie";
import TopMenu from "../components/TopMenu";
import Select from "react-select";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

const cookies = new Cookies();

class MpInbound extends Component {
  state = {
    currentTradeId: "",
    inputTradeId: "",
    inputTradeAsin: "",
    isFetchingTradeItems: false,
    mpItemsAll: [],
    isCancelling: false,
    isUpdatingPrice: false,
    onlyCheckedIn: false,
    selectedAsin: {},
    newMPValue: 0,
    errorFetchingTradeItems: "",
    errorScanningItem: "",
    rejectReason: "",
    isUpdatingAsin: false,
    isUnexpectedItem: false,
    newAsinWinnerText: "",
    admin_data: cookies.get("admin_data"),
    tradeList: [],
  };

  constructor() {
    super();
    this.onFormSubmitNewTradeID = this.onFormSubmitNewTradeID.bind(this);
    this.onFormSubmitNewAsinScan = this.onFormSubmitNewAsinScan.bind(this);
    this.setSelectedAsin = this.setSelectedAsin.bind(this);
    this.updateMPValue = this.updateMPValue.bind(this);
  }

  async initiate() {
    const response = await fetch(`${apiBaseUrlLocal}admin/marketplace/trades`, {
      method: "POST",
      body: new URLSearchParams({
        user_id: this.state.admin_data.admin_id,
        app_token: this.state.admin_data.token,
      }),
    });
    if (response.status === 200) {
      const data = await response.json();
      if ("status" in data && data.status === true) {
        console.log(data.data);
        let tradeList = data.data.map((trade) => {
          return {
            label: trade,
            value: trade,
          };
        });
        this.setState({ tradeList });
      }
    }
  }

  componentDidMount() {
    this.initiate();
    //this.setState({ inputTradeId: "MKPL-3-3" }, () => this.onFormSubmitNewTradeID());
  }

  downloadAsinsCSV(isFBAPrices) {
    let csvContent = "data:text/csv;charset=utf-8,";

    // csvContent += "ASIN, SKU, QUANTITY, TITLE, CONDITION, CONDITION_NOTES, BUY_COST, SELF_WEAR, SOURCE, LISTING_PROFILE";

    const csvContentFBA = isFBAPrices === true ? ",FBA PRICE" : "";

    csvContent += `ASIN,TITLE,ESCORE,SALESRANK,DECISION,NOTES,MP PRICE,${csvContentFBA}MF PRICE,SCANNED ON` + "\r\n";

    this.state.mpItemsAll.forEach(function (rowArray) {
      const scannedDate = new Date(rowArray.scanned_on);
      const yearScanned = scannedDate.getFullYear();
      let monthScanned = (1 + scannedDate.getMonth()).toString();
      monthScanned = monthScanned.length > 1 ? monthScanned : "0" + monthScanned;

      let dayScanned = scannedDate.getDate().toString();
      dayScanned = dayScanned.length > 1 ? dayScanned : "0" + dayScanned;

      //return month + "-" + day + "-" + year;

      const rowContentFBA = isFBAPrices === true ? "," + rowArray.fba_estimated_profit_initial : "";

      let row =
        '="' +
        rowArray.asin +
        '","' +
        rowArray.title +
        '",' +
        rowArray.escore +
        "," +
        rowArray.sales_rank +
        "," +
        (rowArray.checked_on === null ? "NOT SCANNED" : rowArray.reject_notes === null ? "ACCEPTED" : "REJECTED") +
        "," +
        (rowArray.reject_notes === null ? "" : rowArray.reject_notes) +
        "," +
        rowArray.mp_estimated_profit +
        rowContentFBA +
        "," +
        rowArray.mf_estimated_profit +
        "," +
        (monthScanned + "-" + dayScanned + "-" + yearScanned);

      csvContent += row + "\r\n";
    });

    var encodedUri = encodeURI(csvContent);
    const fixedEncodedURI = encodedUri.replaceAll("#", "%23");
    var link = document.createElement("a");
    link.setAttribute("href", fixedEncodedURI);
    link.setAttribute("download", `${this.state.currentTradeId}.csv`);
    document.body.appendChild(link); // Required for FF

    link.click(); // This will download the data file named "my_data.csv".
  }

  async setSelectedAsin(asinScanIndex) {
    let mpItemsAll = [...this.state.mpItemsAll];
    mpItemsAll[asinScanIndex].checked_on = new Date();

    mpItemsAll = mpItemsAll.sort(function (a, b) {
      return (
        (a.checked_on === null) - (b.checked_on === null) ||
        -(a.checked_on > b.checked_on) ||
        +(a.checked_on < b.checked_on)
      );
    });

    this.setState({ mpItemsAll });

    fetch(`${apiBaseUrlLocal}admin/marketplace/inbound-scan`, {
      method: "POST",
      body: new URLSearchParams({
        user_id: this.state.admin_data.admin_id,
        app_token: this.state.admin_data.token,
        trade_id: this.state.currentTradeId,
        scan_id: this.state.mpItemsAll[asinScanIndex].id,
        decision: "accept",
        notes: "",
      }),
    });

    this.setState({ selectedAsin: mpItemsAll[asinScanIndex] });
  }

  async onFormSubmitNewAsinScan(e) {
    e.preventDefault();
    const asinSearched = e.target.elements.mpAsin.value;

    this.setState({ isUnexpectedItem: false, errorScanningItem: "", inputTradeAsin: "", newAsinWinnerText: "" });

    const asinScanIndex = this.state.mpItemsAll.findIndex(
      (item) => item.asin === asinSearched && item.checked_on === null
    );
    if (asinScanIndex >= 0) {
      console.log(`Unscanned item exists. will check that in.`, this.state.mpItemsAll[asinScanIndex]);
      // const firstUnScanned = this.state.mpItemsAll.findIndex(
      //   (item) => item.asin === asinSearched && item.checked_on === null
      // );
      // const scanIndex = firstUnScanned >= 0 ? firstUnScanned : asinScanIndex;
      this.setSelectedAsin(asinScanIndex);
    } else {
      console.log(`No such unscanned asin exists. Will re-reevaluate based on users triggers`, asinSearched);
      this.setState({ isUnexpectedItem: true, processingUnKnownASIN: true, selectedAsin: {} });
      // Send request for new ASIN scan
      const response = await fetch(`${apiBaseUrlLocal}admin/marketplace/new-scan`, {
        method: "POST",
        body: new URLSearchParams({
          user_id: this.state.admin_data.admin_id,
          app_token: this.state.admin_data.token,
          trade_id: this.state.currentTradeId,
          asin: asinSearched,
        }),
      });
      if (response.status === 200) {
        const data = await response.json();
        if ("status" in data && data.status === true) {
          const newItemRes = data.newItem;
          if (newItemRes.winner.winnerModule !== "MARKETPLACE")
            this.setState({ newAsinWinnerText: "NOT ACCEPTING ANYMORE" });
          else {
            let mpItemsAll = [];
            for (let i = 0; i < data.data.length; i++) {
              let mpData = data.data[i];
              mpData.checked_on =
                mpData.checked_on === null || mpData.checked_on === "" ? null : new Date(mpData.checked_on);
              mpItemsAll.push(mpData);
            }
            this.setState({ mpItemsAll }, () => {
              this.setState({ errorScanningItem: "", inputTradeAsin: "", newAsinWinnerText: "" });

              const asinScanIndex = this.state.mpItemsAll.findIndex(
                (item) => item.asin === asinSearched && item.checked_on === null
              );
              if (asinScanIndex >= 0) this.setSelectedAsin(asinScanIndex);
            });
          }
        }
      }

      //console.log(response.newItem);
      this.setState({ processingUnKnownASIN: false });

      // this.setState({ errorScanningItem: "ASIN not found" });
    }
  }

  async updateItemScan(scanId, tradeId, decision, notes) {
    let mpItemsAll = [...this.state.mpItemsAll];
    const index = mpItemsAll.findIndex((mpItem) => mpItem.id === scanId);

    if (index >= 0) {
      mpItemsAll[index].reject_notes = decision === "accept" ? null : notes;

      this.setState({ mpItemsAll, selectedAsin: {}, isUpdatingAsin: false, isCancelling: false });
      this.tradeAsinInput !== undefined && this.tradeAsinInput.focus();
    }

    // this.setState({ isUpdatingAsin: true });
    const response = await fetch(`${apiBaseUrlLocal}admin/marketplace/inbound-scan`, {
      method: "POST",
      body: new URLSearchParams({
        user_id: this.state.admin_data.admin_id,
        app_token: this.state.admin_data.token,
        trade_id: tradeId,
        scan_id: scanId,
        decision,
        notes,
      }),
    });
    if (response.status === 200) {
      const data = await response.json();
      // if ("status" in data && data.status === true) {
      //   this.setState({ mpItemsAll: data.data, selectedAsin: {}, isUpdatingAsin: false, isCancelling: false });
      //   this.tradeAsinInput !== undefined && this.tradeAsinInput.focus();
      // }
    }
  }

  async updateMPValue() {
    const scanId = this.state.selectedAsin.id;
    const price = Number(this.state.newMPValue);

    let mpItemsAll = [...this.state.mpItemsAll];
    const index = mpItemsAll.findIndex((mpItem) => mpItem.id == scanId);

    if (index >= 0) {
      mpItemsAll[index].mp_estimated_profit = price;
      this.setState({ mpItemsAll, selectedAsin: mpItemsAll[index] });

      fetch(`${apiBaseUrlLocal}admin/marketplace/update-value`, {
        method: "POST",
        body: new URLSearchParams({
          user_id: this.state.admin_data.admin_id,
          app_token: this.state.admin_data.token,
          scan_id: scanId,
          new_value: price,
        }),
      });
    }
  }

  async onFormSubmitNewTradeID(tradeId) {
    // e.preventDefault();
    // const tradeId = this.state.inputTradeId;

    this.setState({ isFetchingTradeItems: true, errorFetchingTradeItems: "" });

    const response = await fetch(`${apiBaseUrlLocal}admin/marketplace/items`, {
      method: "POST",
      body: new URLSearchParams({
        user_id: this.state.admin_data.admin_id,
        app_token: this.state.admin_data.token,
        trade_id: tradeId,
      }),
    });
    if (response.status === 200) {
      const data = await response.json();
      if ("status" in data && data.status === true) {
        this.setState({ isFetchingTradeItems: false });
        if (data.data.length > 0) {
          let mpItemsAll = [];
          for (let i = 0; i < data.data.length; i++) {
            let mpData = data.data[i];
            mpData.checked_on =
              mpData.checked_on === null || mpData.checked_on === "" ? null : new Date(mpData.checked_on);
            mpItemsAll.push(mpData);
          }

          this.setState({
            mpItemsAll,
            currentTradeId: tradeId,
          });
        } else {
          this.setState({ errorFetchingTradeItems: "Invalid or empty trade", inputTradeId: "" });
          this.tradeIdInput !== undefined && this.tradeIdInput.focus();
        }
      }
    }
  }

  render() {
    const totalItems = this.state.mpItemsAll.length;
    const totalScanned = Number(
      this.state.mpItemsAll.reduce((count, item) => count + (item.checked_on !== null ? 1 : 0), 0)
    );

    const totalShipmentAmt = this.state.mpItemsAll.reduce((sum, item) => sum + item.mp_estimated_profit, 0).toFixed(2);
    const totalScannedAmt = this.state.mpItemsAll
      .reduce((sum, item) => sum + (item.checked_on !== null ? item.mp_estimated_profit : 0), 0)
      .toFixed(2);
    const progressBarPercentage = ((totalScanned * 100) / totalItems).toFixed(2);

    const totalPayAmt = this.state.mpItemsAll
      .reduce(
        (sum, item) => sum + (item.checked_on !== null && item.reject_notes === null ? item.mp_estimated_profit : 0),
        0
      )
      .toFixed(2);

    return (
      <React.Fragment>
        <TopMenu
          handlenavigationroutes={(routeName) => {
            // abortRequests();
            this.props.history.push(routeName);
          }}
          adminData={this.state.admin_data}
          history={this.props.history}
          activeKey="/mp-inbound"
        />

        <Container fluid={true} style={{ marginTop: "15px" }}>
          <Row>
            <Col md={12}>
              <div>
                <Select
                  disabled={this.state.isFetchingTradeItems}
                  options={this.state.tradeList}
                  onChange={(opt) => this.onFormSubmitNewTradeID(opt.value)}
                />

                {/* <Form onSubmit={this.onFormSubmitNewTradeID}>
                    <Form.Control
                      ref={(input) => {
                        this.tradeIdInput = input;
                      }}
                      onChange={(e) => {
                        this.setState({ inputTradeId: e.target.value });
                      }}
                      value={this.state.inputTradeId}
                      required
                      type="text"
                      disabled={this.state.isFetchingTradeItems}
                      name="mpTradeId"
                      autoFocus
                      autoComplete="off"
                      className="input-text-trigger-name"
                      style={{ borderBottom: "1px solid #ddd" }}
                      placeholder="Enter Marketplace Trade ID Here"
                    />
                  </Form> */}
                {this.state.errorFetchingTradeItems !== "" && (
                  <div style={{ fontSize: "14px", color: "#E54532", marginTop: 10, marginBottom: 10, padding: "10px" }}>
                    {this.state.errorFetchingTradeItems}
                  </div>
                )}
              </div>
              {this.state.currentTradeId !== "" && (
                <Row style={{ marginTop: "50px" }}>
                  <Col md={7}>
                    <h4>
                      Scanning into {this.state.currentTradeId}{" "}
                      <Button
                        size="sm"
                        style={{ marginLeft: "10px" }}
                        onClick={() => {
                          confirmAlert({
                            title: "Select CSV version",
                            message: "Choose the appropriate CSV version to be downloaded",
                            buttons: [
                              {
                                label: "With FBA prices",
                                onClick: () => this.downloadAsinsCSV(true),
                              },
                              {
                                label: "Without FBA prices",
                                onClick: () => this.downloadAsinsCSV(false),
                              },
                            ],
                          });
                        }}
                      >
                        <i className="fa fa-cloud-download" />
                      </Button>
                      <span style={{ marginLeft: "10px", paddingLeft: "10px", borderLeft: "1px solid #000" }}>
                        Pay: ${totalPayAmt}
                      </span>
                    </h4>

                    <div style={{ marginTop: 20, marginBottom: 20, width: "700px", maxWidth: "100%" }}>
                      <ProgressBar
                        now={progressBarPercentage}
                        variant={
                          progressBarPercentage < 50
                            ? "primary"
                            : progressBarPercentage >= 50 && progressBarPercentage < 100
                            ? "warning"
                            : "danger"
                        }
                      />

                      <div style={{ marginTop: 5, fontSize: "14px" }}>
                        {totalScanned} (<b>${totalScannedAmt}</b>) out of {totalItems} (claimed worth{" "}
                        <b>${totalShipmentAmt}</b>) scanned.
                      </div>
                    </div>

                    <div style={{ marginTop: "50px" }}>
                      <Form onSubmit={this.onFormSubmitNewAsinScan}>
                        <Form.Control
                          ref={(input) => {
                            this.tradeAsinInput = input;
                          }}
                          onChange={(e) => {
                            this.setState({ inputTradeAsin: e.target.value });
                          }}
                          value={this.state.inputTradeAsin}
                          required
                          type="text"
                          name="mpAsin"
                          autoFocus
                          disabled={this.state.isUpdatingAsin}
                          autoComplete="off"
                          className="input-text-trigger-name"
                          style={{ borderBottom: "1px solid #ddd", padding: 0, width: "702px" }}
                          placeholder="Enter ASIN"
                        />
                      </Form>

                      <div style={{ fontSize: "14px", color: "#E54532", marginTop: 10, marginBottom: 10 }}>
                        {this.state.processingUnKnownASIN && <Spinner animation="border" role="status"></Spinner>}
                        {this.state.newAsinWinnerText !== "" && <span>{this.state.newAsinWinnerText}</span>}
                      </div>
                    </div>

                    {"id" in this.state.selectedAsin && (
                      <div style={{ marginTop: "20px" }}>
                        <div style={{ fontWeight: "bold" }}>{this.state.selectedAsin.title}</div>
                        <div>
                          {this.state.isUnexpectedItem ? (
                            <span style={{ color: "#9A1D27", fontWeight: "bold" }}>UNEXPECTED ITEM SCANNED</span>
                          ) : (
                            <span>
                              Scanned on:{" "}
                              {new Date(this.state.selectedAsin.scanned_on).toLocaleString("en-US", {
                                hour12: true,
                                month: "long",
                                day: "2-digit",
                                year: "numeric",
                                hour: "2-digit",
                                minute: "2-digit",
                                weekday: "short",
                                timeZone: "Etc/GMT+0",
                              })}
                            </span>
                          )}{" "}
                          | Quoted Value: <b>${this.state.selectedAsin.mp_estimated_profit}</b>
                        </div>
                        <div>
                          FBA: <b>${this.state.selectedAsin.fba_estimated_profit_initial}</b> | MF:{" "}
                          <b>${this.state.selectedAsin.mf_estimated_profit}</b> | ESCORE:{" "}
                          <b>{this.state.selectedAsin.escore}</b> | RANK: <b>{this.state.selectedAsin.sales_rank}</b>
                        </div>

                        <div style={{ display: "flex" }}>
                          {this.state.isCancelling === false && (
                            <div style={{ marginTop: "20px", marginRight: "20px" }}>
                              {this.state.isUpdatingPrice ? (
                                <div style={{ width: "700px" }}>
                                  <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                    <Form.Control
                                      required
                                      type="number"
                                      step="0.01"
                                      value={this.state.newMPValue}
                                      name="newMPValue"
                                      onChange={(e) => this.setState({ newMPValue: e.target.value })}
                                      style={{
                                        width: "250px",
                                        borderRadius: "0",
                                        border: "none",
                                        padding: 0,
                                        borderBottom: "1px solid #ccc",
                                      }}
                                      placeholder="Enter new Value here"
                                    />
                                  </Form.Group>

                                  <Button
                                    variant="secondary"
                                    style={{ borderRadius: 0 }}
                                    disabled={this.state.isUpdatingAsin}
                                    onClick={() => this.setState({ isUpdatingPrice: false })}
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    variant="primary"
                                    disabled={this.state.isUpdatingAsin}
                                    style={{ marginLeft: 10, borderRadius: 0 }}
                                    onClick={() => this.updateMPValue()}
                                  >
                                    Save
                                  </Button>
                                </div>
                              ) : (
                                <div>
                                  <Button
                                    disabled={this.state.isUpdatingPrice}
                                    variant="primary"
                                    style={{ borderRadius: 0 }}
                                    onClick={() => {
                                      this.setState({ isUpdatingPrice: true });
                                    }}
                                  >
                                    Update Price
                                  </Button>
                                </div>
                              )}
                            </div>
                          )}

                          {this.state.isUpdatingPrice === false && (
                            <div style={{ marginTop: "20px" }}>
                              {this.state.isCancelling ? (
                                <div style={{ width: "700px" }}>
                                  <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                    <Form.Label>Specify a reason</Form.Label>
                                    <Form.Control
                                      as="textarea"
                                      rows={3}
                                      disabled={this.state.isUpdatingAsin}
                                      value={this.state.rejectReason}
                                      onChange={(e) => this.setState({ rejectReason: e.target.value })}
                                    />
                                  </Form.Group>

                                  <Button
                                    variant="secondary"
                                    style={{ borderRadius: 0 }}
                                    disabled={this.state.isUpdatingAsin}
                                    onClick={() => this.setState({ isCancelling: false })}
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    variant="primary"
                                    disabled={this.state.isUpdatingAsin}
                                    style={{ marginLeft: 10, borderRadius: 0 }}
                                    onClick={() =>
                                      this.updateItemScan(
                                        this.state.selectedAsin.id,
                                        this.state.currentTradeId,
                                        "reject",
                                        this.state.rejectReason
                                      )
                                    }
                                  >
                                    Save
                                  </Button>
                                </div>
                              ) : (
                                <div>
                                  <Button
                                    disabled={this.state.isUpdatingAsin}
                                    variant="danger"
                                    style={{ borderRadius: 0 }}
                                    onClick={() => {
                                      this.setState({ isCancelling: true });
                                    }}
                                  >
                                    Reject
                                  </Button>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </Col>

                  <Col md={5}>
                    <div style={{ textAlign: "right" }}>
                      <Form.Check
                        onChange={(e) => this.setState({ onlyCheckedIn: e.target.checked })}
                        type="switch"
                        name="isCheckedIn"
                        id="is-checked-in"
                        label="Toggle pending to be Checked in"
                        value={this.state.onlyCheckedIn}
                      />
                    </div>
                    <div
                      style={{
                        marginTop: "15px",
                        background: "#EAECEF",
                        border: "1px solid #ddd",
                        height: "90vh",
                        width: "100%",
                        overflowY: "auto",
                        padding: "10px",
                        wordWrap: "break-word",
                      }}
                    >
                      {(this.state.onlyCheckedIn === true
                        ? this.state.mpItemsAll.filter((mi) => mi.checked_on === null)
                        : this.state.mpItemsAll
                      ).map((mpItem) => {
                        return (
                          <div
                            className={
                              mpItem.checked_on !== null
                                ? mpItem.reject_notes !== null
                                  ? "mp-inbound-cart-item-rejected"
                                  : "mp-inbound-cart-item-accepted"
                                : "mp-inbound-cart-item"
                            }
                          >
                            <div style={{ display: "flex", alignItems: "center" }}>
                              <div style={{ flex: 5 }}>
                                <div className="title">{mpItem.title}</div>
                                <div className="asin">
                                  {mpItem.asin + " "}
                                  <Button
                                    variant={"link"}
                                    size="sm"
                                    onClick={() => this.setState({ selectedAsin: mpItem })}
                                    style={{
                                      color: "#000",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    EDIT SCAN
                                  </Button>
                                  {/* {mpItem.checked_on !== null && (
                                    <Button
                                      variant={"link"}
                                      size="sm"
                                      onClick={() =>
                                        this.updateItemScan(
                                          mpItem.id,
                                          this.state.currentTradeId,
                                          mpItem.reject_notes !== null ? "accept" : "reject",
                                          mpItem.reject_notes !== null ? "" : "switched"
                                        )
                                      }
                                      style={{
                                        color: mpItem.reject_notes !== null ? "#54ff8e" : "#8c0c04",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Switch to {mpItem.reject_notes !== null ? "Accept" : "Reject"}
                                    </Button>
                                  )} */}
                                </div>
                              </div>
                              <div style={{ flex: 1, height: "100%", margin: "5px" }}>
                                <div
                                  style={{
                                    textAlign: "center",
                                    alignItems: "center",
                                    padding: "5px",
                                    margin: "5px",
                                    background: "#111313",
                                    color: "#fff",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {mpItem.mp_estimated_profit === null || mpItem.mp_estimated_profit === ""
                                    ? "-"
                                    : `$${mpItem.mp_estimated_profit.toFixed(2)}`}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default MpInbound;
