import React, { Component } from "react";
import { Container, Row, Col, Form, Button, Spinner, InputGroup, FormControl } from "react-bootstrap";
// import { config } from "../../../../../backend/includes/database";
import { reconfigTB, updateVendorsInTB } from "../../../includes/HelperTriggerBatches";

//import ColorTextPicker from "../../Others/ColorTextPicker";

class BookScouterTrigger extends Component {
  state = {
    isTriggerEnabled: false,
    config_bs: [],
    targetDiff: 0,
    customText: "",
    customColor: "",
    isUpdatingVendorList: false,
    activeVendorsList: [],
    minPrice: 0,
  };

  constructor(props) {
    super(props);
    this.handleReConfig = this.handleReConfig.bind(this);
  }

  componentDidMount() {
    /**
     * This is what executes before everything when the page loads.
     * What we're doing here:
     * 1. calling API to load/set triggers
     */
    this.setState({ triggerBatchId: this.props.triggerBatchId });

    if (
      "conf_bookscouter" in this.props.batchConfiguration &&
      this.props.batchConfiguration.conf_bookscouter !== null
    ) {
      console.log(this.props.batchConfiguration.conf_bookscouter);
      const config_bs = JSON.parse(this.props.batchConfiguration.conf_bookscouter);

      this.setState({ config_bs });

      if ("enabled" in config_bs) {
        this.setState({
          isTriggerEnabled: config_bs.enabled,
          targetDiff: config_bs.target_diff,
          activeVendorsList: "vendors" in config_bs ? JSON.parse(config_bs.vendors) : [],
          customText: "text" in config_bs ? config_bs.text : "",
          customColor: "color" in config_bs ? config_bs.color : "",
          minPrice: "min_price" in config_bs ? config_bs.min_price : 0,
        });
      }
    }
  }
  // async handleReConfig(color, text) {
  //   let newConfig = {};
  //   const targetDiff = this.state.targetDiff;
  //   if (targetDiff >= 0) {
  //     newConfig.enabled = true;
  //     newConfig.target_diff = targetDiff;
  //     newConfig.vendors = this.setState({ isTriggerEnabled: true });
  //   } else {
  //     newConfig.enabled = false;
  //     this.setState({ isTriggerEnabled: false });
  //   }

  //   await reconfigTB(this.props.admin_data, this.props.triggerBatchId, "conf_bookscouter", JSON.stringify(newConfig));
  // }

  async handleReConfig() {
    let config_bs = { ...this.state.config_bs };

    this.setState({ reconfigInProgress: true });
    config_bs.min_price = parseFloat(this.state.minPrice);

    this.props.updateTBConfig("conf_bookscouter", config_bs);
    await reconfigTB(this.props.admin_data, this.props.triggerBatchId, "conf_bookscouter", JSON.stringify(config_bs));
    this.setState({ reconfigInProgress: false });
  }

  async updateVendorsList(e) {
    this.setState({ isUpdatingVendorList: true });
    const activeVendorsList = [...this.state.activeVendorsList];
    const vIndex = activeVendorsList.indexOf(e.target.value);

    if (e.target.checked) activeVendorsList.push(e.target.value);
    else activeVendorsList.splice(vIndex, 1);

    await updateVendorsInTB(this.props.admin_data, this.props.triggerBatchId, JSON.stringify(activeVendorsList));
    console.log(activeVendorsList);

    this.setState({
      activeVendorsList,
      isUpdatingVendorList: false,
    });
    this.props.updateBSVendors(activeVendorsList);
  }

  render() {
    const vendorsList = [
      "Textbook Drop",
      "Books2Cash",
      "ValoreBooks",
      "Bookbyte",
      "Buyback101",
      "Sell Books",
      "SellBackBooks",
      "TopDollar4Books",
      "eCampus",
      "RentText",
      "CollegeBooksDirect",
      "TextbookManiac",
      "BooksRun",
      "TextbookRush",
      "TextbookCashback",
      "Powell's",
      "Comic Blessing",
      // "SellBackYourBook",
      "BeerMoneyBooks",
      "BlueRocketBooks",
      "Bookstores.com",
      "Amazon",
      "Empire Text",
      "PiggyBook",
      "BookMonster",
      "Textbooks.com ",
    ];
    let isMEnabled = false;
    if ("high_level" in this.props.batchConfiguration && this.props.batchConfiguration.high_level !== null) {
      const config_high_level = JSON.parse(this.props.batchConfiguration.high_level);
      const index = config_high_level.findIndex(function (module) {
        return module.module_name === "BookScouter";
      });
      isMEnabled = config_high_level[index].enabled;
    }

    return (
      <React.Fragment>
        {isMEnabled === true && (
          <Container fluid={true} style={{ padding: "0" }}>
            <div style={{ display: "flex", marginBottom: "20px" }}>
              <div style={{ paddingTop: "5px", marginRight: "10px" }}>Accept only if price is greater than </div>
              <div>
                <InputGroup id="ig_asin" style={{ width: "250px" }}>
                  <InputGroup.Prepend>
                    <InputGroup.Text id="inputGroupPrepend">$</InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    style={{ width: "50px" }}
                    value={this.state.minPrice}
                    onChange={(e) => {
                      this.setState({ minPrice: e.target.value });
                    }}
                    autoComplete="off"
                    id="minPriceInput"
                    placeholder="Min"
                    type="number"
                    aria-describedby="basic-addon1"
                  />
                  <InputGroup.Append id="btn_asin_search">
                    <Button onClick={this.handleReConfig} disabled={this.state.reconfigInProgress}>
                      {this.state.reconfigInProgress === true ? (
                        <Spinner style={{ width: "20px", height: "20px" }} animation="border" role="status">
                          <span className="sr-only">Loading...</span>
                        </Spinner>
                      ) : (
                        <span>Save</span>
                      )}
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
              </div>
            </div>

            <Row style={{ paddingBottom: "15px" }}>
              <Col md={12}>
                <strong>Select vendors to compare</strong>
                <Row style={{ marginTop: "20px" }}>
                  {vendorsList.map((vendor) => {
                    return (
                      <Col md={4}>
                        <Form.Check
                          disabled={this.state.isUpdatingVendorList}
                          checked={this.state.activeVendorsList.indexOf(vendor) > -1 ? true : false}
                          style={{ marginBottom: "10px" }}
                          type="checkbox"
                          id={"check-" + vendor}
                          onChange={(e) => this.updateVendorsList(e)}
                          label={vendor}
                          value={vendor}
                        />
                      </Col>
                    );
                  })}
                </Row>
              </Col>

              {/* {this.state.isTriggerEnabled === true && (
                <Col md={12} style={{ marginTop: "20px" }}>
                  <ColorTextPicker
                    customColor={this.state.customColor}
                    customText={this.state.customText}
                    updateCustomTextColor={async (color, text) => {
                      await this.handleReConfig(color, text);
                    }}
                    updatingCustomTextColor={this.state.reconfigInProgress}
                  />
                </Col>
              )} */}
            </Row>
          </Container>
        )}
      </React.Fragment>
    );
  }
}

export default BookScouterTrigger;
