//var styled = require("react-tabtab").styled;
//let { TabListStyle, ActionButtonStyle, TabStyle, PanelStyle } = styled;

import styled from "styled-components";
import { styled as styledTabTab } from "react-tabtab";

let { ActionButtonStyle, TabStyle, PanelStyle } = styledTabTab;

// TabListStyle = styled(TabListStyle)`
//   background-color: yellow;
// `;

TabStyle = styled(TabStyle)`
  background-color: #e9ecef;
  transition: color 0.28s ease;
  ${(props) =>
    props.active && !props.vertical ? ` border-bottom: 2px solid #232327;background-color: #007BFF;color: #fff;` : null}
  &:hover {
    color: white;
    background-color: #007bff;
    border-bottom: 2px solid #232327;
  }
`;

// TabStyle = styled(TabStyle)`
//   background-color: #e9ecef;
//   color: #000;
//   transition: color 0.28s ease;
//   ${(props) =>
//     props.active && !props.vertical ? ` border-bottom: 2px solid #232327;background-color: #007BFF;color: #fff;` : null}
// `;

ActionButtonStyle = styled(ActionButtonStyle)`
  background-color: blue;
  border-radius: 0;
  &:hover {
    background-color: #eee;
  }
`;

PanelStyle = styled(PanelStyle)`
  padding: 15px 0 0 0;
`;

// need to follow this object naming
export { TabStyle as Tab, ActionButtonStyle as ActionButton, PanelStyle as Panel };
