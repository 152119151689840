import React, { Component } from "react";
import { Form, Modal, Button, FormControl } from "react-bootstrap";

class ModalAddTriggerBatch extends Component {
  state = {};

  componentDidMount() {
    if (this.batchNameInput !== undefined) this.batchNameInput.select();
  }

  render() {
    return (
      <Modal show={this.props.showAddModal} onHide={this.props.handleClose} dialogClassName="editModal">
        <Form onSubmit={this.props.onFormSubmitAddTrigger}>
          <Modal.Header closeButton>
            <FormControl
              required
              ref={(input) => {
                this.batchNameInput = input;
              }}
              type="text"
              name="triggerName"
              className="input-text-trigger-name"
              placeholder="Enter Trigger Batch Name Here"
              autoFocus
            />
          </Modal.Header>

          <Modal.Body>
            {this.props.teams !== false && this.props.teams.length >= 1 && (
              <div>
                <Form.Group controlId="formTeamId">
                  <Form.Label>Team</Form.Label>
                  <Form.Control as="select" name="teamId">
                    {this.props.teams.map((teamGroup) => {
                      return <option value={teamGroup.id}>{teamGroup.team_name}</option>;
                    })}
                  </Form.Control>
                </Form.Group>
              </div>
            )}
          </Modal.Body>

          <Modal.Footer style={{ border: "none" }}>
            <Button variant="primary" type="submit">
              Save Changes
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

export default ModalAddTriggerBatch;
