import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import TriggerBatches from "./pages/TriggerBatches";
import Route404 from "./pages/Route404";
import "./assets/style/theme_minimal.css";
//import Dashboard from "./pages/Dashboard";
import Dashboard from "./pages/Dashboard_";
import TriggerBatch from "./pages/TriggerBatch";
import Users from "./pages/Users";
import Login from "./pages/Login";
import Sounds from "./pages/Sounds";
import Scans from "./pages/Scans";
import ShippingPlans from "./pages/ShippingPlans";
import Settings from "./pages/Settings";
import Shipments from "./pages/Shipments";
import ProtectedRoute from "./ProtectedRoute";
import ReEvaluateQueue from "./pages/ReEvaluateQueue";
import Invite from "./pages/Invite";
import MpSources from "./pages/MpSources";
import BoxContent from "./pages/BoxContent";
import MpInbound from "./pages/MpInbound";
import MpBatches from "./pages/MpBatches";
import Profiles from "./pages/Profiles";
import ZiffitLedger from "./pages/ZiffitLedger";
import WhiteLabel from "./pages/WhiteLabel";

class App extends Component {
  render() {
    return (
      <React.Fragment>
        <Router>
          <Switch>
            <Route exact path="/" component={Login} />
            <ProtectedRoute exact path="/whitelabel" component={WhiteLabel} />
            <ProtectedRoute exact path="/dashboard" component={Dashboard} />
            <ProtectedRoute exact path="/triggers" component={TriggerBatches} />
            <ProtectedRoute exact path="/users" component={Users} />
            <ProtectedRoute exact path="/profiles" component={Profiles} />
            <ProtectedRoute exact path="/invite" component={Invite} />
            <ProtectedRoute exact path="/sounds" component={Sounds} />
            <ProtectedRoute exact path="/ziffit-ledger" component={ZiffitLedger} />
            <ProtectedRoute exact path="/reevaluate-queue" component={ReEvaluateQueue} />
            <ProtectedRoute exact path="/scans" component={Scans} />
            <ProtectedRoute exact path="/shipments" component={Shipments} />
            <ProtectedRoute exact path="/box-tool" component={BoxContent} />
            <ProtectedRoute exact path="/account-settings" component={Settings} />
            <ProtectedRoute exact path="/shipping-plans" component={ShippingPlans} />
            <ProtectedRoute exact path="/mp-sources" component={MpSources} />
            <ProtectedRoute exact path="/mp-batches" component={MpBatches} />
            <ProtectedRoute exact path="/mp-inbound" component={MpInbound} />
            <ProtectedRoute exact path="/edit-trigger-batch/:id" component={TriggerBatch} />
            <Route component={Route404} />
          </Switch>
        </Router>
      </React.Fragment>
    );
  }
}

export default App;
