import React, { Component } from "react";
import { Row, Col, Container, Button, Table, Spinner, Form } from "react-bootstrap";
import { uploadNewProfile, deleteProfile, toggleIsMultiProfile } from "../includes/HelperUsers";
import TopMenu from "../components/TopMenu";
import Cookies from "universal-cookie";

const cookies = new Cookies();

class Profiles extends Component {
  state = {
    admin_data: cookies.get("admin_data"),
    currentProfilePictureFile: null,
    showMultiProfileOption: false,
    addingNewProfile: false,
    isToggling: false,
    profiles: [],
    newProfileName: "",
    newProfileError: "",
  };

  constructor() {
    super();
    this.addNewProfile = this.addNewProfile.bind(this);
    this.onChangeProfilePictureFile = this.onChangeProfilePictureFile.bind(this);
    this.deleteExistingProfile = this.deleteExistingProfile.bind(this);
    this.updateIsMultiProfile = this.updateIsMultiProfile.bind(this);
  }

  initialize() {
    if (this.state.admin_data.is_multi_profiles === 1) this.setState({ showMultiProfileOption: true });
  }

  onChangeProfilePictureFile = (e) => {
    if (e.target.files[0] !== undefined) {
      this.setState({ currentProfilePictureFile: e.target.files[0] });
    }
  };
  async addNewProfile(profileName) {
    this.setState({ addingNewProfile: true, newProfileError: "" });
    const res = await uploadNewProfile(
      this.state.admin_data.team_id,
      profileName,
      this.state.currentProfilePictureFile,
      this.state.admin_data.admin_id,
      this.state.admin_data.token
    );

    if (res !== false && "status" in res) {
      if (res.status === true) {
        let admin_data = { ...this.state.admin_data };
        admin_data.profiles = JSON.parse(res.profiles);
        this.setState({ admin_data, newProfileName: "" });
      } else this.setState({ newProfileError: res.error });
    }
    this.setState({ addingNewProfile: false });
  }

  async updateIsMultiProfile(isChecked) {
    this.setState({ isToggling: true });
    const res = await toggleIsMultiProfile(
      this.state.admin_data.team_id,
      isChecked,
      this.state.admin_data.admin_id,
      this.state.admin_data.token
    );
    if (res !== false && "status" in res && res.status === true) {
      let admin_data = { ...this.state.admin_data };
      admin_data.profiles = JSON.parse(res.profiles);
      this.setState({ admin_data });
    }
    this.setState({ isToggling: false });
  }

  async deleteExistingProfile(profileName) {
    this.setState({ deletingProfile: true });
    const res = await deleteProfile(
      this.state.admin_data.team_id,
      profileName,
      this.state.admin_data.admin_id,
      this.state.admin_data.token
    );
    if (res !== false && "status" in res && res.status === true) {
      let admin_data = { ...this.state.admin_data };
      admin_data.profiles = JSON.parse(res.profiles);
      this.setState({ admin_data });
    }
    this.setState({ deletingProfile: false });
  }

  componentDidMount() {
    this.initialize();
  }

  render() {
    return (
      <React.Fragment>
        <TopMenu
          handlenavigationroutes={(routeName) => {
            // abortRequests();
            this.props.history.push(routeName);
          }}
          history={this.props.history}
          activeKey="/sounds"
          isReevaluate={
            "is_re_evaluate" in this.state.admin_data && this.state.admin_data.is_re_evaluate === 1 ? true : false
          }
          adminData={this.state.admin_data}
        />

        <Container fluid={true}>
          <Row style={{ marginTop: "20px" }}>
            <Col md={12}>
              <h3>Manage Profiles</h3>
            </Col>
          </Row>
          <Row
            style={{
              marginTop: "20px",
            }}
          >
            <Col md={12}>
              <div>
                <Form.Check
                  defaultChecked={this.state.admin_data.is_multi_profiles === 1}
                  onChange={(e) => {
                    const isChecked = e.target.checked;
                    this.setState({ showMultiProfileOption: isChecked }, () => {
                      this.updateIsMultiProfile(isChecked);
                    });
                  }}
                  type="switch"
                  name="isMultiEnabled"
                  id="is-multi-enabled"
                  label="Enable Multiple Profiles?"
                />
              </div>
              {this.state.showMultiProfileOption && (
                <div style={{ marginTop: "10px", padding: "0px" }}>
                  <div
                    style={{
                      marginTop: "20px",
                      paddingTop: "20px",
                      borderTop: "1px solid #ddd",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ flex: 4 }}>
                      <Form.Group controlId="formAudioFile">
                        <Form.Label>Choose Profile Photo</Form.Label>
                        <Form.Control
                          accept="image/*"
                          type="file"
                          onChange={this.onChangeProfilePictureFile}
                          placeholder="Choose Profile Photo"
                        />
                      </Form.Group>
                    </div>
                    <div style={{ width: "100%", flex: 5 }}>
                      <Form.Control
                        style={{ border: "none", borderBottom: "1px solid #ddd", borderRadius: 0 }}
                        type="text"
                        onChange={(e) => this.setState({ newProfileName: e.target.value })}
                        value={this.state.newProfileName}
                        placeholder="Give a name"
                      />
                    </div>
                    <div style={{ width: "100%", flex: 2, paddingLeft: "40px" }}>
                      <Button
                        onClick={() => this.addNewProfile(this.state.newProfileName)}
                        variant="primary"
                        disabled={this.state.newProfileName.length <= 0}
                        size="sm"
                        style={{ width: "100%" }}
                      >
                        {this.state.addingNewProfile === true ? (
                          <Spinner style={{ width: "15px", height: "15px" }} animation="border" role="status"></Spinner>
                        ) : (
                          <span>Add New</span>
                        )}
                      </Button>
                    </div>
                  </div>

                  <div style={{ marginTop: "30px" }}>
                    <Table>
                      <tbody>
                        {this.state.admin_data.profiles.map((profile) => {
                          return (
                            <tr>
                              <td style={{ textAlign: "left", paddingLeft: 0 }}>
                                {profile.picture !== "" ? (
                                  <img
                                    style={{ width: "65px" }}
                                    src={`https://files.palletiq.com/profile_images/${profile.picture}`}
                                  />
                                ) : (
                                  ""
                                )}
                              </td>
                              <td style={{ textAlign: "left" }}>{profile.name}</td>
                              <td style={{ textAlign: "right" }}>
                                <Button
                                  onClick={() => this.deleteExistingProfile(profile.name)}
                                  variant="danger"
                                  size="sm"
                                >
                                  <i className="fa fa-trash" />
                                </Button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>

                  {/* <TagsInput
                            inputProps={{
                              className: "react-tagsinput-input",
                              placeholder: "Add Scanning Profiles",
                            }}
                            onlyUnique={true}
                            value={this.state.multiProfiles}
                            onChange={(tags) => {
                              this.setState({ multiProfiles: tags });
                            }}
                          />
                          <div style={{ marginTop: "5px" }}>Note: Use ENTER or TAB key to add more profiles</div> */}
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default Profiles;
