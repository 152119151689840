import { apiBaseUrlLocal } from "./AppConfig";
import axios from "axios";

export const scheduleForNow = async (queue_id, admin_data) => {
  const response = await fetch(apiBaseUrlLocal + "admin/re_evaluation/run_now/" + queue_id, {
    method: "POST",
    body: new URLSearchParams({
      user_id: admin_data.admin_id,
      app_token: admin_data.token,
    }),
  });
  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else return false;
};

export const getTriggerBatches = async (admin_data) => {
  const response = await fetch(apiBaseUrlLocal + "admin/tbatches/list", {
    method: "POST",
    body: new URLSearchParams({
      user_id: admin_data.admin_id,
      app_token: admin_data.token,
    }),
  });
  return await response.json();
};

export const loadReEvaluateQueue = async (admin_data) => {
  const response = await fetch(apiBaseUrlLocal + "admin/re_evaluation/list", {
    method: "POST",
    body: new URLSearchParams({
      user_id: admin_data.admin_id,
      app_token: admin_data.token,
    }),
  });
  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else return false;
};

export const saveReEvaluateQueue = async (
  admin_data,
  queueName,
  asinFile,
  triggerBatchId,
  shippingPlanId,
  frequency,
  recipientEmail,
  csvFields,
  isLive
) => {
  const data = new FormData();
  data.append("user_id", admin_data.admin_id);
  data.append("app_token", admin_data.token);
  data.append("queue_name", queueName);
  data.append("asinFile", asinFile);
  data.append("frequency", frequency);
  data.append("trigger_batch_id", triggerBatchId);
  data.append("shipping_plan_id", shippingPlanId);
  data.append("recipient_email", recipientEmail);
  data.append("csv_fields", csvFields);
  data.append("is_live", isLive);

  try {
    let res = await axios.post(apiBaseUrlLocal + "admin/re_evaluation/", data);
    const dataRes = await res.data;
    return dataRes;
  } catch (err) {
    return false;
  }

  // const response = await fetch(apiBaseUrlLocal + "admin/re_evaluation/", {
  //   method: "POST",
  //   body: new URLSearchParams({
  //     user_id: admin_data.admin_id,
  //     app_token: admin_data.token,
  //     queue_name,
  //     asin_list,
  //     frequency,
  //     trigger_batch_id,
  //     recipient_email: recipientEmail,
  //     csv_fields: csvFields,
  //     is_live: isLive,
  //   }),
  // });
  // if (response.status === 200) {
  //   const data = await response.json();
  //   return data;
  // } else return false;
};

export const updateReEvaluateQueue = async (
  admin_data,
  queue_id,
  queue_name,
  asin_list,
  trigger_batch_id,
  frequency
) => {
  const response = await fetch(apiBaseUrlLocal + "admin/re_evaluation/" + queue_id, {
    method: "PUT",
    body: new URLSearchParams({
      user_id: admin_data.admin_id,
      app_token: admin_data.token,
      queue_name,
      asin_list,
      frequency,
      trigger_batch_id,
    }),
  });
  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else return false;
};

export const deleteReEvaluateQueue = async (admin_data, queue_id) => {
  const response = await fetch(apiBaseUrlLocal + "admin/re_evaluation/" + queue_id, {
    method: "DELETE",
    body: new URLSearchParams({
      user_id: admin_data.admin_id,
      app_token: admin_data.token,
    }),
  });
  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else return false;
};
