import { apiBaseUrlLocal } from "./AppConfig";

export const sendInvitation = async (teamEmail, teamName, admin_data) => {
  const response = await fetch(apiBaseUrlLocal + "admin/invite", {
    method: "POST",
    body: new URLSearchParams({
      user_id: admin_data.admin_id,
      app_token: admin_data.token,
      team_email: teamEmail,
      team_name: teamName,
    }),
  });
  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else return false;
};

export const saveNotes = async (admin_data, inviteUserId, notes) => {
  const response = await fetch(apiBaseUrlLocal + "admin/invite/notes", {
    method: "PUT",
    body: new URLSearchParams({
      user_id: admin_data.admin_id,
      app_token: admin_data.token,
      invite_user_id: inviteUserId,
      notes: notes,
    }),
  });
  if (response.status === 200) {
    const data = await response.json();

    return data;
  } else return false;
};

export const getInvitations = async (admin_data) => {
  const response = await fetch(apiBaseUrlLocal + "admin/invite/list", {
    method: "POST",
    body: new URLSearchParams({
      user_id: admin_data.admin_id,
      app_token: admin_data.token,
    }),
  });
  if (response.status === 200) {
    const data = await response.json();

    return data;
  } else return false;
};

export const resendEmail = async (invite_user_id, admin_data) => {
  const response = await fetch(apiBaseUrlLocal + "admin/invite/resend", {
    method: "POST",
    body: new URLSearchParams({
      user_id: admin_data.admin_id,
      app_token: admin_data.token,
      invite_user_id: invite_user_id,
    }),
  });
  if (response.status === 200) {
    const data = await response.json();

    return data;
  } else return false;
};

export const cancelInvite = async (invite_user_id, admin_data) => {
  const response = await fetch(apiBaseUrlLocal + "admin/invite/cancel", {
    method: "POST",
    body: new URLSearchParams({
      user_id: admin_data.admin_id,
      app_token: admin_data.token,
      invite_user_id: invite_user_id,
    }),
  });
  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else return false;
};
