import React, { Component, Fragment } from "react";
import { Form, Modal, Button, FormControl, Spinner, Table } from "react-bootstrap";

class ModalEmailUBB extends Component {
  state = {
    containerType: "boxes",
    numberOfContainers: 1,
    paymentMethod: "paypal",
  };

  initiateModal() {
    this.setState({ containerType: "boxes", numberOfContainers: 1 });
  }

  constructor() {
    super();

    this.initiateModal = this.initiateModal.bind(this);
    this.submitEmailVendor = this.submitEmailVendor.bind(this);
  }

  submitEmailVendor(e) {
    e.preventDefault();

    const numberOfContainers = e.target.elements.numberOfContainers.value;
    const boxWeightsElements = e.target.elements["boxWeights[]"];
    let boxWeights = [];

    if (Array.isArray(boxWeightsElements)) {
      boxWeightsElements.forEach((bwe) => {
        boxWeights.push(bwe.value);
      });
    } else boxWeights.push(boxWeightsElements.value);

    const contactName = e.target.elements.contactName.value;
    const contactPhone = e.target.elements.contactPhone.value;
    const shipFromAddress = e.target.elements.shipFromAddress.value;
    const paymentMethod = e.target.elements.paymentMethod.value;

    let paypalEmail = "";
    let paymentName = "";
    let paymentAddress = "";

    if (this.state.paymentMethod === "paypal") {
      paypalEmail = e.target.elements.paypalEmail.value;
    } else {
      paymentName = e.target.elements.paymentName.value;
      paymentAddress = e.target.elements.paymentAddress.value;
    }

    const formData = {
      numberOfContainers,
      boxWeights,
      contactName,
      contactPhone,
      shipFromAddress,
      paymentMethod,
      paypalEmail,
      paymentName,
      paymentAddress,
    };

    this.props.submitShipmentData(formData);
  }

  render() {
    return (
      <Modal
        onEnter={this.initiateModal}
        show={this.props.showModal}
        onHide={this.props.handleClose}
        dialogClassName="editModal"
      >
        <Modal.Header closeButton>
          <h3>Send Email to {this.props.selectedShipment.display_name}</h3>
        </Modal.Header>

        <Modal.Body>
          <Form inline onSubmit={this.submitEmailVendor}>
            <Table bordered>
              <tbody>
                <tr>
                  <td>Number of Boxes</td>
                  <td style={{ paddingLeft: "20px" }}>
                    <Form.Control
                      required
                      defaultValue={1}
                      maxLength={3}
                      style={{ width: "100px" }}
                      type="numbers"
                      name="numberOfContainers"
                      onChange={(e) => {
                        this.setState({ numberOfContainers: e.target.value });
                      }}
                    />
                  </td>
                </tr>

                <tr>
                  <td>Boxes' Weights</td>
                  <td style={{ paddingLeft: "20px" }} colSpan={3}>
                    {Array.from({ length: this.state.numberOfContainers }, (_, k) => (
                      <FormControl
                        required
                        style={{ width: "80px", margin: "2.5px 5px 2.5px 0" }}
                        key={k}
                        name="boxWeights[]"
                        type="numbers"
                        placeholder={`Box ${k + 1}`}
                      ></FormControl>
                    ))}
                  </td>
                </tr>

                <tr>
                  <td>Contact Name</td>
                  <td style={{ paddingLeft: "20px" }}>
                    {" "}
                    <FormControl required name="contactName" type="text"></FormControl>
                  </td>
                </tr>
                <tr>
                  <td>Contact Phone</td>
                  <td style={{ paddingLeft: "20px" }}>
                    {" "}
                    <FormControl required name="contactPhone" type="text"></FormControl>
                  </td>
                </tr>
                <tr>
                  <td>Ship from Address</td>
                  <td style={{ paddingLeft: "20px" }}>
                    {" "}
                    <Form.Control required name="shipFromAddress" as="textarea" rows="3" />
                  </td>
                </tr>
                <tr>
                  <td>Payment Method</td>
                  <td style={{ paddingLeft: "20px" }}>
                    <FormControl
                      required
                      as="select"
                      onChange={(e) => {
                        this.setState({ paymentMethod: e.target.value });
                      }}
                      name="paymentMethod"
                    >
                      <option value="paypal">PayPal</option>
                      <option value="cheque">Cheque</option>
                    </FormControl>
                  </td>
                </tr>
                {this.state.paymentMethod === "paypal" ? (
                  <tr>
                    <td>PayPal Email</td>
                    <td style={{ paddingLeft: "20px" }}>
                      {" "}
                      <FormControl required name="paypalEmail" type="text"></FormControl>
                    </td>
                  </tr>
                ) : (
                  <Fragment>
                    <tr>
                      <td>Payment Name</td>
                      <td style={{ paddingLeft: "20px" }}>
                        {" "}
                        <FormControl required name="paymentName" type="text"></FormControl>
                      </td>
                    </tr>
                    <tr>
                      <td>Payment Address</td>
                      <td style={{ paddingLeft: "20px" }}>
                        {" "}
                        <FormControl required name="paymentAddress" type="text"></FormControl>
                      </td>
                    </tr>
                  </Fragment>
                )}
              </tbody>
            </Table>
            {this.props.inProcessSubmitData === true ? (
              <Spinner animation="border" role="status"></Spinner>
            ) : (
              <Button variant="primary" type="submit">
                Send Email
              </Button>
            )}
          </Form>
        </Modal.Body>
      </Modal>
    );
  }
}

export default ModalEmailUBB;
