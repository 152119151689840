import React, { Component } from "react";
import { Modal, Form, Spinner, Button, Row, Col } from "react-bootstrap";
import Select from "react-select";

class EditTeam extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const flags =
      this.props.selectedTeam && this.props.selectedTeam.site_flags
        ? JSON.parse(this.props.selectedTeam.site_flags)
        : {};

    return this.props.selectedTeam ? (
      <Modal show={this.props.showEditModal} onHide={this.props.handleClose} dialogClassName="editModal">
        <Form onSubmit={this.props.onFormSubmitEdit}>
          <Modal.Header closeButton>
            <h4>{this.props.selectedTeam.sub_domain}.scanporter.com</h4>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col md={12}>
                <Form.Group controlId="formFirstName">
                  <Form.Label>Pick a team</Form.Label>
                  <Select
                    required
                    defaultValue={{ label: this.props.selectedTeam.team_name, value: this.props.selectedTeam.id }}
                    className="basic-single"
                    classNamePrefix="select"
                    isClearable={true}
                    isSearchable={true}
                    name="teamId"
                    options={this.props.teams.map((team) => {
                      return {
                        label: team.team_name,
                        value: team.id,
                      };
                    })}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row style={{ marginBottom: "20px" }}>
              <Col md={6}>
                <Form.Check
                  defaultChecked={flags.hidden_mode}
                  name="isHiddenMode"
                  style={{ marginBottom: "10px" }}
                  type="checkbox"
                  id={"check-hidden-mode"}
                  label={"Enable Hidden Mode"}
                />
                <Form.Check
                  defaultChecked={flags.sales_rank}
                  name="isShowSalesRank"
                  style={{ marginBottom: "10px" }}
                  type="checkbox"
                  id={"check-salesrank"}
                  label={"Show SalesRank"}
                />
                <Form.Check
                  defaultChecked={flags.infoboxes}
                  name="isInfoBoxesEnabled"
                  style={{ marginBottom: "10px" }}
                  type="checkbox"
                  id={"check-infoboxes"}
                  label={"Enable Info boxes"}
                />
                <Form.Check
                  defaultChecked={flags.menu_sounds}
                  name="isShowTopMenuSounds"
                  style={{ marginBottom: "10px" }}
                  type="checkbox"
                  id={"check-topmenu-sounds"}
                  label={"Top Menu > Sounds"}
                />
              </Col>
              <Col md={6}>
                <Form.Check
                  defaultChecked={flags.menu_settings}
                  name="isShowTopMenuSettings"
                  style={{ marginBottom: "10px" }}
                  type="checkbox"
                  id={"check-topmenu-settings"}
                  label={"Top Menu > Settings"}
                />
                <Form.Check
                  defaultChecked={flags.menu_shipments}
                  name="isShowTopMenuShipments"
                  style={{ marginBottom: "10px" }}
                  type="checkbox"
                  id={"check-topmenu-shipments"}
                  label={"Top Menu > Shipments"}
                />
                <Form.Check
                  defaultChecked={flags.menu_scans}
                  name="isShowTopMenuScans"
                  style={{ marginBottom: "10px" }}
                  type="checkbox"
                  id={"check-topmenu-scans"}
                  label={"Top Menu > Scans"}
                />
                <Form.Check
                  defaultChecked={flags.menu_source}
                  name="isShowTopMenuSource"
                  style={{ marginBottom: "10px" }}
                  type="checkbox"
                  id={"check-topmenu-source"}
                  label={"Top Menu > Source"}
                />
              </Col>
            </Row>

            <Row>
              <Col md={4}>
                <Form.Group controlId="formFirstName">
                  <Form.Label>Allot a sub domain</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    defaultValue={this.props.selectedTeam.sub_domain}
                    name="subDomain"
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="formFirstName">
                  <Form.Label>Login page support Email</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    defaultValue={this.props.selectedTeam.support_email}
                    name="supportEmail"
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="formFirstName">
                  <Form.Label>Browser Title</Form.Label>
                  <Form.Control
                    required
                    defaultValue={this.props.selectedTeam.browser_title}
                    type="text"
                    name="browserTitle"
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={4}>
                <Form.Group controlId="formAudioFile">
                  <Form.Label>Logo Dark - Login Page</Form.Label>
                  <Form.Control
                    onChange={this.props.setFileLogoDark}
                    type="file"
                    name="logoDark"
                    placeholder="Choose Image File"
                  />
                </Form.Group>
                <div style={{ fontSize: "13px" }}>Recommended 400px X 172px</div>
              </Col>
              <Col md={4}>
                <Form.Group controlId="formAudioFile">
                  <Form.Label>Logo Light - Home Page</Form.Label>
                  <Form.Control
                    onChange={this.props.setFileLogoLight}
                    type="file"
                    name="logoLight"
                    placeholder="Choose Image File"
                  />
                </Form.Group>
                <div style={{ fontSize: "13px" }}>Recommended 400px X 172px</div>
              </Col>
              <Col md={4}>
                <Form.Group controlId="formAudioFile">
                  <Form.Label>Home Page Right Banner</Form.Label>
                  <Form.Control
                    onChange={this.props.setFileHomeBanner}
                    type="file"
                    name="homeBanner"
                    placeholder="Choose Image File"
                  />
                </Form.Group>
                <div style={{ fontSize: "13px" }}>Recommended 1080px X 720px</div>
              </Col>
            </Row>

            <div className="wl-build-options-row" style={{ marginTop: "50px" }}>
              <div style={{ flex: 1 }}>
                <div style={{ fontWeight: "bold" }}>Winners Map Config</div>
                <div style={{ fontSize: "13px", fontStyle: "italic" }}>(Only change if you know what you're doing)</div>
              </div>
              <div className="wl-build-options-col-2">
                <Form.Control
                  required
                  defaultValue={this.props.selectedTeam.winners_map}
                  name="winnersMap"
                  as="textarea"
                  rows="8"
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {this.props.invalidUserAddMessage !== "" && (
              <div className="mr-auto" style={{ color: "red", fontSize: "12.5px" }}>
                {this.props.invalidUserAddMessage}
              </div>
            )}
            {this.props.inProgressUpdate === true ? (
              <Spinner animation="border" role="status"></Spinner>
            ) : (
              <Button variant="primary" type="submit">
                Save
              </Button>
            )}
          </Modal.Footer>
        </Form>
      </Modal>
    ) : (
      <></>
    );
  }
}

export default EditTeam;
