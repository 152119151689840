import React from "react";
import { Route, Redirect } from "react-router-dom";
import auth from "./Auth";
import { Spinner } from "react-bootstrap";

class ProtectedRoute extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      isLoading: true,
      isLoggedIn: false,
    };

    let urlToken = "";
    let search = window.location.search;
    let params = new URLSearchParams(search);
    urlToken = params.get("token");

    if (urlToken !== "" && urlToken !== undefined && urlToken !== null) {
      auth.setAdminData({
        token: urlToken,
      });

      window.location = window.location.href.split("?")[0];
      return false;
    }

    this.validateToken();
  }

  async validateToken() {
    const isAuthenticated = await auth.isAuthenticated();
    this.setState(() => ({ isLoading: false, isLoggedIn: isAuthenticated }));
  }

  render() {
    return this.state.isLoading ? (
      <div style={{ textAlign: "center", fontSize: "34px", marginTop: "50px" }}>
        <Spinner
          animation="border"
          role="status"
          style={{ marginRight: "10px", width: "40px", height: "40px" }}
        ></Spinner>{" "}
        Loading ...
      </div>
    ) : this.state.isLoggedIn ? (
      <Route path={this.props.path} component={this.props.component} exact={this.props.exact} />
    ) : (
      <Redirect to={{ pathname: "/", state: { from: this.props.location } }} />
    );
  }
}

export default ProtectedRoute;
