import React, { Component } from "react";
import TopMenu from "../components/TopMenu";
import Cookies from "universal-cookie";
import { Container, Row, Col, Spinner, Button } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { apiBaseUrlLocal } from "../includes/AppConfig";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import {
  loadUsers,
  addUser,
  editUser,
  deleteUser,
  loadTeams,
  uploadNewProfile,
  deleteProfile,
} from "../includes/HelperUsers";
import ModalAdd from "../components/Users/ModalAdd";
import ModalEdit from "../components/Users/ModalEdit";
import { getTriggerBatches } from "../includes/HelperTriggers";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { loadShippingPlans } from "../includes/HelperShippingPlans";

const cookies = new Cookies();

const { SearchBar } = Search;

class Users extends Component {
  state = {
    users: [],
    isLoadingTableUsers: false,
    addingNewProfile: false,
    currentProfilePictureFile: null,
    columns: [],
    showAddModal: false,
    triggerBatches: [],
    selectedUserToEdit: null,
    admin_data: cookies.get("admin_data"),
    inProgressEdit: false,
    inProgressAdd: false,
    teams: [],
    shippingPlans: [],
    subscriptionJSON: {},
    invalidUserAddMessage: "",
  };
  constructor() {
    super();
    this.fetchUsersAndBatches = this.fetchUsersAndBatches.bind(this);
    this.onFormSubmitEditUser = this.onFormSubmitEditUser.bind(this);
    this.handleDeleteUser = this.handleDeleteUser.bind(this);
    this.onFormSubmitAddUser = this.onFormSubmitAddUser.bind(this);
    this.handleAddNewUser = this.handleAddNewUser.bind(this);
    this.getSubscription = this.getSubscription.bind(this);
    this.onChangeProfilePictureFile = this.onChangeProfilePictureFile.bind(this);
    this.addNewProfile = this.addNewProfile.bind(this);
    this.deleteExistingProfile = this.deleteExistingProfile.bind(this);
  }

  handleLogout() {
    cookies.remove("admin_data");
    window.location = "/?token-expiry=1";
  }

  componentWillMount() {
    this.loadTeams();
    this.getShippingPlans();
    this.fetchUsersAndBatches();
    this.getSubscription();
  }

  getDaysRemaining() {
    if ("subData" in this.state.subscriptionJSON) {
      const diff =
        new Date(this.state.subscriptionJSON.subData.current_period_end * 1000).getTime() - new Date().getTime();
      return (diff / (1000 * 3600 * 24)).toFixed(0);
    } else return 0;
  }

  async getSubscription() {
    const responseGetSubscription = await fetch(apiBaseUrlLocal + "admin/payments/get-subscription", {
      method: "POST",
      body: new URLSearchParams({
        user_id: this.state.admin_data.admin_id,
        app_token: this.state.admin_data.token,
      }),
    });
    if (responseGetSubscription.status === 200) {
      const subData = await responseGetSubscription.json();
      this.setState({ subscriptionJSON: subData.data });
    }
  }

  async getShippingPlans() {
    const response = await loadShippingPlans(this.state.admin_data);
    console.log(response);
    response !== false && response.status === true && this.setState({ shippingPlans: response.data });
  }

  async loadTeams() {
    if (this.state.admin_data.access_level === 0 || this.state.admin_data.access_level === 1) {
      const teams = await loadTeams(this.state.admin_data);
      teams !== false &&
        this.setState({ teams: teams.data }, () => {
          const team_arr = this.state.teams.map((e, index) => ({
            value: index,
            label: e.team_name,
          }));
          console.log(team_arr);
          this.initialiseTable(team_arr);
        });
    } else {
      console.log("NOT SUPER");
    }
  }

  handleEditUser(row) {
    this.setState({ showEditModal: true, selectedUserToEdit: row });
  }

  async onDeleteUser(row) {
    console.log(row);
    this.setState({ isLoadingTableUsers: true });
    const response = await deleteUser(this.state.admin_data, row.id);
    console.log(response);
    if (response.status === "success") this.setState({ users: response.data, isLoadingTableUsers: false });
  }

  handleDeleteUser(row) {
    confirmAlert({
      title: "Delete User?",
      message: "This will permanently delete this user. Are you sure?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.onDeleteUser(row),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  }

  async initialiseTable(team_arr) {
    const actionFormatter = (cell, row, rowIndex, formatExtraData) => {
      console.log(`row`, row);
      return (
        <div>
          <Button
            size="sm"
            className="actionButtonLessPadding"
            variant="light"
            onClick={() => this.handleEditUser(row)}
          >
            Edit
          </Button>

          {row.id !== this.state.admin_data.admin_id && (
            <Button
              size="sm"
              className="actionButtonLessPadding"
              style={{ marginLeft: "8px" }}
              variant="danger"
              onClick={() => this.handleDeleteUser(row)}
            >
              Delete
            </Button>
          )}
        </div>
      );
    };

    const triggerBatchFormatter = (cell, row, rowIndex, formatExtraData) => {
      const indexInTriggerBatches = this.state.triggerBatches.findIndex((i) => {
        return i.id === row.trigger_batch;
      });
      if (indexInTriggerBatches >= 0)
        return (
          <div>
            <a
              href="#"
              onClick={() => {
                this.props.history.push({
                  pathname: `edit-trigger-batch/${this.state.triggerBatches[indexInTriggerBatches].id}`,
                  state: { triggerBatchName: this.state.triggerBatches[indexInTriggerBatches].name },
                });
              }}
              // href={`/edit-trigger-batch/${this.state.triggerBatches[indexInTriggerBatches].id}/${this.state.triggerBatches[indexInTriggerBatches].name}`}
            >
              {this.state.triggerBatches[indexInTriggerBatches].name}
            </a>
          </div>
        );
    };

    const booleanFormatter = (cell, row, rowIndex, formatExtraData) => {
      const toReturn = cell === 1 ? "YES" : "NO";
      return <div>{toReturn}</div>;
    };

    let tableColumns = [
      {
        dataField: "first_name",
        text: "F. Name",
        headerStyle: { cursor: "pointer", outline: "none" },
      },
      {
        dataField: "last_name",
        text: "L. Name",
        headerStyle: { cursor: "pointer", outline: "none" },
      },
      {
        dataField: "hidden_mode",
        text: "Hidden Mode?",
        headerStyle: { cursor: "pointer", outline: "none" },
        formatter: booleanFormatter,
      },
      {
        dataField: "user_email",
        text: "Email",
        headerStyle: { cursor: "pointer", outline: "none" },
      },
      {
        dataField: "trigger_batch",
        text: "Trigger Batch",
        headerStyle: { cursor: "pointer", outline: "none" },
        formatter: triggerBatchFormatter,
      },
      {
        dataField: "team_name",
        text: "Team Name",
        headerStyle: { cursor: "pointer", outline: "none" },
      },
      {
        dataField: "trigger_batch",
        text: "Actions",
        headerStyle: () => {
          return { width: "15%" };
        },
        formatter: actionFormatter,
      },
    ];
    this.state.admin_data.access_level === 1 && tableColumns.splice(5, 1);
    this.state.admin_data.access_level > 1 && tableColumns.splice(-2);
    this.setState({ columns: tableColumns });
  }

  async fetchUsersAndBatches() {
    this.setState({ isLoadingTableUsers: true });
    const response2 = await getTriggerBatches(this.state.admin_data);
    if ("error" in response2 && response2.error === "Invalid Credentials") this.handleLogout();
    this.setState({ triggerBatches: response2 });
    const response = await loadUsers(this.state.admin_data);
    if ("error" in response && response.error === "Invalid Credentials") this.handleLogout();
    if (response.status === "success") this.setState({ users: response.data, isLoadingTableUsers: false });
  }

  async onFormSubmitAddUser(event) {
    event.preventDefault();
    this.setState({ inProgressAdd: true });
    const response = await addUser(this.state.admin_data, event.target.elements);
    console.log(response);
    if (response.status === "success")
      this.setState({
        users: response.data,
        showAddModal: false,
        inProgressAdd: false,
      });
    else
      this.setState({
        invalidUserAddMessage:
          "message" in response &&
          "sqlMessage" in response.message &&
          response.message.sqlMessage.indexOf("Duplicate entry") >= 0
            ? "An account with this email address already exists! Please input a new email address."
            : "",
        inProgressAdd: false,
      });
  }

  onChangeProfilePictureFile = (e) => {
    if (e.target.files[0] !== undefined) {
      this.setState({ currentProfilePictureFile: e.target.files[0] });
    }
  };

  async addNewProfile(profileName) {
    this.setState({ addingNewProfile: true });
    const res = await uploadNewProfile(
      this.state.selectedUserToEdit.id,
      profileName,
      this.state.currentProfilePictureFile,
      this.state.admin_data.admin_id,
      this.state.admin_data.token
    );
    if ("status" in res && res.status === true) {
      let usersList = this.state.users;
      const currentUserIndex = usersList.findIndex((user) => user.id === this.state.selectedUserToEdit.id);
      console.log(usersList[currentUserIndex]);
      usersList[currentUserIndex].profiles = res.profiles;
      console.log(usersList[currentUserIndex]);

      this.setState({ selectedUserToEdit: usersList[currentUserIndex], users: usersList });
    }
    this.setState({ addingNewProfile: false });
  }

  async deleteExistingProfile(profileName) {
    this.setState({ deletingProfile: true });
    const res = await deleteProfile(
      this.state.selectedUserToEdit.id,
      profileName,
      this.state.admin_data.admin_id,
      this.state.admin_data.token
    );
    if ("status" in res && res.status === true) {
      let usersList = this.state.users;
      const currentUserIndex = usersList.findIndex((user) => user.id === this.state.selectedUserToEdit.id);
      console.log(usersList[currentUserIndex]);
      usersList[currentUserIndex].profiles = res.profiles;
      console.log(usersList[currentUserIndex]);

      this.setState({ selectedUserToEdit: usersList[currentUserIndex], users: usersList });
    }
    this.setState({ deletingProfile: false });
  }

  async onFormSubmitEditUser(fields) {
    this.setState({ inProgressEdit: true });
    fields.user_id = this.state.selectedUserToEdit.id;
    const response = await editUser(this.state.admin_data, fields);
    if (response.status === "success") this.setState({ users: response.data, showEditModal: false });
    this.setState({ inProgressEdit: false });
  }

  handleAddNewUser() {
    this.setState({ invalidUserAddMessage: "" });
    confirmAlert({
      title: "Notice",
      message: `Congrats on growing your team! Adding 1 new user will add $19 to your monthly payment starting next billing cycle. And an estimated pro-rated charge of $${(
        (19 / 31) *
        this.getDaysRemaining()
      ).toFixed(0)} will be applied today to your card on file. Would you like to proceed with adding this user?`,
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            this.setState({ showAddModal: true });
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  }

  render() {
    return (
      <React.Fragment>
        <TopMenu
          handlenavigationroutes={(routeName) => {
            // abortRequests();
            this.props.history.push(routeName);
          }}
          history={this.props.history}
          userData={this.state.admin_data}
          activeKey="/users"
          isReevaluate={
            "is_re_evaluate" in this.state.admin_data && this.state.admin_data.is_re_evaluate === 1 ? true : false
          }
          adminData={this.state.admin_data}
        />

        <ModalAdd
          teams={this.state.teams}
          invalidUserAddMessage={this.state.invalidUserAddMessage}
          shippingPlans={this.state.shippingPlans}
          triggerBatches={this.state.triggerBatches}
          adminData={this.state.admin_data}
          showAddModal={this.state.showAddModal}
          inProgressAdd={this.state.inProgressAdd}
          handleClose={() => this.setState({ showAddModal: false })}
          onFormSubmitAddUser={this.onFormSubmitAddUser}
        />

        <ModalEdit
          addingNewProfile={this.state.addingNewProfile}
          onChangeProfilePictureFile={this.onChangeProfilePictureFile}
          addNewProfile={this.addNewProfile}
          deleteExistingProfile={this.deleteExistingProfile}
          teams={this.state.teams}
          shippingPlans={this.state.shippingPlans}
          editUser={this.state.selectedUserToEdit}
          triggerBatches={this.state.triggerBatches}
          showEditModal={this.state.showEditModal}
          inProgressEdit={this.state.inProgressEdit}
          handleClose={() => this.setState({ showEditModal: false })}
          onFormSubmitEditUser={this.onFormSubmitEditUser}
        />

        <Container fluid={true}>
          <Row style={{ marginTop: "20px" }}>
            <Col md={12}></Col>
          </Row>
          <Row>
            <Col className="tabletoolkit" md={12}>
              <div>
                <ToolkitProvider keyField="id" data={this.state.users} columns={this.state.columns} search>
                  {(props) => (
                    <div>
                      <Row>
                        <Col md={6}>
                          <div className="div-searchbar">
                            <SearchBar className="search-bar dash_table_inputs" {...props.searchProps} />
                          </div>
                        </Col>
                        <Col md={6}>
                          <div style={{ float: "right" }}>
                            {(this.state.admin_data.access_level === 0 || this.state.admin_data.access_level === 1) && (
                              <Button
                                className="actionButtonLessPadding"
                                style={{ marginLeft: "8px" }}
                                variant="success"
                                onClick={() => this.handleAddNewUser()}
                              >
                                Add New User
                              </Button>
                            )}
                          </div>
                        </Col>
                      </Row>

                      <Row style={{ marginTop: "20px" }}>
                        <Col md={12}>
                          {this.state.isLoadingTableUsers === true ? (
                            <div style={{ textAlign: "center" }}>
                              <Spinner animation="border" role="status">
                                <span className="sr-only">Loading...</span>
                              </Spinner>
                            </div>
                          ) : (
                            <BootstrapTable
                              id="usersTable"
                              keyField="id"
                              noDataIndication={() => <div style={{ textAlign: "center" }}>No Data available</div>}
                              wrapperClasses="table-responsive"
                              {...props.baseProps}
                            />
                          )}
                        </Col>
                      </Row>
                    </div>
                  )}
                </ToolkitProvider>
              </div>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default Users;
