import { apiBaseUrlLocal } from "./AppConfig";

export const getTBConfiguration = async (admin_data, triggerBatchId) => {
  const response = await fetch(apiBaseUrlLocal + "tbatches/batch_configuration?batch_id=" + triggerBatchId, {
    method: "POST",
    body: new URLSearchParams({
      user_id: admin_data.admin_id,
      app_token: admin_data.token,
    }),
  });
  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else return false;
};

// export const updateCad2Usd = async (admin_data, triggerBatchId, cad2usd) => {
//   const response = await fetch(apiBaseUrlLocal + "admin/tbatches/updatecad2usd/" + triggerBatchId, {
//     method: "POST",
//     body: new URLSearchParams({
//       user_id: admin_data.admin_id,
//       app_token: admin_data.token,
//       cad2usd: cad2usd,
//     }),
//   });
//   if (response.status === 200) {
//     const data = await response.json();
//     return data;
//   } else return false;
// };

export const updateTBNameHelper = async (admin_data, triggerBatchId, newTBName) => {
  const response = await fetch(apiBaseUrlLocal + "admin/tbatches/updatename/" + triggerBatchId, {
    method: "POST",
    body: new URLSearchParams({
      user_id: admin_data.admin_id,
      app_token: admin_data.token,
      trigger_batch_name: newTBName,
    }),
  });
  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else return false;
};

export const checkIfMEnabled = (moduleName, batchConfiguration) => {
  if (moduleName in batchConfiguration && batchConfiguration[moduleName] !== null) {
    const config_module = JSON.parse(batchConfiguration[moduleName]);
    const index = config_module.findIndex(function (module) {
      return module.module_name === moduleName;
    });
    return config_module[index].enabled;
  }
  return false;
};

export const reconfigTB = async (admin_data, triggerBatchId, level, config) => {
  const response = await fetch(apiBaseUrlLocal + "tbatches/reconfig?batch_id=" + triggerBatchId, {
    method: "POST",
    body: new URLSearchParams({
      user_id: admin_data.admin_id,
      app_token: admin_data.token,
      level: level,
      config: config,
    }),
  });
  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else return false;
};

export const validateSBYBEmail = async (admin_data, sbyb_email) => {
  const response = await fetch(apiBaseUrlLocal + "admin/users/update-sbyb", {
    method: "POST",
    body: new URLSearchParams({
      user_id: admin_data.admin_id,
      app_token: admin_data.token,
      sbyb_email,
    }),
  });
  if (response.status === 200) {
    const data = await response.json();
    console.log(data);
    return data;
  } else return false;
};

export const updateVendorsInTB = async (admin_data, triggerBatchId, vendorsList) => {
  const response = await fetch(apiBaseUrlLocal + "admin/tbatches/bsvendors/" + triggerBatchId, {
    method: "POST",
    body: new URLSearchParams({
      user_id: admin_data.admin_id,
      app_token: admin_data.token,
      vendors_list: JSON.stringify(vendorsList),
    }),
  });
  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else return false;
};

export const processTabSequenceChange = async (
  updateTabs,
  module_name,
  admin_data,
  batchConfiguration,
  triggerbatch_id,
  updateTBConfig
) => {
  if (module_name in batchConfiguration && batchConfiguration[module_name] !== null) {
    const config_module = JSON.parse(batchConfiguration[module_name]);
    let new_config = [];
    updateTabs.forEach((tabInfo) => {
      const index = config_module.findIndex(function (module) {
        return tabInfo.title === module.module_name;
      });
      new_config.push(config_module[index]);
    });
    console.log(new_config);
    updateTBConfig(module_name, new_config);
    await reconfigTB(admin_data, triggerbatch_id, module_name, JSON.stringify(new_config));
    return new_config;
  }
};
