import React from "react";

/** The output from this component is what gets printed
 *  when user requests 2D bar code for a box.
 */

class PrintBoxBarcode extends React.Component {
  render() {
    return (
      <div className="barCodePrint">
        <div>
          <div style={{ float: "left", width: "33%" }}>Box {this.props.printMeta.boxIdCount}</div>
          <div style={{ float: "left", width: "33%", textAlign: "center" }}>
            Warehouse: {this.props.printMeta.destinationCenter}
          </div>
          <div style={{ float: "left", width: "33%", textAlign: "right" }}>
            {this.props.printMeta.totalItemsInBox} units shipped in box
          </div>
        </div>
        <div style={{ clear: "both" }}></div>
        <div style={{ textAlign: "center", marginTop: "20%" }}>
          <img alt="Bar Code" style={{ margin: "0 auto" }} src={this.props.b64} />
        </div>
        <div style={{ marginTop: "30%" }}>
          <div style={{ float: "left", width: "50%" }}>Shipment ID: {this.props.printMeta.shipmentId}</div>
          <div style={{ float: "left", width: "50%", textAlign: "right" }}>
            Est. Weight: {this.props.printMeta.totalWeightOfBox} lb.
          </div>
        </div>
      </div>
    );
  }
}

export default PrintBoxBarcode;
