import React, { Component, Fragment } from "react";
import { Form, Modal, Button, FormControl, Spinner, Table } from "react-bootstrap";
import Lottie from "react-lottie";
import animationData from "../../assets/animations/done.json";

class ModalEmailVendor extends Component {
  state = {
    isDone: false,
    containerType: "boxes",
    numberOfContainers: 1,
  };

  initiateModal() {
    this.setState({ isDone: false, containerType: "boxes", numberOfContainers: 1 });
    if (this.props.selectedShipment !== undefined) {
      if (this.props.selectedShipment.display_name === "ZIFFIT") this.setState({ containerType: "gaylords" });
    }
  }

  constructor() {
    super();
    this.onToggleContainerType = this.onToggleContainerType.bind(this);
    this.initiateModal = this.initiateModal.bind(this);
    this.submitEmailVendor = this.submitEmailVendor.bind(this);
  }

  onToggleContainerType(e) {
    this.setState({ containerType: e.target.value });
  }

  async submitEmailVendor(e) {
    e.preventDefault();

    const containerType = e.target.elements.containerType.value;
    const numberOfContainers = e.target.elements.numberOfContainers.value;
    const boxWeightsElements = e.target.elements["boxWeights[]"];
    const ziffitTradeId = "tradeID" in e.target.elements ? e.target.elements.tradeID.value : "";

    const emailBOL = "emailBOL" in e.target.elements ? e.target.elements.emailBOL.value : "";
    const isLimitedAccessFacility =
      "isLimitedAccessFacility" in e.target.elements ? e.target.elements.isLimitedAccessFacility.value : "";

    console.log(`boxWeightsElements`, boxWeightsElements.length);

    let boxWeights = [];

    if (boxWeightsElements.length !== undefined && boxWeightsElements.length >= 2)
      boxWeightsElements.forEach((bwe) => {
        boxWeights.push(bwe.value);
      });
    else boxWeights.push(boxWeightsElements.value);

    let contactName = "",
      contactPhone = "",
      addressForPickup = "",
      addressType = "",
      needALiftgate = "",
      availableHoursStart = "",
      availableHoursEnd = "",
      availableDate = "",
      emailForLabels = "";

    if (containerType === "pallets" || containerType === "gaylords") {
      contactName = e.target.elements.contactName.value;
      contactPhone = e.target.elements.contactPhone.value;
      addressForPickup = e.target.elements.addressForPickup.value;
      addressType = e.target.elements.addressType.value;
      needALiftgate = e.target.elements.needALiftgate.value;
      availableHoursStart = e.target.elements.availableHoursStart.value;
      availableHoursEnd = e.target.elements.availableHoursEnd.value;
      availableDate = e.target.elements.availableDate.value;
    } else {
      emailForLabels = e.target.elements.emailForLabels.value;
    }

    const formData = {
      ziffitTradeId,
      emailBOL,
      isLimitedAccessFacility,
      containerType,
      numberOfContainers,
      boxWeights,
      contactName,
      contactPhone,
      addressForPickup,
      addressType,
      needALiftgate,
      availableHoursStart,
      availableHoursEnd,
      availableDate,
      emailForLabels,
    };

    await this.props.submitShipmentData(formData);

    this.setState({ isDone: true });
  }

  render() {
    const defaultLottieOptions = {
      loop: false,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    return (
      <Modal
        onEnter={this.initiateModal}
        show={this.props.showModal}
        onHide={this.props.handleClose}
        dialogClassName="editModal"
      >
        <Modal.Header closeButton>
          <h3>
            {this.props.selectedShipment.display_name !== "ZIFFIT"
              ? `Send Email to ${this.props.selectedShipment.display_name}`
              : "Book Freight for Ziffit Shipments"}
          </h3>
        </Modal.Header>

        <Modal.Body>
          {this.state.isDone ? (
            <div>
              <Lottie options={defaultLottieOptions} height={200} width={200} />
              <div style={{ textAlign: "center", marginTop: "24px" }}>
                <p style={{ fontSize: "24px" }}>
                  {this.props.selectedShipment.display_name === "ZIFFIT" &&
                  this.props.selectedShipment.ziffit_trade_id !== null &&
                  this.props.selectedShipment.ziffit_trade_id !== ""
                    ? `Congrats!  Your shipment has been submitted and we’ve dispatched an army of minions to book freight for you.  Keep an eye on your email inbox for the Bill Of Lading (BOL) in the next day or two.`
                    : `Trade submitted successfully!`}
                </p>
              </div>
            </div>
          ) : (
            <Form inline onSubmit={this.submitEmailVendor}>
              <Table bordered>
                <tbody>
                  {this.props.selectedShipment.display_name === "ZIFFIT" &&
                    this.props.selectedShipment.ziffit_trade_id !== null &&
                    this.props.selectedShipment.ziffit_trade_id !== "" && (
                      <tr>
                        <td>Trade ID</td>
                        <td style={{ paddingLeft: "20px" }}>
                          <FormControl
                            disabled
                            name="tradeID"
                            inline
                            type="text"
                            value={this.props.selectedShipment.ziffit_trade_id}
                          ></FormControl>
                        </td>
                      </tr>
                    )}
                  <tr>
                    <td>Container Type</td>
                    <td style={{ paddingLeft: "20px" }}>
                      <FormControl
                        required
                        as="select"
                        onChange={this.onToggleContainerType}
                        name="containerType"
                        value={this.state.containerType}
                      >
                        {this.props.selectedShipment.display_name !== "ZIFFIT" ? (
                          <React.Fragment>
                            <option value="boxes">Boxes</option>
                            <option value="pallets">Pallets</option>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <option value="gaylords">Gaylords</option>
                          </React.Fragment>
                        )}
                      </FormControl>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      Number of {this.state.containerType.charAt(0).toUpperCase() + this.state.containerType.slice(1)}
                    </td>
                    <td style={{ paddingLeft: "20px" }}>
                      <Form.Control
                        required
                        min={1}
                        maxLength={3}
                        style={{ width: "100px" }}
                        type="number"
                        value={this.state.numberOfContainers}
                        name="numberOfContainers"
                        onChange={(e) => {
                          this.setState({ numberOfContainers: e.target.value });
                        }}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>
                      {this.state.containerType.charAt(0).toUpperCase() + this.state.containerType.slice(1)}' Weights
                    </td>
                    <td style={{ paddingLeft: "20px" }} colSpan={3}>
                      {Array.from({ length: this.state.numberOfContainers }, (_, k) => (
                        <FormControl
                          required
                          style={{ width: "80px", marginRight: "5px", marginBottom: "5px" }}
                          key={k}
                          inline
                          name="boxWeights[]"
                          type="numbers"
                          placeholder={`${
                            this.state.containerType === "boxes"
                              ? "Box"
                              : this.state.containerType === "pallets"
                              ? "Pallet"
                              : "G. "
                          } ${k + 1}`}
                        ></FormControl>
                      ))}
                    </td>
                  </tr>

                  {this.state.containerType === "boxes" ? (
                    <tr>
                      <td>Email Address</td>
                      <td style={{ paddingLeft: "20px" }}>
                        <FormControl required name="emailForLabels" inline type="text"></FormControl>
                      </td>
                    </tr>
                  ) : (
                    <Fragment>
                      <tr>
                        <td>Contact Name</td>
                        <td style={{ paddingLeft: "20px" }}>
                          <FormControl
                            defaultValue={
                              this.props.shipmentAddress !== null && "name" in this.props.shipmentAddress
                                ? this.props.shipmentAddress.name
                                : ""
                            }
                            required
                            name="contactName"
                            inline
                            type="text"
                          ></FormControl>
                        </td>
                      </tr>
                      <tr>
                        <td>Contact Phone</td>
                        <td style={{ paddingLeft: "20px" }}>
                          <FormControl
                            defaultValue={
                              this.props.shipmentAddress !== null && "phone" in this.props.shipmentAddress
                                ? this.props.shipmentAddress.phone
                                : ""
                            }
                            required
                            name="contactPhone"
                            inline
                            type="text"
                          ></FormControl>
                        </td>
                      </tr>
                      <tr>
                        <td>Address for Pickup</td>
                        <td style={{ paddingLeft: "20px" }}>
                          <Form.Control
                            required
                            defaultValue={
                              this.props.shipmentAddress !== null && "address" in this.props.shipmentAddress
                                ? this.props.shipmentAddress.address
                                : ""
                            }
                            name="addressForPickup"
                            as="textarea"
                            rows="3"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Address Type</td>
                        <td style={{ paddingLeft: "20px" }}>
                          <FormControl
                            required
                            name="addressType"
                            as="select"
                            defaultValue={
                              this.props.shipmentAddress !== null && "address_type" in this.props.shipmentAddress
                                ? this.props.shipmentAddress.address_type
                                : ""
                            }
                          >
                            <option value="residential">Residential</option>
                            <option value="commercial">Commercial</option>
                          </FormControl>
                        </td>
                      </tr>
                      <tr>
                        <td>Need a Liftgate</td>
                        <td style={{ paddingLeft: "20px" }}>
                          <FormControl required name="needALiftgate" as="select">
                            <option value="no">No</option>
                            <option value="yes">Yes</option>
                          </FormControl>
                        </td>
                      </tr>

                      {this.props.selectedShipment.display_name === "ZIFFIT" &&
                        this.props.selectedShipment.ziffit_trade_id !== null &&
                        this.props.selectedShipment.ziffit_trade_id !== "" && (
                          <Fragment>
                            <tr>
                              <td>Is this location a storage unit or a limited access facility?</td>
                              <td style={{ paddingLeft: "20px" }}>
                                <FormControl required name="isLimitedAccessFacility" as="select">
                                  <option value="no">No</option>
                                  <option value="yes">Yes</option>
                                </FormControl>
                              </td>
                            </tr>
                            <tr>
                              <td>Email Address where we can send the Bill of Lading</td>
                              <td style={{ paddingLeft: "20px" }}>
                                <FormControl
                                  defaultValue={
                                    this.props.shipmentAddress !== null && "email_bol" in this.props.shipmentAddress
                                      ? this.props.shipmentAddress.email_bol
                                      : ""
                                  }
                                  required
                                  name="emailBOL"
                                  inline
                                  type="email"
                                ></FormControl>
                              </td>
                            </tr>
                          </Fragment>
                        )}

                      <tr>
                        <td>Hours Available</td>
                        <td style={{ paddingLeft: "20px" }}>
                          {" "}
                          <FormControl required name="availableHoursStart" inline as="select">
                            {Array.from({ length: 24 }, (_, k) => (
                              <option value={k + ":00"}>{k + ":00"}</option>
                            ))}
                          </FormControl>
                          <span style={{ margin: "0 15px 0 15px" }}>TO</span>
                          <FormControl required name="availableHoursEnd" inline as="select">
                            {Array.from({ length: 24 }, (_, k) => (
                              <option value={k + ":00"}>{k + ":00"}</option>
                            ))}
                          </FormControl>
                        </td>
                      </tr>
                      <tr>
                        <td>Date Available</td>
                        <td style={{ paddingLeft: "20px" }}>
                          {" "}
                          <FormControl required name="availableDate" className="dash_table_inputs" type="date" />
                        </td>
                      </tr>
                    </Fragment>
                  )}
                </tbody>
              </Table>
              {this.props.inProcessSubmitData === true ? (
                <Spinner animation="border" role="status"></Spinner>
              ) : (
                <Button variant="primary" type="submit">
                  Submit Batch
                </Button>
              )}
            </Form>
          )}
        </Modal.Body>
      </Modal>
    );
  }
}

export default ModalEmailVendor;
