import React, { Component } from "react";
import TopMenu from "../components/TopMenu";
import { Row, Col, Container, Button, Table, Spinner, FormControl } from "react-bootstrap";
import ModalInvite from "../components/Others/ModalInvite";
import { sendInvitation, getInvitations, resendEmail, cancelInvite, saveNotes } from "../includes/HelperInvite";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import Cookies from "universal-cookie";
import ModalEditNotes from "../components/Others/ModalEditNotes";

const cookies = new Cookies();

class Invite extends Component {
  state = {
    showInviteModal: false,
    showEditNotesModal: false,
    admin_data: cookies.get("admin_data"),
    isInvitingNewUser: false,
    originalInviteList: [],
    inviteList: [],
    selectedInvite: {},
    isLoadingInviteList: false,
    isSavingNotes: false,
  };

  constructor() {
    super();
    this.onFormSubmitInviteUser = this.onFormSubmitInviteUser.bind(this);
    this.onFormSubmitSaveNotes = this.onFormSubmitSaveNotes.bind(this);
  }

  componentDidMount() {
    this.getPendingInvites();
  }

  async onFormSubmitSaveNotes(e) {
    e.preventDefault();

    this.setState({ isSavingNotes: true });
    const inviteListResponse = await saveNotes(
      this.state.admin_data,
      this.state.selectedInvite.user_id,
      e.target.elements.notes.value
    );
    if (inviteListResponse.status === true) {
      this.setState({
        originalInviteList: inviteListResponse.data,
        inviteList: inviteListResponse.data,
      });
    }

    this.setState({ isSavingNotes: false, showEditNotesModal: false });
  }

  async getPendingInvites() {
    this.setState({ isLoadingInviteList: true });
    const inviteListResponse = await getInvitations(this.state.admin_data);
    if (inviteListResponse.status === true) {
      this.setState({
        originalInviteList: inviteListResponse.data,
        inviteList: inviteListResponse.data,
        isLoadingInviteList: false,
      });
    } else {
      this.setState({ isLoadingInviteList: false });
    }
  }

  async onFormSubmitInviteUser(evt) {
    console.log("Sending new invite");
    evt.preventDefault();

    const teamEmail = evt.target.elements.teamEmail.value;
    const teamName = evt.target.elements.teamName.value;

    if (teamEmail !== "" && teamName !== "") {
      this.setState({ isInvitingNewUser: true });
      // Call API to add trigger
      const response = await sendInvitation(teamEmail, teamName, this.state.admin_data);
      if ("status" in response && response.status === true) {
        this.setState({ inviteList: response.data });
      }

      this.setState({
        showInviteModal: false,
        isInvitingNewUser: false,
      });
    }
  }

  async onHandleResendInvite(invite) {
    this.setState({ resendingEmail: true, resendingEmailTo: invite.user_id });
    await resendEmail(invite.user_id, this.state.admin_data);
    this.setState({ resendingEmail: false });
  }

  async handleCancelInvite(invite) {
    this.setState({ cancellingInvite: true, cancellingInviteFor: invite.user_id });
    const response = await cancelInvite(invite.user_id, this.state.admin_data);
    if ("status" in response && response.status === true) {
      this.setState({ inviteList: response.data });
    }
    this.setState({ cancellingInvite: false });
  }

  async onHandleCancelInvite(invite) {
    confirmAlert({
      title: `Cancel invitation for ${invite.team_name}?`,
      message: "This will remove the associated team and user information from the database",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.handleCancelInvite(invite),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  }

  render() {
    return (
      <React.Fragment>
        <TopMenu
          handlenavigationroutes={(routeName) => {
            // abortRequests();
            this.props.history.push(routeName);
          }}
          isReevaluate={
            "is_re_evaluate" in this.state.admin_data && this.state.admin_data.is_re_evaluate === 1 ? true : false
          }
          history={this.props.history}
          activeKey="/invite"
          adminData={this.state.admin_data}
        />

        <ModalEditNotes
          isSavingNote={this.state.isSavingNotes}
          onFormSubmitSaveNotes={this.onFormSubmitSaveNotes}
          selectedInvite={this.state.selectedInvite}
          showEditNotesModal={this.state.showEditNotesModal}
          handleClose={() => this.setState({ showEditNotesModal: false })}
        />

        <ModalInvite
          showInviteModal={this.state.showInviteModal}
          isInvitingNewUser={this.state.isInvitingNewUser}
          onChangeAudioFile={this.onChangeAudioFile}
          handleClose={() => this.setState({ showInviteModal: false })}
          onFormSubmitInviteUser={this.onFormSubmitInviteUser}
        />

        <Container fluid={true} style={{ marginTop: "15px" }}>
          <h2>Invites</h2>
          <Row style={{ marginTop: "20px" }}>
            <Col md={12}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div>
                  <Button
                    className="actionButtonLessPadding"
                    style={{ marginLeft: "0px" }}
                    variant="success"
                    onClick={() => this.setState({ showInviteModal: true })}
                  >
                    Invite New User
                  </Button>
                </div>
                <div style={{ paddingLeft: "20px" }}>
                  <FormControl
                    as="select"
                    onChange={(e) => {
                      const inviteList = this.state.originalInviteList.filter((invite) =>
                        e.target.value === "accepted"
                          ? invite.first_name !== null && invite.last_name !== null
                          : e.target.value === "pending"
                          ? invite.first_name === null && invite.last_name === null
                          : true
                      );
                      this.setState({ inviteList });
                    }}
                    style={{ borderRadius: "0.4em" }}
                  >
                    <option value="all">Show all</option>
                    <option value="pending">Show only pending</option>
                    <option value="accepted">Show only accepted</option>
                  </FormControl>
                </div>

                <div style={{ paddingLeft: "20px" }}>
                  Showing {this.state.inviteList.length} of {this.state.originalInviteList.length} records
                </div>
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: "20px" }}>
            <Col md={12}>
              <Table bordered className="table_triggers">
                <thead>
                  <tr>
                    <th style={{ textAlign: "center" }}>Joined?</th>
                    <th style={{ textAlign: "center" }}>Card on File?</th>
                    <th style={{ width: "200px" }}>Notes</th>
                    <th>Team Name</th>
                    <th>Email Address</th>
                    <th>Invited On</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.isLoadingInviteList === false ? (
                    this.state.inviteList.map((invite, _index) => {
                      return (
                        <tr>
                          <td style={{ textAlign: "center" }}>
                            <i
                              style={{
                                color: invite.first_name !== null ? "green" : "red",
                                fontSize: "20px",
                                fontWeight: "bold",
                              }}
                              className={`fa ${invite.first_name !== null ? "fa-check-circle" : "fa-times-circle"}`}
                            />
                          </td>
                          <td style={{ textAlign: "center" }}>
                            <i
                              style={{
                                color: invite.is_stripe === 1 ? "green" : "red",
                                fontSize: "20px",
                                fontWeight: "bold",
                              }}
                              className={`fa ${invite.is_stripe === 1 ? "fa-check-circle" : "fa-times-circle"}`}
                            />
                          </td>
                          <td
                            style={{ fontStyle: "italic", cursor: "pointer" }}
                            onClick={() => {
                              this.setState({ selectedInvite: invite, showEditNotesModal: true });
                            }}
                          >
                            {invite.notes}
                          </td>
                          <td>{invite.team_name}</td>
                          <td>{invite.user_email}</td>
                          <td>
                            {invite.invited_on !== null ? new Date(invite.invited_on).toISOString().slice(0, 10) : ""}
                          </td>
                          <td>
                            <Button
                              className="actionButtonLessPadding"
                              variant="primary"
                              onClick={() => this.onHandleResendInvite(invite, _index)}
                            >
                              {this.state.resendingEmail === true && this.state.resendingEmailTo === invite.user_id ? (
                                <Spinner
                                  animation="border"
                                  role="status"
                                  style={{ width: "15px", height: "15px" }}
                                ></Spinner>
                              ) : (
                                "Resending Invitation Email"
                              )}
                            </Button>
                            <Button
                              className="actionButtonLessPadding"
                              style={{ marginLeft: "15px" }}
                              variant="danger"
                              onClick={() => this.onHandleCancelInvite(invite, _index)}
                            >
                              Cancel Invitation
                            </Button>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="6" style={{ textAlign: "center" }}>
                        <Spinner animation="border" role="status">
                          <span className="sr-only">Loading...</span>
                        </Spinner>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default Invite;
