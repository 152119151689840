import React, { Component } from "react";
import { Form, Modal, Spinner, Button, Row, Col } from "react-bootstrap";
import "react-dates/initialize";
import { DateRangePicker, isInclusivelyBeforeDay } from "react-dates";
import Select from "react-select";
import "react-dates/lib/css/_datepicker.css";
import moment from "moment";
moment().format();

const winnerOptions = [
  "Amazon - FBA",
  "Amazon - MF",
  "Amazon New - FBA",
  "Amazon New - MF",
  "Marketplace",
  "Wholesale - SBYB",
  "Wholesale - ZIFFIT",
  "Bookscouter",
  "Rejected",
];

const reactSelectCustomStyles = {
  control: (styles) => ({ ...styles, backgroundColor: "white", height: "48px", borderRadius: "0.12em" }),
};

class ScansExport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      start_date: null,
      end_date: null,
      allSourceSelected: true,
      allUsersSelected: true,
      checkedCsvFields: [...winnerOptions],
      selectedSources: [],
      selectedUsers: [],
    };

    this.onFormSubmitExportCSV = this.onFormSubmitExportCSV.bind(this);
    this.handleSourcesChange = this.handleSourcesChange.bind(this);
    this.handleSelectedUsersChange = this.handleSelectedUsersChange.bind(this);
  }

  handleSourcesChange(selectedSources) {
    this.setState({ selectedSources });
  }
  handleSelectedUsersChange(selectedUsers) {
    this.setState({ selectedUsers });
  }

  onFormSubmitExportCSV(e) {
    e.preventDefault();
    this.props.onFormSubmitExportCSV(
      this.state.start_date,
      this.state.end_date,
      this.state.checkedCsvFields,
      this.state.selectedSources.map((selectedSource) => selectedSource.value),
      this.state.selectedUsers.map((selectedUser) => selectedUser.value)
    );
  }

  getFormattedDate(dt) {
    var dd = dt.getDate();
    var mm = dt.getMonth() + 1;

    var yyyy = dt.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }

    return yyyy + "-" + mm + "-" + dd;
  }

  render() {
    return (
      <React.Fragment>
        <Modal
          onEntered={this.handleOpen}
          show={this.props.showExportModal}
          onHide={this.props.handleClose}
          dialogClassName="editModal"
        >
          <Form onSubmit={this.onFormSubmitExportCSV}>
            <Modal.Header closeButton>
              <h3>Download previous scans</h3>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div style={{ color: "#a1a0a0", marginBottom: "10px" }}>Pick a date range</div>
                <div>
                  <DateRangePicker
                    required={true}
                    isOutsideRange={(day) => !isInclusivelyBeforeDay(day, moment())}
                    minimumNights={0}
                    max
                    startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                    startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                    endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                    endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                    onDatesChange={({ startDate, endDate }) =>
                      this.setState(
                        {
                          startDate,
                          endDate,
                          start_date: this.getFormattedDate(new Date(startDate)),
                          end_date: this.getFormattedDate(new Date(endDate)),
                        },
                        this.fetchStats
                      )
                    } // PropTypes.func.isRequired,
                    focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                    onFocusChange={(focusedInput) => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                  />
                </div>
              </div>

              <div style={{ marginTop: "20px", borderTop: "1px solid #ddd", paddingTop: "15px" }}>
                <div style={{ color: "#a1a0a0", marginBottom: "10px" }}>Routed to</div>
                <Row>
                  <Col md={4}>
                    <Form.Check
                      style={{ marginBottom: "10px", color: "red" }}
                      type="checkbox"
                      defaultChecked={true}
                      id={"check-uncheck"}
                      onChange={(e) => {
                        if (e.target.checked) this.setState({ checkedCsvFields: winnerOptions });
                        else this.setState({ checkedCsvFields: [] });
                      }}
                      label={`Check/Uncheck All`}
                    />
                  </Col>

                  {winnerOptions.map((vendor) => {
                    return (
                      <Col md={4}>
                        <Form.Check
                          checked={this.state.checkedCsvFields.indexOf(vendor) >= 0}
                          style={{ marginBottom: "10px" }}
                          type="checkbox"
                          // defaultChecked={true}
                          id={"check-" + vendor}
                          onChange={(e) => {
                            const checkedCsvFields = [...this.state.checkedCsvFields];
                            const vIndex = checkedCsvFields.indexOf(e.target.value);

                            if (e.target.checked) checkedCsvFields.push(e.target.value);
                            else checkedCsvFields.splice(vIndex, 1);
                            this.setState({ checkedCsvFields });
                          }}
                          label={vendor}
                          value={vendor}
                        />
                      </Col>
                    );
                  })}
                </Row>
              </div>

              <div style={{ marginTop: "20px", borderTop: "1px solid #ddd", paddingTop: "15px" }}>
                <div style={{ color: "#a1a0a0", marginBottom: "10px", display: "flex" }}>
                  <div>Selected Sources</div>
                  <div style={{ marginLeft: "15px" }}>
                    <Form.Check
                      style={{ marginBottom: "10px", color: "#3679F6" }}
                      type="checkbox"
                      checked={this.state.allSourceSelected}
                      id={"check-uncheck-sources"}
                      onChange={(e) => {
                        this.setState({ selectedSources: [], allSourceSelected: e.target.checked });
                        // if (e.target.checked) this.setState({ checkedCsvFields: winnerOptions });
                        // else this.setState({ checkedCsvFields: [] });
                      }}
                      label={`Include All`}
                    />
                  </div>
                </div>
                <Select
                  styles={reactSelectCustomStyles}
                  isMulti
                  isDisabled={this.state.allSourceSelected}
                  value={this.state.selectedSources}
                  onChange={this.handleSourcesChange}
                  options={this.props.sourcesData}
                  placeholder={
                    this.state.allSourceSelected === true
                      ? "Uncheck 'Include All' to enable this filter"
                      : this.props.isLoadingSources === true
                      ? "LOADING ..."
                      : "Select Sources"
                  }
                />
              </div>

              <div style={{ marginTop: "20px", borderTop: "1px solid #ddd", paddingTop: "15px" }}>
                <div style={{ color: "#a1a0a0", marginBottom: "10px", display: "flex" }}>
                  <div>Selected Users</div>
                  <div style={{ marginLeft: "15px" }}>
                    <Form.Check
                      style={{ marginBottom: "10px", color: "#3679F6" }}
                      type="checkbox"
                      checked={this.state.allUsersSelected}
                      id={"check-uncheck-users"}
                      onChange={(e) => {
                        this.setState({ selectedUsers: [], allUsersSelected: e.target.checked });
                      }}
                      label={`Include All`}
                    />
                  </div>
                </div>
                <Select
                  isMulti
                  isDisabled={this.state.allUsersSelected}
                  styles={reactSelectCustomStyles}
                  value={this.state.selectedUsers}
                  onChange={this.handleSelectedUsersChange}
                  options={this.props.users_list}
                  placeholder={
                    this.state.allUsersSelected === true
                      ? "Uncheck 'Include All' to enable this filter"
                      : this.props.isLoadingUsers === true
                      ? "LOADING ..."
                      : "Select Users"
                  }
                />
              </div>
            </Modal.Body>

            <Modal.Footer>
              {this.props.isExportingCSV === true ? (
                <Spinner animation="border" role="status"></Spinner>
              ) : (
                <Button variant="primary" type="submit">
                  Export
                </Button>
              )}
            </Modal.Footer>
          </Form>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ScansExport;
