import React, { Component } from "react";
import { loadTriggers, updateTrigger, addTrigger, deleteTrigger } from "../../../includes/HelperTriggers";
import { Container, Row, Col, Button, Spinner } from "react-bootstrap";
import { reconfigTB } from "../../../includes/HelperTriggerBatches";

import TableListTriggers from "../Modals/TriggersTextBooks/TableListTriggers";
//import ModalEditTrigger from "../Modals/TriggersHitList/ModalEditTrigger";
//import ModalAddTrigger from "../Modals/TriggersHitList/ModalAddTrigger";
import ModalAdd from "../Modals/TriggersTextBooks/ModalAdd";
import ModalEdit from "../Modals/TriggersTextBooks/ModalEdit";

class TextBookTriggers extends Component {
  state = {
    triggers: [],
    triggerRows: [],
    showEditModal: false,
    showAddModal: false,
    editTrigger: {},
    categoryList: [],
    triggerType: 401,
    triggerBatchId: -1,
    isLoading: false,
    isSavingTrigger: false,
    isTriggerEnabled: false,
    savingTriggerDifference: false,
    targetDiff: 0,
  };

  constructor() {
    super();
    this.onFormSubmitEditTrigger = this.onFormSubmitEditTrigger.bind(this);
    this.onFormSubmitAddTrigger = this.onFormSubmitAddTrigger.bind(this);
    this.onClickDeleteTrigger = this.onClickDeleteTrigger.bind(this);
    this.initiateLoadTriggers = this.initiateLoadTriggers.bind(this);
    this.handleTriggerToggle = this.handleTriggerToggle.bind(this);
    this.handleReConfig = this.handleReConfig.bind(this);
  }

  componentDidMount() {
    /**
     * This is what executes before everything when the page loads.
     * What we're doing here:
     * 1. calling API to load/set triggers
     */
    this.setState({ triggerBatchId: this.props.triggerBatchId }, this.initiateLoadTriggers);

    if ("conf_hitlist" in this.props.batchConfiguration && this.props.batchConfiguration.conf_hitlist !== null) {
      const config_hitlist = JSON.parse(this.props.batchConfiguration.conf_hitlist);
      this.setState({ config_hitlist });
      if ("enabled" in config_hitlist) {
        this.setState({
          isTriggerEnabled: config_hitlist.enabled,
          targetDiff: config_hitlist.target_diff,
        });
      }
    }
  }

  initiateLoadTriggers() {
    this.fetchTriggers();
  }

  /**
   * Reads Triggers from Datbase using our API and saves it to state.
   * Should we use Redux for this??
   */
  async fetchTriggers() {
    this.setState({ isLoading: true });
    const triggersList = await loadTriggers(this.props.admin_data, this.state.triggerBatchId, this.state.triggerType);
    this.setState({ triggers: triggersList, isLoading: false });
  }

  handleEditShow = (trigger, _index) => {
    let triggerClone = trigger;
    triggerClone.jsonData = JSON.parse(trigger.TriggerJson);

    this.setState({ showEditModal: true, editTrigger: triggerClone });
  };

  extractFields(e, customColor) {
    const seasonBumpPriceAvg = e.target.elements.seasonBumpPriceAvg.value;
    const valBumpPriceAvg = e.target.elements.valBumpPriceAvg.value;
    const seasonBumpPriceMedian = e.target.elements.seasonBumpPriceMedian.value;
    const valBumpPriceMedian = e.target.elements.valBumpPriceMedian.value;
    const seasonUsedBuyBox = e.target.elements.seasonUsedBuyBox.value;
    const valUsedBuyBox = e.target.elements.valUsedBuyBox.value;
    const seasonBumpCount = e.target.elements.seasonBumpCount.value;
    const valBumpCount = e.target.elements.valBumpCount.value;
    const targetPrice = e.target.elements.targetPrice.value;
    // const isAlwaysReject = e.target.elements.isAlwaysReject.checked;

    const triggerJson = {
      customColor,
      seasonBumpPriceAvg,
      valBumpPriceAvg,
      seasonBumpPriceMedian,
      valBumpPriceMedian,
      seasonUsedBuyBox,
      valUsedBuyBox,
      seasonBumpCount,
      valBumpCount,
      targetPrice,
      // isAlwaysReject,
    };

    return triggerJson;
  }

  /**
   * Gets called when user submits 'Edit Trigger' form from the Modal Dialog.
   * @param {formparameters} event
   */
  async onFormSubmitEditTrigger(e, customColor = "") {
    e.preventDefault();

    // Gather required fields
    const triggerId = this.state.editTrigger.Id;
    const triggerName = e.target.elements.triggerName.value;
    const triggerJson = this.extractFields(e, customColor);
    console.log({ triggerJson });

    this.setState({ isSavingTrigger: true });

    // Call API to update trigger
    const response = await updateTrigger(
      this.props.admin_data,
      this.state.triggerBatchId,
      triggerId,
      triggerName,
      triggerJson,
      this.state.triggerType
    );
    response !== false && response.status === "success" && this.setState({ triggers: response.data });
    this.setState({ showEditModal: false, isSavingTrigger: false });
  }

  /**
   * Gets called when user submits 'Add Trigger' form from the Modal Dialog.
   * @param {formparameters} event
   */
  async onFormSubmitAddTrigger(e, customColor = "") {
    e.preventDefault();
    const triggerName = e.target.elements.triggerName.value;
    const triggerJson = this.extractFields(e, customColor);
    console.log({ triggerJson });

    this.setState({ isSavingTrigger: true });

    // // Call API to add trigger
    const response = await addTrigger(
      this.props.admin_data,
      this.state.triggerBatchId,
      triggerName,
      triggerJson,
      this.state.triggerType
    );
    response !== false && response.status === "success" && this.setState({ triggers: response.data });
    this.setState({ isSavingTrigger: false, showAddModal: false });
  }

  async onClickDeleteTrigger(trigger, _index) {
    // Gather required fields
    const triggerId = trigger.Id;
    console.log(triggerId);
    // Call API to delete trigger
    const response = await deleteTrigger(
      this.props.admin_data,
      this.state.triggerBatchId,
      triggerId,
      this.state.triggerType
    );
    response !== false && response.status === "success" && this.setState({ triggers: response.data });
    this.setState({ showAddModal: false });
  }

  handleTriggerToggle(evt) {
    this.setState({ isTriggerEnabled: evt.target.checked });
  }
  saveTargetDifference(evt) {
    console.log("Hi");
  }

  async handleReConfig() {
    let newConfig = {};
    const targetDiff = this.state.targetDiff;
    if (targetDiff >= 0) {
      newConfig.enabled = true;
      newConfig.target_diff = targetDiff;
      this.setState({ isTriggerEnabled: true });
    } else {
      newConfig.enabled = false;
      this.setState({ isTriggerEnabled: false });
    }

    await reconfigTB(this.props.admin_data, this.props.triggerBatchId, "conf_hitlist", JSON.stringify(newConfig));
  }

  render() {
    let isMEnabled = false;
    if ("high_level" in this.props.batchConfiguration && this.props.batchConfiguration.high_level !== null) {
      const config_high_level = JSON.parse(this.props.batchConfiguration.high_level);
      const index = config_high_level.findIndex(function (module) {
        return module.module_name === "Textbook";
      });
      isMEnabled = config_high_level[index].enabled;
    }

    return (
      <React.Fragment>
        <ModalEdit
          isSavingTrigger={this.state.isSavingTrigger}
          premiumLevel={this.props.admin_data.premium_level}
          showEditModal={this.state.showEditModal}
          handleClose={() => this.setState({ showEditModal: false })}
          onFormSubmitEditTrigger={this.onFormSubmitEditTrigger}
          editTrigger={this.state.editTrigger}
        />

        <ModalAdd
          isSavingTrigger={this.state.isSavingTrigger}
          premiumLevel={this.props.admin_data.premium_level}
          showAddModal={this.state.showAddModal}
          handleClose={() => this.setState({ showAddModal: false })}
          onFormSubmitAddTrigger={this.onFormSubmitAddTrigger}
        />

        {isMEnabled === true && (
          <Container fluid={true} style={{ padding: "0" }}>
            <Row>
              <Col md={12}>
                This Textbook module helps you identify books that exhibit seasonality with increased demand during the
                months of January and August. Use it to set aside textbooks to avoid selling them too cheap in the
                offseason, and also identify books that may not be worth selling now but are likely to increase in price
                within a few months!
              </Col>
            </Row>

            <Row style={{ paddingTop: "10px", borderTop: "1px solid #ddd", marginTop: "10px" }}>
              <Col md={3}></Col>

              {this.state.isTriggerEnabled === true && (
                <Col md={9}>
                  <div style={{ textAlign: "right", marginTop: "5px" }}>
                    <Button
                      className="actionButtonLessPadding"
                      style={{ marginLeft: "8px" }}
                      variant="success"
                      onClick={() => this.setState({ showAddModal: true })}
                    >
                      Add New Textbook Trigger
                    </Button>
                  </div>
                </Col>
              )}
            </Row>

            {this.state.isTriggerEnabled === true && (
              <Row style={{ marginTop: "20px" }}>
                <Col md={12}>
                  {this.state.isLoading ? (
                    <div style={{ textAlign: "center", paddingTop: "100px" }}>
                      <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                    </div>
                  ) : (
                    <TableListTriggers
                      triggers={this.state.triggers}
                      handleEditShow={this.handleEditShow}
                      deleteTrigger={this.onClickDeleteTrigger}
                    />
                  )}
                </Col>
              </Row>
            )}
          </Container>
        )}
      </React.Fragment>
    );
  }
}

export default TextBookTriggers;
