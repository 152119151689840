import React, { Component } from "react";
import { Row, Col, Form, Modal, Button, Spinner } from "react-bootstrap";

class EditPlan extends Component {
  state = {
    currentCSVFile: null,
    taText: "",
  };

  constructor() {
    super();
    this.onFormSubmitEditShippingPlan = this.onFormSubmitEditShippingPlan.bind(this);
    this.onChangeCSVFile = this.onChangeCSVFile.bind(this);
    this.handleChangeTaText = this.handleChangeTaText.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
  }

  handleOpen() {
    if ("plan_json" in this.props.currentEditPlan && this.props.currentEditPlan.plan_json !== "") {
      const recs = JSON.parse(this.props.currentEditPlan.plan_json);
      console.log(recs);
      let parsedRecs = "";
      for (let i = 0; i < recs.length; i++) {
        parsedRecs += recs[i].max_weight + " " + recs[i].shipcost + "\n";
      }
      this.setState({ taText: parsedRecs });
    }
  }

  async onFormSubmitEditShippingPlan(event) {
    event.preventDefault();

    // shippingPlanName required fields
    const shippingPlanName = event.target.elements.shippingPlanName.value;

    new Promise((resolve, reject) => {
      let asins = [];
      if (this.state.currentCSVFile !== null) {
        var reader = new FileReader();
        reader.onload = function (e) {
          const csvLines = e.target.result.split("\n");
          for (let i = 1; i < csvLines.length; i++) {
            const csvValues = csvLines[i].split(",");
            csvValues.length === 2 &&
              csvValues[0] !== "" &&
              csvValues[0] !== undefined &&
              csvValues[1] !== "" &&
              csvValues[1] !== undefined &&
              asins.push({ max_weight: csvValues[0], shipcost: csvValues[1] });
          }
          resolve(asins);
        };
        reader.readAsText(this.state.currentCSVFile);
      } else resolve(asins);
    }).then((res) => {
      const finalArray = this.state.taText
        .split("\n")
        .filter((e1) => e1 !== "")
        .map((rec) => {
          const weightPrice = rec.split(/\s+/);
          return { max_weight: weightPrice[0], shipcost: weightPrice[1] };
        });

      console.log(finalArray);
      const shippingPlanData = res.concat(finalArray);
      this.props.onFormSubmitEditShippingPlan(shippingPlanName, shippingPlanData);
      //this.props.onFormSubmitAddTrigger(triggerName, hitListTarget, isbnList);
    });
  }

  onChangeCSVFile = (e) => {
    if (e.target.files[0] !== undefined) {
      this.setState({ currentCSVFile: e.target.files[0] });
    }
  };

  handleChangeTaText = (e) => {
    this.setState({ taText: e.target.value });
  };

  render() {
    return (
      <Modal
        onEntered={this.handleOpen}
        show={this.props.showEditModal}
        onHide={this.props.handleClose}
        dialogClassName="editModal"
      >
        <Form onSubmit={this.onFormSubmitEditShippingPlan}>
          <Modal.Header closeButton>
            <Form.Control
              required
              type="text"
              name="shippingPlanName"
              className="input-text-trigger-name"
              defaultValue={this.props.currentEditPlan.plan_name}
              placeholder="Enter Shipping Plan Name Here"
            />
          </Modal.Header>
          <Modal.Body>
            <div style={{ fontSize: "13px", fontWeight: "500" }}>
              <Row>
                <Col md={12}>
                  <Row>
                    <Col md={12}>
                      <div>
                        <Form.Group controlId="formAudioFile">
                          <Form.Label>Choose .csv File</Form.Label>
                          <Form.Control
                            onChange={this.onChangeCSVFile}
                            type="file"
                            name="csvFile"
                            placeholder="Choose .csv File"
                          />
                        </Form.Group>
                      </div>
                      <div>
                        <div style={{ padding: "0 0 10px 0" }}>AND/OR</div>
                        <Form.Group controlId="exampleForm.ControlTextarea1">
                          <Form.Label>
                            Enter in format: MaxWeight PRICE. E.g. <strong>1.06 10.50</strong> separated by new line
                          </Form.Label>
                          <Form.Control
                            value={this.state.taText}
                            as="textarea"
                            rows="8"
                            onChange={this.handleChangeTaText}
                          />
                        </Form.Group>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {this.props.isProcessingApi === true ? (
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            ) : (
              <Button variant="primary" type="submit">
                Save Changes
              </Button>
            )}
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

export default EditPlan;
