import React, { Component } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { Tabs, DragTabList, DragTab, PanelList, Panel } from "react-tabtab";
import { simpleSwitch } from "react-tabtab/lib/helpers/move";
// import * as customStyle from "react-tabtab/lib/themes/bulma";
import * as css2 from "./WholeSaleTabStyle";
// import RMSTrigger from "../Level2/RMSTrigger";
import SBYBTrigger from "../Level2/SBYBTrigger";
// import BRTrigger from "../Level2/BRTrigger";
// import BRBTrigger from "../Level2/BRBTrigger";
// import SBTrigger from "../Level2/SBTrigger";
import ZIFFITTrigger from "../Level2/ZIFFITTrigger";
// import UBBTrigger from "../Level2/UBBTrigger";
import { reconfigTB, processTabSequenceChange } from "../../../includes/HelperTriggerBatches";

class WholeSaleTriggers extends Component {
  state = {
    activeIndex: 0,
    isUpdatingSkipVal: false,
  };

  constructor(props) {
    super(props);
    this.handleTabSequenceChange = this.handleTabSequenceChange.bind(this);
  }

  getTabs() {
    let tabs = [
      {
        title: `SBYB`,
        content: (
          <SBYBTrigger
            updateTBConfig={this.props.updateTBConfig}
            batchConfiguration={this.props.batchConfiguration}
            admin_data={this.props.admin_data}
            triggerBatchId={this.props.triggerBatchId}
          />
        ),
      },
      {
        title: `ZIFFIT`,
        content: (
          <ZIFFITTrigger
            updateTBConfig={this.props.updateTBConfig}
            batchConfiguration={this.props.batchConfiguration}
            admin_data={this.props.admin_data}
            triggerBatchId={this.props.triggerBatchId}
          />
        ),
      },
    ];

    let tabsInOrder = [];

    const wholeSaleConfiguration = this.props.batchConfiguration.conf_wholesale
      ? JSON.parse(this.props.batchConfiguration.conf_wholesale)
      : [];

    wholeSaleConfiguration.forEach((tabName) => {
      var index = tabs.findIndex(function (tab) {
        return tab.title === tabName.module_name;
      });

      if (index >= 0) tabsInOrder.push(tabs.splice(index, 1)[0]);
    });

    tabsInOrder = tabsInOrder.concat(tabs);
    return tabsInOrder;
  }

  async updateWholesaleConfig(key, val) {
    if (this.props.batchConfiguration.high_level) {
      let config_high_level = JSON.parse(this.props.batchConfiguration.high_level);
      const index = config_high_level.findIndex(function (module) {
        return module.module_name === "WholeSale";
      });
      config_high_level[index][key] = val;

      this.props.updateTBConfig("high_level", config_high_level);
      await reconfigTB(
        this.props.admin_data,
        this.props.triggerBatchId,
        "high_level",
        JSON.stringify(config_high_level)
      );
    }
  }

  async handleTabSequenceChange({ oldIndex, newIndex }) {
    const updateTabs = simpleSwitch(this.getTabs(), oldIndex, newIndex);
    this.setState({ activeIndex: newIndex });
    await processTabSequenceChange(
      updateTabs,
      "conf_wholesale",
      this.props.admin_data,
      this.props.batchConfiguration,
      this.props.triggerBatchId,
      this.props.updateTBConfig
    );
  }

  render() {
    const { activeIndex } = this.state;
    const tabs = this.getTabs();

    const tabsTemplate = [];
    const panelTemplate = [];
    tabs.forEach((tab, index) => {
      tabsTemplate.push(<DragTab key={index}>{tab.title}</DragTab>);
      panelTemplate.push(<Panel key={index}>{tab.content}</Panel>);
    });

    let isMEnabled = false,
      isSkipEscore = false,
      isSkipSalesRank = false,
      skipMinEscore = -1,
      skipMaxSalesRank = -1;

    if (this.props.batchConfiguration.high_level) {
      const config_high_level = JSON.parse(this.props.batchConfiguration.high_level);
      const index = config_high_level.findIndex(function (module) {
        return module.module_name === "WholeSale";
      });
      isMEnabled = config_high_level[index].enabled;
      isSkipEscore = config_high_level[index].isSkipEscore;
      skipMinEscore = config_high_level[index].skipMinEscore;

      isSkipSalesRank = config_high_level[index].isSkipSalesRank;
      skipMaxSalesRank = config_high_level[index].skipMaxSalesRank;
    }

    return (
      <React.Fragment>
        {isMEnabled === true && (
          <Container fluid={true} style={{ padding: "0" }}>
            <Row>
              <Col md={12}>
                <div style={{ paddingBottom: "20px", borderBottom: "1px solid #ccc", marginBottom: "10px" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Form.Check
                      checked={isSkipEscore}
                      type="checkbox"
                      label="Skip Wholesale triggers when eScore is greater than"
                      id={"check-skip-wholesale-escore"}
                      onChange={(e) => {
                        this.updateWholesaleConfig("isSkipEscore", e.target.checked);
                      }}
                    />
                    <div style={{ marginLeft: "10px" }}>
                      <Form
                        onSubmit={async (e) => {
                          this.setState({ isUpdatingSkipVal: true });
                          e.preventDefault();
                          e.target.elements.skipVal.value &&
                            (await this.updateWholesaleConfig("skipMinEscore", e.target.elements.skipVal.value));
                          this.setState({ isUpdatingSkipVal: false });
                        }}
                      >
                        <Form.Control
                          required
                          disabled={!isSkipEscore || this.state.isUpdatingSkipVal}
                          type="number"
                          step="1"
                          name="skipVal"
                          defaultValue={skipMinEscore}
                          onKeyPress={this.handleTargetChangeKeyPress}
                          style={{
                            width: "80px",
                            borderRadius: "0",
                            border: "none",
                            textAlign: "center",
                            borderBottom: "1px solid #000",
                          }}
                        />
                      </Form>
                    </div>
                  </div>

                  <div
                    style={{
                      marginTop: "20px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Form.Check
                      checked={isSkipSalesRank}
                      type="checkbox"
                      id={"check-skip-wholesale-rank"}
                      label="Skip Wholesale triggers when Sales Rank is lower than"
                      onChange={(e) => {
                        this.updateWholesaleConfig("isSkipSalesRank", e.target.checked);
                      }}
                    />
                    <div style={{ marginLeft: "10px" }}>
                      <Form
                        onSubmit={async (e) => {
                          this.setState({ isUpdatingSkipVal: true });
                          e.preventDefault();
                          e.target.elements.skipVal.value &&
                            (await this.updateWholesaleConfig("skipMaxSalesRank", e.target.elements.skipVal.value));
                          this.setState({ isUpdatingSkipVal: false });
                        }}
                      >
                        <Form.Control
                          disabled={!isSkipSalesRank || this.state.isUpdatingSkipVal}
                          required
                          type="number"
                          step="1"
                          name="skipVal"
                          defaultValue={skipMaxSalesRank || 1000000}
                          style={{
                            width: "150px",
                            borderRadius: "0",
                            border: "none",
                            textAlign: "center",
                            borderBottom: "1px solid #000",
                          }}
                        />
                      </Form>
                    </div>
                  </div>

                  <div style={{ marginTop: "20px", fontSize: "13px", fontStyle: "italic", color: "#3679F6" }}>
                    Note: Press <span style={{ fontWeight: "bold" }}>ENTER KEY</span> to save values above.
                  </div>
                </div>

                <div style={{ marginTop: "20px" }}>
                  <Tabs
                    showModalButton={false}
                    customStyle={css2}
                    activeIndex={activeIndex}
                    onTabChange={(index) => this.setState({ activeIndex: index })}
                    onTabSequenceChange={this.handleTabSequenceChange}
                  >
                    <DragTabList>{tabsTemplate}</DragTabList>
                    <PanelList>{panelTemplate}</PanelList>
                  </Tabs>
                </div>
              </Col>
            </Row>
          </Container>
        )}
      </React.Fragment>
    );
  }
}

export default WholeSaleTriggers;
