import React, { Component } from "react";
import { Container, Row, Col, Form, InputGroup, Button, Spinner } from "react-bootstrap";
import { Tabs, PanelList, Panel, TabList, Tab } from "react-tabtab";
import * as customStyleMD from "react-tabtab/lib/themes/material-design";
import AmazonCatTriggers from "./AmzonCatTriggers";

class AmzCATriggers extends Component {
  state = {
    activeIndex: 0,
  };

  render() {
    let isMEnabled = false,
      cad2usd = 0.79,
      inShipRate = 0.4;

    if ("high_level" in this.props.batchConfiguration && this.props.batchConfiguration.high_level !== null) {
      const config_high_level = JSON.parse(this.props.batchConfiguration.high_level);
      const index = config_high_level.findIndex(function (module) {
        return module.module_name === "Amazon CA";
      });
      isMEnabled = config_high_level[index].enabled;
      cad2usd = "cad2usd" in config_high_level[index] ? config_high_level[index].cad2usd : 0.79;
      inShipRate = "inShipRate" in config_high_level[index] ? config_high_level[index].inShipRate : 0.4;
    }
    return (
      <React.Fragment>
        {isMEnabled === true && (
          <Container fluid={true} style={{ padding: "0" }}>
            {/* <Row style={{ marginBottom: "20px" }}>
              <Col md={6}>
                <Form onSubmit={this.props.updateCAD2USD}>
                  <InputGroup id="ig_triname">
                    <InputGroup.Prepend>
                      <div style={{ paddingTop: "8px", paddingRight: "10px" }}>1 CAD = USD</div>
                    </InputGroup.Prepend>
                    <Form.Control
                      disabled={this.props.reconfigInProgress}
                      defaultValue={cad2usd}
                      required
                      type="number"
                      step="any"
                      name="cad2usd"
                      placeholder="For a number e.g. 0.71"
                    />
                    <InputGroup.Append>
                      <Button type="submit" variant="primary" disabled={this.props.reconfigInProgress}>
                        {this.props.reconfigInProgress === true ? (
                          <Spinner style={{ width: "20px", height: "20px" }} animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                          </Spinner>
                        ) : (
                          <span>Update</span>
                        )}
                      </Button>
                    </InputGroup.Append>
                  </InputGroup>
                </Form>
              </Col>
            </Row> */}

            <div style={{ marginBottom: "25px" }}>
              <Form onSubmit={this.props.updateCAD2USD}>
                <div style={{ float: "left", padding: "5px 5px 5px 0" }}>1 CAD =</div>
                <div style={{ float: "left" }}>
                  <Form.Control
                    disabled={this.props.reconfigInProgress}
                    required
                    type="number"
                    step="0.01"
                    name="cad2usd"
                    max={100}
                    style={{
                      width: "80px",
                      borderRadius: "0",
                      border: "none",
                      borderBottom: "1px solid #ccc",
                    }}
                    defaultValue={cad2usd}
                  />
                </div>
                <div style={{ float: "left", padding: "5px 0 5px 0" }}> USD</div>
                <div style={{ float: "left", paddingLeft: "20px" }}>
                  <Button type="submit" variant="primary" disabled={this.props.reconfigInProgress}>
                    {this.props.reconfigInProgress === true ? (
                      <Spinner style={{ width: "20px", height: "20px" }} animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                    ) : (
                      <span>Update</span>
                    )}
                  </Button>
                </div>
                <div style={{ clear: "both" }}></div>
              </Form>
            </div>

            <Row style={{ marginBottom: "20px" }}>
              <Col md={6}>
                <Form onSubmit={this.props.updateInShipRate}>
                  <InputGroup id="ig_triname">
                    <InputGroup.Prepend>
                      <div style={{ paddingTop: "8px", paddingRight: "10px" }}>Per KG inbound shipping rate</div>
                    </InputGroup.Prepend>
                    <Form.Control
                      disabled={this.props.reconfigInProgress}
                      defaultValue={inShipRate}
                      required
                      type="number"
                      step="any"
                      name="inShipRate"
                      placeholder="For a number e.g. 0.40"
                    />
                    <InputGroup.Append>
                      <Button type="submit" variant="primary" disabled={this.props.reconfigInProgress}>
                        {this.props.reconfigInProgress === true ? (
                          <Spinner style={{ width: "20px", height: "20px" }} animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                          </Spinner>
                        ) : (
                          <span>Update</span>
                        )}
                      </Button>
                    </InputGroup.Append>
                  </InputGroup>
                </Form>
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <Tabs customStyle={customStyleMD} showModalButton={false}>
                  <TabList>
                    <Tab>
                      <i className="fa fa-book" />
                      &nbsp; Books
                    </Tab>
                    <Tab>
                      <i className="fa fa-music" />
                      &nbsp; Music
                    </Tab>
                    <Tab>
                      <i className="fa fa-video-camera" />
                      &nbsp; Video
                    </Tab>
                    <Tab>
                      <i className="fa fa-gamepad" />
                      &nbsp; Video Games
                    </Tab>
                  </TabList>
                  <PanelList>
                    <Panel>
                      <AmazonCatTriggers
                        batchConfiguration={this.props.batchConfiguration}
                        triggersType={"conf_amazon_ca_books"}
                        updateTBConfig={this.props.updateTBConfig}
                        admin_data={this.props.admin_data}
                        triggerBatchId={this.props.triggerBatchId}
                      />
                    </Panel>
                    <Panel>
                      <AmazonCatTriggers
                        batchConfiguration={this.props.batchConfiguration}
                        triggersType={"conf_amazon_ca_music"}
                        updateTBConfig={this.props.updateTBConfig}
                        admin_data={this.props.admin_data}
                        triggerBatchId={this.props.triggerBatchId}
                      />
                    </Panel>
                    <Panel>
                      <AmazonCatTriggers
                        batchConfiguration={this.props.batchConfiguration}
                        triggersType={"conf_amazon_ca_video"}
                        updateTBConfig={this.props.updateTBConfig}
                        admin_data={this.props.admin_data}
                        triggerBatchId={this.props.triggerBatchId}
                      />
                    </Panel>
                    <Panel>
                      <AmazonCatTriggers
                        batchConfiguration={this.props.batchConfiguration}
                        triggersType={"conf_amazon_ca_video_games"}
                        updateTBConfig={this.props.updateTBConfig}
                        admin_data={this.props.admin_data}
                        triggerBatchId={this.props.triggerBatchId}
                      />
                    </Panel>
                  </PanelList>
                </Tabs>
              </Col>
            </Row>
          </Container>
        )}
      </React.Fragment>
    );
  }
}

export default AmzCATriggers;
