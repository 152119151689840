/**
 * Helper Class; contains functions mostly associated with API calls.
 */
import { apiBaseUrlLocalBoxContent } from "./AppConfig";

export const loadShipments = async (user_id, app_token) => {
  const response = await fetch(apiBaseUrlLocalBoxContent + "shipments/list_working", {
    method: "POST",
    body: new URLSearchParams({
      user_id,
      app_token,
    }),
  });
  const data = await response.json();
  return data.data;
};

export const getCurrentPrinter = async (user_id, app_token) => {
  const response = await fetch(apiBaseUrlLocalBoxContent + "shipments/list_working", {
    method: "POST",
    body: new URLSearchParams({
      user_id,
      app_token,
    }),
  });
  const data = await response.json();
  return data.data;
};

export const loadSKUsInShipment = async (user_id, app_token, shipment_id) => {
  console.log("Fetching items for shipment #" + shipment_id);
  const responseShipments = await fetch(apiBaseUrlLocalBoxContent + "shipments/fetch_items", {
    method: "POST",
    body: new URLSearchParams({
      user_id,
      app_token,
      shipment_ids: shipment_id,
    }),
  });
  const dataSkuQuantInShipment = await responseShipments.json();
  return dataSkuQuantInShipment;
};

export const resolveSKUs = async (user_id, app_token, SKUList) => {
  const response = await fetch(apiBaseUrlLocalBoxContent + "shipments/resolve_skus", {
    method: "POST",
    body: new URLSearchParams({
      user_id,
      app_token,
      seller_skus: SKUList,
    }),
  });
  const itemsforShipment = await response.json();
  return itemsforShipment;
};

export const itemDetail = async (user_id, app_token, SKU) => {
  const response = await fetch(apiBaseUrlLocalBoxContent + "shipments/item_detail", {
    method: "POST",
    body: new URLSearchParams({
      user_id,
      app_token,
      seller_sku: SKU,
    }),
  });
  const itemsDetail = await response.json();
  return itemsDetail;
};

export const uploadState = (user_id, app_token, stateShipments, stateActiveItems, callback) => {
  fetch(apiBaseUrlLocalBoxContent + "user/update_state", {
    method: "POST",
    body: new URLSearchParams({
      user_id,
      app_token,
      shipments_state: JSON.stringify(stateShipments),
      active_items_state: JSON.stringify(stateActiveItems),
    }),
  }).then((response) => {
    response.json().then(function (data) {
      callback(data);
    });
  });
};

export const fetchUser = async (user_id, app_token) => {
  const response = await fetch("https://boxc-api.lavneet.com/v1/user/fetch", {
    method: "POST",
    body: new URLSearchParams({
      user_id,
      app_token,
    }),
  });
  const data = await response.json();
  if (data.data !== undefined) return data.data[0];
  else return false;
};

export const updatePrinter = async (
  user_id,
  app_token,
  printerName,
  FNSKUWidth,
  FNSKUHeight,
  FNSKUFontSize,
  barCodeHeight
) => {
  const response = await fetch("https://boxc-api.lavneet.com/v1/user/update_printer", {
    method: "POST",
    body: new URLSearchParams({
      user_id,
      app_token,
      printer_name: printerName,
      fnsku_width: FNSKUWidth,
      fnsku_height: FNSKUHeight,
      barcode_height: barCodeHeight,
      fnsku_font_size: FNSKUFontSize,
    }),
  });
  const data = await response.json();
  return data.status;
};

export const updateMWS = async (user_id, app_token, mwsSellerId, mwsAuthToken) => {
  const response = await fetch("https://boxc-api.lavneet.com/v1/user/update_mws", {
    method: "POST",
    body: new URLSearchParams({
      user_id,
      app_token,
      mws_seller_id: mwsSellerId,
      mws_auth_token: mwsAuthToken,
    }),
  });
  const data = await response.json();
  return data.status;
};
