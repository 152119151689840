import React, { Component } from "react";
import ListWorkspaceItem from "./ListWorkspaceItem";

class AllBoxItems extends Component {
  state = {};

  itemsToDisplay() {
    const itemsList = this.props.boxcontents.sort(function (x, y) {
      return y.lastUpdated - x.lastUpdated;
    });
    return itemsList;
  }

  render() {
    return (
      <div>
        {this.itemsToDisplay().map((item, index) => {
          return (
            <ListWorkspaceItem
              key={item.Asin + "_" + item.ShipmentId + "_" + item.BoxIdCount}
              item={item}
              getBoxes={this.props.getBoxes}
              generateFNSKU={this.props.generateFNSKU}
              deleteItemFromWorkspace={this.props.deleteItemFromWorkspace}
              moveItemToDiffBox={this.props.moveItemToDiffBox}
            />
          );
        })}
      </div>
    );
  }
}

export default AllBoxItems;
