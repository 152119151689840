import React, { Component } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import preloader02 from "../../assets/images/preloader_02_01.gif";
import ShipmentBox from "./ShipmentBox";
import newBox from "../../assets/images/new-box.png";
import ReactToPrint from "react-to-print";
import bwipjs from "bwip-js";
import PrintBoxBarcode from "./PrintBoxBarcode";
import printerIcon from "../../assets/images/icon_printer.png";

class ShipmentSelectOption extends Component {
  state = {
    show2DBarCode: false,
    printData: [],
  };

  getCurrentActiveBox() {
    return this.props.shipment.activeBoxIndex;
  }

  handleShow() {
    this.setState({ showBoxContent: true });
  }
  handleClose() {
    this.setState({ showBoxContent: false });
  }

  async checkShipment(e) {
    if (e.target.checked) {
      this.props.addShipmentToWorkspace();
    } else {
      this.props.removeShipmentFromWorkspace();
    }
  }

  /** Iterates over every item in all the boxes of this shipment to get the total item count. */
  totalItemsInAllBoxes() {
    if (this.props.shipment.boxes !== undefined) {
      let total_items = 0;
      this.props.shipment.boxes.forEach((box) => {
        box.items.forEach((item) => {
          total_items += item.quantityInBox;
        });
      });
      return total_items;
    }
  }

  /** Print Barcodes for all the boxes at once */
  handleBeforeGetContent() {
    /** Iterate over boxes to get box-details which will be passed on to the handleBeforeGetContent */
    return new Promise((resolve, reject) => {
      let printData = [];

      this.props.shipment.boxes.forEach((box) => {
        if (box.items !== undefined && box.items.length > 0) {
          const shipmentId = box.items[0].ShipmentId;
          const destinationCenter = box.items[0].DestinationFulfillmentCenterId;
          const boxIdCount = Number(box.id_count) + 1;
          const totalItemsInBox = this.totalItemsinBox(box);
          const totalWeightOfBox = this.totalWeightOfBox(box);

          let printDataIter = {
            shipmentId: shipmentId,
            destinationCenter: destinationCenter,
            boxIdCount: boxIdCount,
            totalItemsInBox: totalItemsInBox,
            totalWeightOfBox: totalWeightOfBox,
          };

          let barCodeString = "AMZN,PO:" + shipmentId;
          box.items.forEach((item) => {
            barCodeString += ",ASIN:" + item.Asin + ",QTY:" + item.quantityInBox;
          });
          printDataIter.barCodeString = barCodeString;
          printData.push(printDataIter);
        }
      });

      let promises = [];
      printData.forEach((pData) => {
        const promise = new Promise((resolve, reject) => {
          let canvas = document.createElement("canvas");
          bwipjs(
            canvas,
            {
              bcid: "pdf417",
              text: pData.barCodeString,
              scale: 5, // 3x scaling factor
              height: 10, // Bar height, in millimeters
              includetext: true,
              textxalign: "center",
            },
            function (err, cvs) {
              if (err) {
                return err;
              } else {
                const dataURL = canvas.toDataURL("image/png");
                resolve(dataURL);
              }
            }
          );
        });
        promises.push(promise);
      });

      Promise.all(promises).then((barCodes) => {
        barCodes.forEach((barCode, index) => {
          printData[index].b64 = barCode;
        });
        this.setState({ printData: printData }, () => resolve());
      });
    });
  }

  totalWeightOfBox(box) {
    let totalWeight = 0;
    box.items.forEach((itemInBox) => {
      totalWeight += Number(itemInBox.Weight) * itemInBox.quantityInBox;
    });
    return Math.round(totalWeight * 100) / 100;
  }

  totalItemsinBox(box) {
    let totalItems = 0;
    box.items.forEach((itemInBox) => {
      totalItems += itemInBox.quantityInBox;
    });
    return totalItems;
  }

  /** Iterates over every item in all the boxes of this shipment to get the total item weight. */
  totalWeightOfAllBox() {
    if (this.props.shipment.boxes !== undefined) {
      let total_weight = 0;
      this.props.shipment.boxes.forEach((box) => {
        box.items.forEach((item) => {
          total_weight += Number(item.quantityInBox * item.Weight);
        });
      });
      return Math.round(total_weight * 100) / 100;
    }
  }

  render() {
    return (
      <div className="ShipmentOption">
        <Row className="align-items-center row1">
          <Col
            md={8}
            style={{
              paddingTop: 10,
            }}
          >
            <Form.Group controlId={"CHECK_" + this.props.shipment.ShipmentId}>
              <div style={{ float: "left" }}>
                <Form.Check
                  disabled={this.props.shipment.status === "loading"}
                  type="checkbox"
                  defaultChecked={this.props.shipment.status === "checked" ? true : false}
                  onChange={(e) => this.checkShipment(e)}
                  className="ShipmentName"
                  label={this.props.shipment.ShipmentName}
                />
              </div>

              {this.props.shipment.boxes !== undefined &&
                this.props.shipment.boxes.length > 0 &&
                this.props.shipment.boxes[0].items.length > 0 && (
                  <div>
                    <ReactToPrint
                      trigger={() => (
                        <img
                          style={{ marginLeft: "10px", width: "22px", cursor: "pointer" }}
                          src={printerIcon}
                          alt="Print Barcodes"
                          title="Print Barcodes"
                        />
                      )}
                      pageStyle="@media print{@page {size: 8.5in 5.5in;size: landscape;}}"
                      onBeforeGetContent={() => this.handleBeforeGetContent()}
                      content={() => this.componentRef}
                    />
                    <div style={{ display: "none" }}>
                      <div ref={(el) => (this.componentRef = el)}>
                        {this.state.printData.map((printPage, index) => (
                          <div>
                            <PrintBoxBarcode key={"pp-" + index} b64={printPage.b64} printMeta={printPage} />
                            {this.state.printData.length > index + 1 && <p style={{ pageBreakBefore: "always" }}></p>}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}

              <div style={{ clear: "both" }}></div>

              <div style={{ paddingLeft: "20px" }}>
                <small className="badge badge-primary">{this.props.shipment.DestinationFulfillmentCenterId}</small>
                <small className="badge badge-primary" style={{ marginLeft: "10px" }}>
                  {this.props.shipment.ShipmentId}
                </small>
                {this.totalWeightOfAllBox() > 0 && (
                  <small className="badge badge-secondary" style={{ marginLeft: "10px" }}>
                    {this.totalWeightOfAllBox() + " lb"}
                  </small>
                )}
              </div>
            </Form.Group>
          </Col>
          <Col md={4}>
            {this.props.shipment.status === "checked" && (
              <div className="circularProgressBarContainer float-right">
                <CircularProgressbar
                  value={
                    this.props.shipment.totalItems > 0 &&
                    (this.totalItemsInAllBoxes() / this.props.shipment.totalItems) * 100
                  }
                  text={`${this.totalItemsInAllBoxes() + "/" + this.props.shipment.totalItems}`}
                  styles={{
                    path: {
                      stroke: "#106CFF",
                    },
                    text: {
                      fill: "#000",
                      fontSize: "22px",
                    },
                  }}
                />
              </div>
            )}
            {this.props.shipment.status === "loading" && (
              <img className="float-right" alt="Loading" src={preloader02} />
            )}
          </Col>
        </Row>
        <Row>
          {this.props.shipment.boxes !== undefined &&
            this.props.shipment.boxes.map((box, index) => {
              return (
                <div className={index === 0 ? "mb-4" : ""} key={"box-" + box.id}>
                  <ShipmentBox
                    key={box.id}
                    box={box}
                    activeBox={this.getCurrentActiveBox()}
                    deleteItemFromBox={this.props.deleteItemFromBox.bind(this, index)}
                  ></ShipmentBox>
                  {this.props.shipment.activeBoxIndex !== box.id_count ? (
                    <span onClick={this.props.markActive.bind(this, index)} className="markActiveText spanClickable">
                      Mark Current
                    </span>
                  ) : (
                    <span className="activeText">Current Box</span>
                  )}
                </div>
              );
            })}

          {this.props.shipment.boxes !== undefined &&
            this.props.shipment.boxes.length > 0 &&
            this.props.shipment.boxes[this.props.shipment.boxes.length - 1].items.length > 0 && (
              <span onClick={this.props.addNewBoxToShipment} className="NewBoxOptionContainer">
                <img className="boxIcon" alt="Add New Box" src={newBox} />
              </span>
            )}
        </Row>

        {/* <span>
              <small>
                <strong>
                  <a href="#">Export .tsv</a>
                </strong>
              </small>
            </span> */}
        {/* {this.props.shipment.boxes !== undefined &&
          this.props.shipment.boxes.length > 0 &&
          this.props.shipment.boxes[0].items.length > 0 && (
            <Row className="mt-2">
              <Col md={12} className="shipmentMeta">
                <span>
                  <small>
                    <strong>
                      <ReactToPrint
                        trigger={() => <a href="#">Print Barcodes</a>}
                        pageStyle="@media print{@page {size: 8.5in 5.5in;size: landscape;}}"
                        onBeforeGetContent={() => this.handleBeforeGetContent()}
                        content={() => this.componentRef}
                      />
                      <div style={{ display: "none" }}>
                        <div ref={el => (this.componentRef = el)}>
                          {this.state.printData.map((printPage, index) => (
                            <div>
                              <PrintBoxBarcode b64={printPage.b64} printMeta={printPage} />
                              {this.state.printData.length > index + 1 && <p style={{ pageBreakBefore: "always" }}></p>}
                            </div>
                          ))}
                        </div>
                      </div>
                    </strong>
                  </small>
                </span>
              </Col>
            </Row>
          )} */}
      </div>
    );
  }
}

export default ShipmentSelectOption;
