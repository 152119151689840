import React, { Component } from "react";
import { Table, Button } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

class TableListTriggers extends Component {
  state = {};

  deleteTrigger(trigger, _index) {
    confirmAlert({
      title: "Delete Trigger?",
      message: "This will permanently delete this trigger. Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.props.deleteTrigger(trigger, _index),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  }

  render() {
    return (
      <Table bordered className="table_triggers">
        <thead>
          <tr>
            <th style={{ width: "10%" }}>Trigger Name</th>
            <th>Categories</th>
            <th>Min eScore</th>
            <th>Max eScore</th>
            <th>Price</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {this.props.triggers.length >= 1 &&
            this.props.triggers.map((trigger, _index) => {
              try {
                const triggerJson = JSON.parse(trigger.TriggerJson);
                const triggerCategories = triggerJson.categories;
                let categoriesName = [];
                triggerCategories.forEach((triggerCategory) => {
                  categoriesName.push(triggerCategory["name"]);
                });
                return (
                  <tr key={trigger.Id}>
                    <td>{trigger.TriggerName}</td>
                    <td>{JSON.stringify(categoriesName)}</td>

                    <td>{"min_escore" in triggerJson ? triggerJson.min_escore : "N/A"}</td>
                    <td>{"max_escore" in triggerJson ? triggerJson.max_escore : "N/A"}</td>

                    <td>
                      {"price_comparison" in triggerJson
                        ? triggerJson.price_comparison + " - " + triggerJson.price_val
                        : "N/A"}
                    </td>

                    <td style={{ width: "13%" }}>
                      <Button
                        className="actionButtonLessPadding"
                        variant="light"
                        onClick={() => this.props.handleEditShow(trigger, _index)}
                      >
                        Edit
                      </Button>
                      <Button
                        className="actionButtonLessPadding"
                        style={{ marginLeft: "8px" }}
                        variant="danger"
                        onClick={() => this.deleteTrigger(trigger, _index)}
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                );
              } catch (e) {
                return (
                  <tr>
                    <td>{trigger.TriggerName}</td>
                    <td style={{ textAlign: "center" }} colSpan={12}>
                      Invalid Trigger
                    </td>
                  </tr>
                );
              }
            })}
        </tbody>
      </Table>
    );
  }
}

export default TableListTriggers;
