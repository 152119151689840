import React, { Component } from "react";
import { Row, Col, Form, Modal, Button, Spinner, Table } from "react-bootstrap";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css"; // If using WebPack and style-loader.

class ModalEdit extends Component {
  state = {
    multiProfiles: [],
    newProfileName: "",
    showMultiProfileOption: false,
  };
  constructor() {
    super();
    // this.handleOpen = this.handleOpen.bind(this);
    this.onFormSubmitEditUser = this.onFormSubmitEditUser.bind(this);
  }

  onFormSubmitEditUser(event) {
    event.preventDefault();
    let fields = event.target.elements;

    // const profiles = this.props.editUser !== null ? JSON.parse(this.props.editUser.profiles) : [];
    // fields.is_multi_profiles = event.target.elements.isMultiEnabled.checked && profiles.length > 0 ? 1 : 0;

    this.props.onFormSubmitEditUser(fields);
  }

  // handleOpen() {
  //   console.log(this.props.editUser);
  // }

  render() {
    return (
      <Modal
        onShow={this.handleOpen}
        show={this.props.showEditModal}
        onHide={this.props.handleClose}
        dialogClassName="editModal"
      >
        <Form onSubmit={this.onFormSubmitEditUser}>
          <Modal.Header closeButton>
            <h3>Edit User</h3>
          </Modal.Header>
          <Modal.Body>
            <div style={{ fontSize: "13px", fontWeight: "500" }}>
              <Row>
                <Col md={12}>
                  <Row>
                    <Col md={this.props.teams !== false && this.props.teams.length >= 1 ? 4 : 6}>
                      <Form.Group controlId="formFirstName">
                        <Form.Label>First Name *</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          defaultValue={this.props.editUser !== null ? this.props.editUser.first_name : ""}
                          name="firstName"
                        />
                      </Form.Group>
                    </Col>
                    <Col md={this.props.teams !== false && this.props.teams.length >= 1 ? 4 : 6}>
                      <Form.Group controlId="formLastName">
                        <Form.Label>Last Name *</Form.Label>
                        <Form.Control
                          type="text"
                          defaultValue={this.props.editUser !== null ? this.props.editUser.last_name : ""}
                          name="lastName"
                        />
                      </Form.Group>
                    </Col>
                    {this.props.teams !== false && this.props.teams.length >= 1 && (
                      <Col md={4}>
                        <Form.Group controlId="formTeamId">
                          <Form.Label>Team</Form.Label>
                          <Form.Control
                            as="select"
                            defaultValue={this.props.editUser !== null ? this.props.editUser.team_id : 1}
                            name="teamId"
                          >
                            {this.props.teams.map((triggerBatch) => {
                              return <option value={triggerBatch.id}>{triggerBatch.team_name}</option>;
                            })}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                    )}
                  </Row>

                  <Row>
                    <Col md={6}>
                      <Form.Group controlId="formTriggerBatch">
                        <Form.Label>Trigger Batch</Form.Label>
                        <Form.Control
                          as="select"
                          defaultValue={this.props.editUser !== null ? this.props.editUser.trigger_batch : 1}
                          name="triggerBatch"
                        >
                          {this.props.triggerBatches.map((triggerBatch) => {
                            return <option value={triggerBatch.id}>{triggerBatch.name}</option>;
                          })}
                        </Form.Control>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group controlId="formTeamId">
                        <Form.Label>Shipping Rate Plan</Form.Label>
                        <Form.Control
                          as="select"
                          defaultValue={
                            this.props.editUser !== null && this.props.editUser.shipping_rate_plan !== ""
                              ? this.props.editUser.shipping_rate_plan
                              : 1
                          }
                          name="shippingPlanId"
                        >
                          <option value={0}>Choose One</option>
                          {this.props.shippingPlans.map((shippingPlan) => {
                            return <option value={shippingPlan.id}>{shippingPlan.plan_name}</option>;
                          })}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6}>
                      <Form.Group controlId="formEmail">
                        <Form.Label>Email Address *</Form.Label>
                        <Form.Control
                          required
                          type="email"
                          defaultValue={this.props.editUser !== null ? this.props.editUser.user_email : ""}
                          name="emailAddress"
                        />
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group controlId="formPassword">
                        <Form.Label>Password *</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          defaultValue={this.props.editUser !== null ? this.props.editUser.user_password : ""}
                          name="password"
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={4}>
                      <Form.Group controlId="formPerPoundShipment">
                        <Form.Label>Per Pound Shipment Rate</Form.Label>
                        <Form.Control
                          type="number"
                          step="0.01"
                          placeholder="0.3"
                          defaultValue={this.props.editUser !== null ? this.props.editUser.per_pound : ""}
                          name="perPound"
                        />
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group controlId="formMWSSeller">
                        <Form.Label>MWS Seller ID</Form.Label>
                        <Form.Control
                          type="text"
                          defaultValue={this.props.editUser !== null ? this.props.editUser.mws_seller_id : ""}
                          name="mwsSellerId"
                        />
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group controlId="formMWSAuth">
                        <Form.Label>MWS Auth</Form.Label>
                        <Form.Control
                          type="text"
                          defaultValue={this.props.editUser !== null ? this.props.editUser.mws_auth_token : ""}
                          name="mwsAuthToken"
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row style={{ marginTop: "20px" }}>
                    <Col md={3}>
                      <Form.Check
                        defaultChecked={this.props.editUser !== null ? this.props.editUser.is_active : false}
                        type="switch"
                        name="isActive"
                        id="is-active"
                        label="Active?"
                      />
                    </Col>
                    <Col md={3}>
                      <Form.Check
                        defaultChecked={this.props.editUser !== null ? this.props.editUser.hidden_mode : false}
                        type="switch"
                        name="isHiddenMode"
                        id="is-hidden"
                        label="Hidden Mode?"
                      />
                    </Col>
                    <Col md={3}>
                      <Form.Check
                        defaultChecked={this.props.editUser !== null ? this.props.editUser.show_sales_rank : false}
                        type="switch"
                        name="isSalesRank"
                        id="is-show-rank"
                        label="Show Rank?"
                      />
                    </Col>
                    <Col md={3}>
                      <Form.Check
                        defaultChecked={this.props.editUser !== null ? this.props.editUser.infoboxes_enabled : false}
                        type="switch"
                        name="isInfoBoxes"
                        id="is-info-boxes"
                        label="InfoBoxes?"
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {this.props.inProgressEdit === true ? (
              <Spinner animation="border" role="status"></Spinner>
            ) : (
              <Button variant="primary" type="submit">
                Save Changes
              </Button>
            )}
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

export default ModalEdit;
