import { apiBaseUrlLocal } from "./AppConfig";
import axios from "axios";

export const loadUsers = async (admin_data) => {
  const response = await fetch(apiBaseUrlLocal + "admin/users/list", {
    method: "POST",
    body: new URLSearchParams({
      user_id: admin_data.admin_id,
      app_token: admin_data.token,
    }),
  });
  return await response.json();
};

export const deleteProfile = async (teamId, profileName, admin_id, admin_token) => {
  const response = await fetch(apiBaseUrlLocal + "admin/profiles/", {
    method: "DELETE",
    body: new URLSearchParams({
      team_id: teamId,
      profile_name: profileName,
      user_id: admin_id,
      app_token: admin_token,
    }),
  });
  return await response.json();
};

export const toggleIsMultiProfile = async (teamId, isChecked, admin_id, admin_token) => {
  const response = await fetch(apiBaseUrlLocal + "admin/profiles/toggle", {
    method: "POST",
    body: new URLSearchParams({
      team_id: teamId,
      is_on: isChecked === true ? 1 : 0,
      user_id: admin_id,
      app_token: admin_token,
    }),
  });
  return await response.json();
};

export const uploadNewProfile = async (teamId, profileName, pictureFile, admin_id, admin_token) => {
  const data = new FormData();
  data.append("team_id", teamId);
  data.append("profile_name", profileName);
  data.append("profile_picture", pictureFile);
  data.append("user_id", admin_id);
  data.append("app_token", admin_token);
  try {
    let res = await axios.post(apiBaseUrlLocal + "admin/profiles/", data);
    const dataRes = await res.data;
    return dataRes;
  } catch (err) {
    return false;
  }
};

export const loadTeams = async (admin_data) => {
  const response = await fetch(apiBaseUrlLocal + "admin/teams/list", {
    method: "POST",
    body: new URLSearchParams({
      user_id: admin_data.admin_id,
      app_token: admin_data.token,
    }),
  });
  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else return false;
};

export const loadWLSites = async (admin_data) => {
  const response = await fetch(apiBaseUrlLocal + "admin/whitelabel/list", {
    method: "POST",
    body: new URLSearchParams({
      user_id: admin_data.admin_id,
      app_token: admin_data.token,
    }),
  });
  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else return false;
};

export const addUser = async (admin_data, fields) => {
  const response = await fetch(apiBaseUrlLocal + "admin/users/", {
    method: "POST",
    body: new URLSearchParams({
      user_id: admin_data.admin_id,
      app_token: admin_data.token,
      perPound: fields.perPound.value !== "" ? fields.perPound.value : 0.3,
      firstName: fields.firstName.value,
      lastName: fields.lastName.value,
      emailAddress: fields.emailAddress.value,
      password: fields.password.value,
      mwsSellerId: fields.mwsSellerId.value,
      mwsAuthToken: fields.mwsAuthToken.value,
      triggerBatch: fields.triggerBatch.value,
      isActive: fields.isActive.checked ? 1 : 0,
      isHiddenMode: fields.isHiddenMode.checked ? 1 : 0,
      isSalesRank: fields.isSalesRank.checked ? 1 : 0,
      isInfoBoxes: fields.isInfoBoxes.checked ? 1 : 0,
      teamId: "teamId" in fields ? fields.teamId.value : null,
      shippingRatePlan: fields.shippingPlanId.value,
    }),
  });
  if (response.status === 200 || response.status === 400) {
    const data = await response.json();
    return data;
  } else return false;
};

export const editUser = async (admin_data, fields) => {
  const response = await fetch(apiBaseUrlLocal + "admin/users/" + fields.user_id, {
    method: "PUT",
    body: new URLSearchParams({
      user_id: admin_data.admin_id,
      app_token: admin_data.token,
      perPound: fields.perPound.value !== "" ? fields.perPound.value : 0.3,
      userId: fields.user_id,
      is_multi_profiles: fields.is_multi_profiles,
      firstName: fields.firstName.value,
      lastName: fields.lastName.value,
      emailAddress: fields.emailAddress.value,
      password: fields.password.value,
      mwsSellerId: fields.mwsSellerId.value,
      mwsAuthToken: fields.mwsAuthToken.value,
      triggerBatch: fields.triggerBatch.value,
      isActive: fields.isActive.checked ? 1 : 0,
      isHiddenMode: fields.isHiddenMode.checked ? 1 : 0,
      isSalesRank: fields.isSalesRank.checked ? 1 : 0,
      isInfoBoxes: fields.isInfoBoxes.checked ? 1 : 0,
      teamId: "teamId" in fields ? fields.teamId.value : null,
      shippingRatePlan: fields.shippingPlanId.value,
    }),
  });
  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else return false;
};

export const deleteUser = async (admin_data, userId) => {
  const response = await fetch(apiBaseUrlLocal + "admin/users/" + userId, {
    method: "DELETE",
    body: new URLSearchParams({
      user_id: admin_data.admin_id,
      app_token: admin_data.token,
    }),
  });
  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else return false;
};
