import React, { Component } from "react";

class Route404 extends Component {
  state = {};
  render() {
    return <h1>No Match 404</h1>;
  }
}

export default Route404;
