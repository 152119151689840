import React, { Component } from "react";
import TopMenu from "../components/TopMenu";
import Cookies from "universal-cookie";
import { Container, Row, Col, Button, Table } from "react-bootstrap";
import NewTeam from "../components/WhiteLabel/NewTeam";
import { loadTeams, loadWLSites } from "../includes/HelperUsers";
import { updateTeamWhileLabel, editTeamWhileLabel, deleteWLSite } from "../components/WhiteLabel/Helper";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import EditTeam from "../components/WhiteLabel/EditTeam";

const cookies = new Cookies();

class WhiteLabel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      admin_data: cookies.get("admin_data"),
      showAddModal: false,
      showEditModal: false,
      inProgressUpdate: false,
      teams: [],
      wlSites: [],
      fileLogoDark: null,
      fileLogoLight: null,
      fileHomeBanner: null,
      selectedTeam: {},
    };

    this.loadTeams = this.loadTeams.bind(this);
    this.onFormSubmitAdd = this.onFormSubmitAdd.bind(this);
    this.onFormSubmitEdit = this.onFormSubmitEdit.bind(this);
  }

  componentDidMount() {
    this.loadTeams();
    this.loadWLSites();
  }

  async onFormSubmitAdd(e) {
    e.preventDefault();
    const teamId = e.target.elements.teamId.value;
    const subDomain = e.target.elements.subDomain.value;
    const supportEmail = e.target.elements.supportEmail.value;
    const winnersMap = e.target.elements.winnersMap.value;
    const browserTitle = e.target.elements.browserTitle.value;
    const siteFlags = {
      hidden_mode: e.target.elements.isHiddenMode.checked,
      sales_rank: e.target.elements.isShowSalesRank.checked,
      infoboxes: e.target.elements.isInfoBoxesEnabled.checked,
      menu_sounds: e.target.elements.isShowTopMenuSounds.checked,
      menu_settings: e.target.elements.isShowTopMenuSettings.checked,
      menu_shipments: e.target.elements.isShowTopMenuShipments.checked,
      menu_scans: e.target.elements.isShowTopMenuScans.checked,
      menu_source: e.target.elements.isShowTopMenuSource.checked,
    };

    this.setState({ inProgressUpdate: true });

    const wlSites = await updateTeamWhileLabel(
      teamId,
      this.state.admin_data,
      subDomain,
      supportEmail,
      winnersMap,
      this.state.fileLogoDark,
      this.state.fileLogoLight,
      this.state.fileHomeBanner,
      browserTitle,
      JSON.stringify(siteFlags)
    );
    wlSites !== false &&
      this.setState({
        showAddModal: false,
        wlSites: wlSites.data,
      });

    this.setState({ inProgressUpdate: false });
  }

  async onFormSubmitEdit(e) {
    e.preventDefault();
    const teamId = e.target.elements.teamId.value;
    const subDomain = e.target.elements.subDomain.value;
    const supportEmail = e.target.elements.supportEmail.value;
    const winnersMap = e.target.elements.winnersMap.value;
    const browserTitle = e.target.elements.browserTitle.value;
    const siteFlags = {
      hidden_mode: e.target.elements.isHiddenMode.checked,
      sales_rank: e.target.elements.isShowSalesRank.checked,
      infoboxes: e.target.elements.isInfoBoxesEnabled.checked,
      menu_sounds: e.target.elements.isShowTopMenuSounds.checked,
      menu_settings: e.target.elements.isShowTopMenuSettings.checked,
      menu_shipments: e.target.elements.isShowTopMenuShipments.checked,
      menu_scans: e.target.elements.isShowTopMenuScans.checked,
      menu_source: e.target.elements.isShowTopMenuSource.checked,
    };

    this.setState({ inProgressUpdate: true });

    const wlSites = await editTeamWhileLabel(
      teamId,
      this.state.admin_data,
      subDomain,
      supportEmail,
      winnersMap,
      this.state.fileLogoDark,
      this.state.fileLogoLight,
      this.state.fileHomeBanner,
      browserTitle,
      JSON.stringify(siteFlags)
    );
    wlSites !== false &&
      this.setState({
        showEditModal: false,
        wlSites: wlSites.data,
      });

    this.setState({ inProgressUpdate: false });
  }

  async handleTeamDelete(teamId) {
    confirmAlert({
      title: "Delete Site?",
      message: "This will unmap this whitelabel site from the selected team. Are you sure?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            const wlSites = await deleteWLSite(teamId, this.state.admin_data);
            wlSites !== false &&
              this.setState({
                wlSites: wlSites.data,
              });
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  }

  async loadTeams() {
    if (this.state.admin_data.access_level === 0 || this.state.admin_data.access_level === 1) {
      const teams = await loadTeams(this.state.admin_data);
      teams !== false &&
        this.setState({
          teams: teams.data.map((team) => {
            return {
              team_name: team.team_name,
              id: team.id,
            };
          }),
        });
    }
  }

  async loadWLSites() {
    if (this.state.admin_data.access_level === 0 || this.state.admin_data.access_level === 1) {
      const wlSites = await loadWLSites(this.state.admin_data);
      wlSites !== false &&
        this.setState({
          wlSites: wlSites.data,
        });
    }
  }

  render() {
    return (
      <>
        <TopMenu
          handlenavigationroutes={(routeName) => {
            this.props.history.push(routeName);
          }}
          history={this.props.history}
          userData={this.state.admin_data}
          activeKey="/users"
          isReevaluate={
            "is_re_evaluate" in this.state.admin_data && this.state.admin_data.is_re_evaluate === 1 ? true : false
          }
          adminData={this.state.admin_data}
        />

        <EditTeam
          inProgressUpdate={this.state.inProgressUpdate}
          selectedTeam={this.state.selectedTeam}
          teams={this.state.teams}
          showEditModal={this.state.showEditModal}
          handleClose={() => this.setState({ showEditModal: false })}
          setFileLogoDark={(e) => {
            if (e.target.files[0] !== undefined) {
              this.setState({ fileLogoDark: e.target.files[0] });
            }
          }}
          setFileLogoLight={(e) => {
            if (e.target.files[0] !== undefined) {
              this.setState({ fileLogoLight: e.target.files[0] });
            }
          }}
          setFileHomeBanner={(e) => {
            if (e.target.files[0] !== undefined) {
              this.setState({ fileHomeBanner: e.target.files[0] });
            }
          }}
          onFormSubmitEdit={this.onFormSubmitEdit}
        />

        <NewTeam
          inProgressUpdate={this.state.inProgressUpdate}
          teams={this.state.teams}
          showAddModal={this.state.showAddModal}
          handleClose={() => this.setState({ showAddModal: false })}
          setFileLogoDark={(e) => {
            if (e.target.files[0] !== undefined) {
              this.setState({ fileLogoDark: e.target.files[0] });
            }
          }}
          setFileLogoLight={(e) => {
            if (e.target.files[0] !== undefined) {
              this.setState({ fileLogoLight: e.target.files[0] });
            }
          }}
          setFileHomeBanner={(e) => {
            if (e.target.files[0] !== undefined) {
              this.setState({ fileHomeBanner: e.target.files[0] });
            }
          }}
          onFormSubmitAdd={this.onFormSubmitAdd}
        />

        <Container fluid={true}>
          <Row>
            <Col md={12}>
              <div style={{ display: "flex", marginTop: "20px", justifyContent: "center", alignItems: "center" }}>
                <h4>WHITELABEL MANAGEMENT</h4>

                <Button
                  variant="primary"
                  type="submit"
                  style={{ marginLeft: "auto" }}
                  onClick={() => this.setState({ showAddModal: true })}
                >
                  Build New Site
                </Button>
              </div>

              <div style={{ marginTop: "20px" }}>
                <Table bordered className="table_triggers">
                  <thead>
                    <tr>
                      <th>Sub domain</th>
                      <th>Team Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.wlSites.map((wlSite) => (
                      <tr>
                        <td>{wlSite.sub_domain}</td>
                        <td>{wlSite.team_name}</td>
                        <td>
                          <Button
                            className="actionButtonLessPadding"
                            style={{ marginLeft: "8px" }}
                            variant="secondary"
                            onClick={() => {
                              this.setState({
                                fileLogoDark: null,
                                fileLogoLight: null,
                                fileHomeBanner: null,
                                showEditModal: true,
                                selectedTeam: wlSite,
                              });
                            }}
                          >
                            <i className="fa fa-pencil" />
                          </Button>
                          <Button
                            className="actionButtonLessPadding"
                            style={{ marginLeft: "20px" }}
                            variant="danger"
                            onClick={() => this.handleTeamDelete(wlSite.id)}
                          >
                            <i className="fa fa-trash" />
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default WhiteLabel;
