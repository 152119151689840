import React, { Component, createRef } from "react";
import { Row, Col, Button } from "react-bootstrap";
import TopMenu from "../components/TopMenu";
import Cookies from "universal-cookie";
import { ReactTabulator } from "react-tabulator";
import { Container } from "react-bootstrap";
import "react-tabulator/lib/styles.css"; // default theme
import "react-tabulator/css/bootstrap/tabulator_bootstrap.min.css"; // use Theme(s)
import DateEditor from "react-tabulator/lib/editors/DateEditor";
// import MultiValueFormatter from "react-tabulator/lib/formatters/MultiValueFormatter";
import {
  getMPSources,
  updateMPSource,
  createNewRow,
  deleteMPSource,
  downloadManifest,
} from "../includes/HelperMpSources";
import { loadTeams } from "../includes/HelperUsers";
import { reactFormatter } from "../includes/MpSourceUtils.tsx";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
global.moment = require("moment");

const cookies = new Cookies();
const tableRef = createRef();

function SimpleButton(props) {
  const rowData = props.cell._cell.row.data;
  //const cellValue = props.cell._cell.value || "Edit | Show";
  return (
    <div>
      <button title="Delete marketplace Shipment" onClick={() => props.handleDeleteMPSourceDB(rowData)}>
        {" "}
        <i className="fa fa-trash" />
      </button>
      <button
        title="Download Manifest File"
        onClick={() => props.handleDownloadManifest(rowData)}
        style={{ marginLeft: "10px" }}
      >
        {" "}
        <i className="fa fa-download" />
      </button>
    </div>
  );
}

class MpSources extends Component {
  state = {
    admin_data: cookies.get("admin_data"),
    mp_sources: [],
    teams: [],
  };

  constructor() {
    super();
    this.handleDeleteMPSourceDB = this.handleDeleteMPSourceDB.bind(this);
    this.handleDownloadManifest = this.handleDownloadManifest.bind(this);
  }

  componentDidMount() {
    this.processMPSources();
  }

  async processMPSources() {
    const teamsData = await loadTeams(this.state.admin_data);
    if ("status" in teamsData && teamsData.status === "success")
      this.setState({ teams: teamsData.data }, async () => {
        const mpSources = await getMPSources(this.state.admin_data);
        if ("status" in mpSources && mpSources.status) {
          this.setState({ mp_sources: mpSources.data }, () => {
            tableRef.current.table.setSort([{ column: "id", dir: "desc" }]);
          });
        }
      });
  }

  async handleDownloadManifest(rowData) {
    this.setState({ downloadingManifest: true });
    const result = await downloadManifest(this.state.admin_data, rowData.name);
    if ("status" in result && result.status) {
      let csvContent = "data:text/csv;charset=utf-8,";

      csvContent += "ASIN,TITLE,ESCORE,ESTIMATED PROFIT,MARKETPLACE PRICE,SALESRANK,TARGET LIST PRICE,SCANNED_ON\r\n";

      result.data.forEach((row) => {
        const scannedDate = new Date(row.scanned_on);
        const yearScanned = scannedDate.getFullYear();
        let monthScanned = (1 + scannedDate.getMonth()).toString();
        monthScanned = monthScanned.length > 1 ? monthScanned : "0" + monthScanned;

        let dayScanned = scannedDate.getDate().toString();
        dayScanned = dayScanned.length > 1 ? dayScanned : "0" + dayScanned;

        csvContent +=
          row.asin +
          "," +
          row.title.replace(/[^\w\s]/gi, "") +
          "," +
          row.escore +
          "," +
          row.estimated_profit +
          "," +
          row.marketplace_price +
          "," +
          row.sales_rank +
          "," +
          row.target_list_price +
          "," +
          (row.scanned_on === "" ? "" : monthScanned + "-" + dayScanned + "-" + yearScanned) +
          "\r\n";
      });

      var encodedUri = encodeURI(csvContent);
      const fixedEncodedURI = encodedUri.replaceAll("#", "%23");
      var link = document.createElement("a");
      link.setAttribute("href", fixedEncodedURI);
      link.setAttribute("download", `${rowData.name}.csv`);
      document.body.appendChild(link); // Required for FF

      link.click(); // This will download the data file named "my_data.csv".
    }
    this.setState({ downloadingManifest: false });
  }

  async handleDeleteMPSourceDB(rowData) {
    confirmAlert({
      title: "Delete MP Source?",
      message: "This will permanently delete this MP Source. Are you sure?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            const result = await deleteMPSource(this.state.admin_data, rowData.id);
            if ("status" in result && result.status)
              this.setState({ mp_sources: result.data }, () => {
                tableRef.current.table.setSort([{ column: "id", dir: "desc" }]);
              });
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  }

  async handleUpdateMPSourceDB(data) {
    const result = await updateMPSource(this.state.admin_data, data);
    if ("status" in result && result.status)
      this.setState({ mp_sources: result.data }, () => {
        tableRef.current.table.setSort([{ column: "id", dir: "desc" }]);
      });
  }
  async handleCreateNewRow() {
    const result = await createNewRow(this.state.admin_data);
    if ("status" in result && result.status)
      this.setState({ mp_sources: result.data }, () => {
        tableRef.current.table.setSort([{ column: "id", dir: "desc" }]);
      });
  }

  render() {
    let teamsSelect = {};
    for (let i = 0; i < this.state.teams.length; i++) {
      teamsSelect[this.state.teams[i].id] = this.state.teams[i].team_name;
    }
    console.log(teamsSelect);
    const editableColumns = [
      {
        title: "",
        field: "custom",
        hozAlign: "center",
        width: 95,
        formatter: reactFormatter(
          <SimpleButton
            handleDownloadManifest={this.handleDownloadManifest}
            handleDeleteMPSourceDB={this.handleDeleteMPSourceDB}
          />
        ),
      },
      {
        title: "ID",
        field: "id",
        width: 70,
        headerSortStartingDir: "desc",
      },
      {
        title: "Name",
        field: "name",
        width: 150,
        editor: "input",
        headerFilter: "input",
      },
      {
        title: "Notes",
        field: "pallet_notes",
        width: 150,
        editor: "input",
        headerFilter: "input",
      },
      {
        title: "Value",
        field: "pallet_value",
        width: 150,
        editor: "input",
        headerFilter: "input",
      },
      {
        title: "Filled",
        field: "agg",
        width: 150,
      },
      {
        title: "Buyer Commission",
        field: "buyer_commission",
        width: 150,
        editor: "input",
        headerFilter: "input",
      },
      {
        title: "Seller Commission",
        field: "seller_commission",
        width: 150,
        editor: "input",
        headerFilter: "input",
      },
      {
        title: "Completed Progress",
        field: "per_completed",
        hozAlign: "left",
        formatter: "progress",
        formatterParams: {
          min: 0,
          max: 100,
          color: ["red", "orange", "green"],
          legend: (val) => {
            return val + "%";
          },
          legendColor: "#000000",
          legendAlign: "center",
        },
        width: 150,
      },
      {
        title: "Team",
        field: "team_name",
        width: 150,
        editor: "select",
        editorParams: teamsSelect,
      },
      {
        title: "Date Ordered",
        field: "ordered_on",
        sorter: "date",
        editor: DateEditor,
        width: 150,
        sorterParams: { format: "YYYY-MM-DD" },
        editorParams: { format: "YYYY-MM-DD" },
      },
      {
        title: "Completed?",
        field: "completed",
        sortable: true,
        sorter: "number",
        align: "center",
        formatter: "tickCross",
        responsive: 1,
        width: 100,
        cellClick: function (e, cell) {
          cell.setValue(!cell.getValue());
        },
      },
      {
        title: "Shipped?",
        field: "shipped",
        sortable: true,
        sorter: "number",
        align: "center",
        formatter: "tickCross",
        responsive: 1,
        width: 100,
        cellClick: function (e, cell) {
          cell.setValue(!cell.getValue());
        },
      },
      {
        title: "Paid?",
        field: "paid",
        sortable: true,
        sorter: "number",
        align: "center",
        formatter: "tickCross",
        responsive: 1,
        width: 100,
        cellClick: function (e, cell) {
          cell.setValue(!cell.getValue());
        },
      },
      {
        title: "Buyer Name",
        field: "buyer_name",
        width: 150,
        editor: "input",
        headerFilter: "input",
      },
      {
        title: "Buyer Email",
        field: "buyer_email",
        width: 150,
        editor: "input",
        headerFilter: "input",
      },
      {
        title: "Buyer Phone",
        field: "buyer_contact_number",
        width: 150,
        editor: "input",
        headerFilter: "input",
      },
      {
        title: "Buyer Address",
        field: "buyer_address",
        width: 150,
        editor: "input",
        headerFilter: "input",
      },
      {
        title: "Buyer City",
        field: "buyer_city",
        width: 150,
        editor: "input",
        headerFilter: "input",
      },
      {
        title: "Buyer State",
        field: "buyer_state",
        width: 150,
        editor: "input",
        headerFilter: "input",
      },
      {
        title: "Buyer ZIP",
        field: "buyer_zip",
        width: 150,
        editor: "input",
        headerFilter: "input",
      },
      {
        title: "Prop",
        field: "residential_vs_commercial",
        width: 150,
        editor: "select",
        editorParams: {
          Residential: "Residential",
          Commerical: "Commercial",
        },
      },
      {
        title: "Requires lift gate?",
        field: "requires_lift_gate",
        sortable: true,
        sorter: "number",
        align: "center",
        formatter: "tickCross",
        responsive: 1,
        width: 200,
        cellClick: function (e, cell) {
          cell.setValue(!cell.getValue());
        },
      },
      {
        title: "Freight Cost",
        field: "freight_cost",
        width: 150,
        editor: "input",
        headerFilter: "input",
      },
      {
        title: "Freight Charge",
        field: "freight_charge",
        width: 150,
        editor: "input",
        headerFilter: "input",
      },
      {
        title: "Freight Paid?",
        field: "freight_paid",
        sortable: true,
        sorter: "number",
        align: "center",
        formatter: "tickCross",
        responsive: 1,
        width: 200,
        cellClick: function (e, cell) {
          cell.setValue(!cell.getValue());
        },
      },
      {
        title: "Total Profit",
        field: "total_profit",
        width: 150,
        editor: "input",
        headerFilter: "input",
      },
      {
        title: "Per Pound",
        field: "per_pound",
        width: 150,
        editor: "input",
        headerFilter: "input",
      },
      // {
      //   title: "Pets",
      //   field: "pets",
      //   sorter: (a, b) => a.toString().localeCompare(b.toString()),
      //   // editor: MultiSelectEditor,
      //   editorParams: { values: petOptions },
      //   formatter: MultiValueFormatter,
      //   formatterParams: { style: "PILL" },
      // },
      // {
      //   title: "Passed?",
      //   field: "passed",
      //   hozAlign: "center",
      //   formatter: "tickCross",
      //   editor: true,
      // },
    ];

    // const data = [
    //   {
    //     id: 1,
    //     name: "Oli Bob",
    //     age: "12",
    //     color: "red",
    //     dob: "01/01/1980",
    //     rating: 5,
    //     passed: true,
    //     pets: ["cat", "dog"],
    //   },
    //   {
    //     id: 2,
    //     name: "Mary May",
    //     age: "1",
    //     color: "green",
    //     dob: "12/05/1989",
    //     rating: 4,
    //     passed: true,
    //     pets: ["cat"],
    //   },
    //   {
    //     id: 5,
    //     name: "Margret Marmajuke",
    //     age: "16",
    //     color: "yellow",
    //     dob: "07/01/1999",
    //     rating: 4,
    //     passed: false,
    //   },
    //   {
    //     id: 6,
    //     name: "Van Ng",
    //     age: "37",
    //     color: "green",
    //     dob: "06/10/1982",
    //     rating: 4,
    //     passed: true,
    //     pets: ["dog", "fish"],
    //   },
    //   {
    //     id: 7,
    //     name: "Duc Ng",
    //     age: "37",
    //     color: "yellow",
    //     dob: "10/10/1982",
    //     rating: 4,
    //     passed: true,
    //     pets: ["dog"],
    //   },
    // ];

    return (
      <React.Fragment>
        <TopMenu
          handlenavigationroutes={(routeName) => {
            // abortRequests();
            this.props.history.push(routeName);
          }}
          isReevaluate={
            "is_re_evaluate" in this.state.admin_data && this.state.admin_data.is_re_evaluate === 1 ? true : false
          }
          history={this.props.history}
          activeKey="/invite"
          adminData={this.state.admin_data}
        />

        <Container fluid={true} style={{ marginTop: "15px" }}>
          <Row style={{ marginTop: "20px" }}>
            <Col md={8}>
              <div>
                <h3>Marketplace Batches</h3>
              </div>
            </Col>
            <Col md={4}>
              <div style={{ textAlign: "right" }}>
                {(this.state.admin_data.access_level === 0 || this.state.admin_data.access_level === 1) && (
                  <Button
                    className="actionButtonLessPadding"
                    style={{ marginLeft: "8px" }}
                    variant="success"
                    onClick={() => this.handleCreateNewRow()}
                  >
                    Add New
                  </Button>
                )}
              </div>
            </Col>
          </Row>

          <Row style={{ marginTop: "20px" }}>
            <Col md={12}>
              <ReactTabulator
                ref={tableRef}
                cellEdited={(cell) => {
                  //const row = cell.getRow();
                  // console.log(row);
                  // console.log("cellEdited", cell);

                  const oldValue = cell._cell.oldValue;
                  const newValue = cell._cell.value;
                  const fieldName = cell._cell.column.field;
                  const mpSourceId = cell._cell.row.data.id;
                  console.log({ oldValue, newValue, fieldName, mpSourceId });

                  this.handleUpdateMPSourceDB({ oldValue, newValue, fieldName, mpSourceId });
                }}
                columns={editableColumns}
                data={this.state.mp_sources}
                // footerElement={<span>Footer</span>}
                initialSort={[{ column: "id", dir: "desc" }]}
              />
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default MpSources;
