import React, { Component } from "react";
import { Table, Button } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

class TableListTriggers extends Component {
  state = {};

  deleteTrigger(trigger, _index) {
    confirmAlert({
      title: "Delete Trigger?",
      message: "This will permanently delete this trigger. Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.props.deleteTrigger(trigger, _index),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  }

  render() {
    return (
      <Table bordered className="table_triggers">
        <thead>
          <tr>
            <th style={{ width: "10%" }}>Trigger Name</th>
            <th>Bump Price Average</th>
            <th>Bump Price Median</th>
            <th>Used Buy Box Average</th>
            <th>Bump Count</th>
            <th>Target Price</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {this.props.triggers.length >= 1 &&
            this.props.triggers.map((trigger, _index) => {
              try {
                const triggerJson = JSON.parse(trigger.TriggerJson);
                return (
                  <tr key={trigger.Id}>
                    <td>{trigger.TriggerName}</td>
                    <td>{triggerJson.seasonBumpPriceAvg + " > $" + triggerJson.valBumpPriceAvg}</td>
                    <td>{triggerJson.seasonBumpPriceMedian + " > $" + triggerJson.valBumpPriceMedian}</td>
                    <td>{triggerJson.seasonUsedBuyBox + " > $" + triggerJson.valUsedBuyBox}</td>
                    <td>{triggerJson.seasonBumpCount + " > " + triggerJson.valBumpCount}</td>
                    <td>${triggerJson.targetPrice}</td>

                    <td style={{ width: "13%" }}>
                      <Button
                        size="sm"
                        className="actionButtonLessPadding"
                        variant="secondary"
                        onClick={() => this.props.handleEditShow(trigger, _index)}
                      >
                        Edit
                      </Button>
                      <Button
                        size="sm"
                        className="actionButtonLessPadding"
                        style={{ marginLeft: "8px" }}
                        variant="danger"
                        onClick={() => this.deleteTrigger(trigger, _index)}
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                );
              } catch (e) {
                return (
                  <tr>
                    <td>{trigger.TriggerName}</td>
                    <td style={{ textAlign: "center" }} colSpan={12}>
                      Invalid Trigger
                    </td>
                  </tr>
                );
              }
            })}
        </tbody>
      </Table>
    );
  }
}

export default TableListTriggers;
