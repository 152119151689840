import React, { Component } from "react";
import { FormControl, InputGroup, Button, Spinner } from "react-bootstrap";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { siteColors } from "../../includes/AppConfig";

class ColorTextPickerPlain extends Component {
  state = {
    currentColor: "",
    currentDisplayText: "",
  };

  componentDidMount() {
    this.setState({ currentColor: this.props.customColor, currentDisplayText: this.props.customText });
  }

  render() {
    const ddOptions = siteColors.map((sc) => {
      return { value: sc, label: <div style={{ width: "20px", height: "20px", background: `${sc}` }}></div> };
    });

    return (
      <React.Fragment>
        <div>
          <InputGroup id="ig_asin" style={{ maxWidth: "400px" }}>
            <InputGroup.Prepend>
              <Dropdown
                options={ddOptions}
                onChange={(col) => {
                  this.setState({ currentColor: col.value });
                }}
                value={this.state.currentColor !== "" ? this.state.currentColor : "#00743F"}
              />
            </InputGroup.Prepend>
            <FormControl
              value={this.state.currentDisplayText}
              onChange={(e) => {
                this.setState({ currentDisplayText: e.target.value });
              }}
              disabled={this.props.updateCustomTextColor}
              style={{ borderRadius: "0" }}
              autoComplete="off"
              placeholder="Display Text"
              aria-describedby="basic-addon1"
            />
            <InputGroup.Append>
              <Button
                onClick={() => {
                  this.props.updateCustomTextColor(this.state.currentColor, this.state.currentDisplayText);
                }}
              >
                {this.props.updatingCustomTextColor === true ? (
                  <Spinner style={{ width: "20px", height: "20px" }} animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                ) : (
                  <span>Update</span>
                )}
              </Button>
            </InputGroup.Append>
          </InputGroup>
        </div>
      </React.Fragment>
    );
  }
}

export default ColorTextPickerPlain;
