import React, { Component } from "react";
import { Row, Col, Form, Modal, Button, InputGroup, Spinner, Table } from "react-bootstrap";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import ReactTooltip from "react-tooltip";
import { siteColors } from "../../../../includes/AppConfig";

class ModalAdd extends Component {
  state = {
    currentColor: "",
    isAlwaysReject: false,
  };
  render() {
    const ddOptions = siteColors.map((sc) => {
      return { value: sc, label: <div style={{ width: "20px", height: "20px", background: `${sc}` }}></div> };
    });
    return (
      <Modal
        onEntered={this.handleOpen}
        show={this.props.showAddModal}
        onHide={this.props.handleClose}
        dialogClassName="editModal"
      >
        <ReactTooltip place="top" type="info" effect="solid" html={true} />

        <Form onSubmit={(e) => this.props.onFormSubmitAddTrigger(e, this.state.currentColor)}>
          <Modal.Header closeButton>
            <div style={{ fontSize: "20px", fontWeight: "bold" }}>
              If you scan a textbook with PalletIQ, what additional parameters would you like to use to filter the
              results?
            </div>
          </Modal.Header>
          <Modal.Body>
            <div style={{ marginTop: "10px" }}>
              <Table>
                <thead>
                  <tr>
                    <th>Data Point</th>
                    <th>Season</th>
                    <th>Minimum Value</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      Average of the top 5 bump prices
                      <i
                        style={{ marginLeft: "10px", fontSize: "15px" }}
                        data-tip="When the sales rank drops, that indicates a sale on Amazon.<br />We snag the lowest price at the time of the rank drop and call that a 'bump price'.<br />This means the book sold for AT LEAST this much money (including shipping).<br />Prime prices may be higher than the bump price, so keep in mind that this is a conservative value."
                        className="fa fa-info-circle"
                      />
                    </td>
                    <td>
                      <Form.Control required as="select" name="seasonBumpPriceAvg">
                        <option value="spring-and-fall">Spring and Fall</option>
                        <option value="spring">Spring</option>
                        <option value="fall">Fall</option>
                      </Form.Control>
                    </td>
                    <td style={{ width: "150px" }}>
                      <div>
                        <Form.Group controlId="formMinScore" style={{ marginBottom: "0" }}>
                          <InputGroup id="ig_asin">
                            <InputGroup.Prepend>
                              <InputGroup.Text id="inputGroupPrepend">$</InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control
                              required
                              type="number"
                              step="0.01"
                              defaultValue="0"
                              name="valBumpPriceAvg"
                              placeholder="Min Price"
                            />
                          </InputGroup>
                        </Form.Group>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Median bump price
                      <i
                        style={{ marginLeft: "10px", fontSize: "15px" }}
                        data-tip="When the sales rank drops, that indicates a sale on Amazon.<br />We snag the lowest price at the time of the rank drop and call that a 'bump price'.<br />This means the book sold for AT LEAST this much money (including shipping).<br />Prime prices may be higher than the bump price, so keep in mind that this is a conservative value."
                        className="fa fa-info-circle"
                      />
                    </td>
                    <td>
                      <Form.Control required as="select" name="seasonBumpPriceMedian">
                        <option value="spring-and-fall">Spring and Fall</option>
                        <option value="spring">Spring</option>
                        <option value="fall">Fall</option>
                      </Form.Control>
                    </td>
                    <td>
                      <Form.Group controlId="formMinScore" style={{ marginBottom: "0" }}>
                        <InputGroup id="ig_asin">
                          <InputGroup.Prepend>
                            <InputGroup.Text id="inputGroupPrepend">$</InputGroup.Text>
                          </InputGroup.Prepend>
                          <Form.Control
                            required
                            type="number"
                            step="0.01"
                            defaultValue="0"
                            name="valBumpPriceMedian"
                            placeholder="Min Price"
                          />
                        </InputGroup>
                      </Form.Group>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Average used buy box price
                      <i
                        style={{ marginLeft: "10px", fontSize: "15px" }}
                        data-tip="The used buy box price doesn't indicate a price where a book actually sold,<br />but it shows you if there's a healthy prime bump for deciding if you want to set the book aside."
                        className="fa fa-info-circle"
                      />
                    </td>
                    <td>
                      <Form.Control required as="select" name="seasonUsedBuyBox">
                        <option value="spring-and-fall">Spring and Fall</option>
                        <option value="spring">Spring</option>
                        <option value="fall">Fall</option>
                      </Form.Control>
                    </td>
                    <td>
                      <Form.Group controlId="formMinScore" style={{ marginBottom: "0" }}>
                        <InputGroup id="ig_asin">
                          <InputGroup.Prepend>
                            <InputGroup.Text id="inputGroupPrepend">$</InputGroup.Text>
                          </InputGroup.Prepend>
                          <Form.Control
                            required
                            type="number"
                            step="0.01"
                            defaultValue="0"
                            name="valUsedBuyBox"
                            placeholder="Min Price"
                          />
                        </InputGroup>
                      </Form.Group>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Bump Count
                      <i
                        style={{ marginLeft: "10px", fontSize: "15px" }}
                        data-tip="Bump count is the number of days during textbook season where the rank drops.<br />Think of it as a zoomed in version of eScore.<br /> Use this field to set demand-based criteria for accepting a book as a textbook."
                        className="fa fa-info-circle"
                      />
                    </td>
                    <td>
                      <Form.Control required as="select" name="seasonBumpCount">
                        <option value="spring-and-fall">Spring and Fall</option>
                        <option value="spring">Spring</option>
                        <option value="fall">Fall</option>
                      </Form.Control>
                    </td>
                    <td>
                      <Form.Control
                        defaultValue="0"
                        required
                        type="number"
                        step="0.01"
                        name="valBumpCount"
                        placeholder=""
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>

            <div style={{ paddingTop: "10px", borderTop: "1px solid #ddd", marginTop: "10px" }}>
              <div>If the above criteria are all met, set the Target Price to the following value: </div>
              <div style={{ marginTop: "15px" }}>
                {/* <div style={{ flex: 1 }}>
                  <Form.Check
                    onChange={(e) => this.setState({ isAlwaysReject: e.target.checked })}
                    checked={this.state.isAlwaysReject}
                    type="switch"
                    name="isAlwaysReject"
                    id="always-reject"
                    label="Always Reject?"
                  />
                </div> */}
                <div>
                  <Form.Group controlId="formMinScore" style={{ marginBottom: "0px" }}>
                    <InputGroup id="ig_asin">
                      <InputGroup.Prepend>
                        <InputGroup.Text id="inputGroupPrepend">$</InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        disabled={this.state.isAlwaysReject}
                        required
                        type="number"
                        step="0.01"
                        name="targetPrice"
                      />
                    </InputGroup>
                  </Form.Group>
                </div>
              </div>
            </div>

            <div style={{ paddingTop: "10px", borderTop: "1px solid #ddd", marginTop: "10px" }}>
              <div style={{ marginBottom: "20px" }}>
                If the Textbook module wins relative to all other triggers, select a box color and the text to display
                on the scanning screen:
              </div>

              {this.props.premiumLevel === 1 ? (
                <InputGroup id="ig_triname">
                  <InputGroup.Prepend style={{ margin: "7px" }}>
                    <Dropdown
                      options={ddOptions}
                      onChange={(col) => {
                        this.setState({ currentColor: col.value });
                      }}
                      value={this.state.currentColor !== "" ? this.state.currentColor : "#FFFFFF"}
                    />
                  </InputGroup.Prepend>
                  <Form.Control
                    required
                    type="text"
                    name="triggerName"
                    className="input-text-trigger-name"
                    style={{ borderBottom: "1px solid #000", fontSize: "18px", marginLeft: "30px" }}
                    placeholder="Enter Trigger Name Here"
                  />
                </InputGroup>
              ) : (
                <Form.Control
                  required
                  type="text"
                  name="triggerName"
                  className="input-text-trigger-name"
                  style={{ borderBottom: "1px solid #000", fontSize: "18px", marginLeft: "30px" }}
                  placeholder="Enter Trigger Name Here"
                />
              )}
            </div>
          </Modal.Body>

          <Modal.Footer>
            {this.props.isSavingTrigger === true ? (
              <Spinner animation="border" role="status"></Spinner>
            ) : (
              <Button variant="primary" type="submit">
                Add new
              </Button>
            )}
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

export default ModalAdd;
