import React, { Component } from "react";
import { Row, Col, Form, Modal, Button, InputGroup, Spinner } from "react-bootstrap";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { siteColors } from "../../../../includes/AppConfig";

class ModalAddTrigger extends Component {
  state = {
    currentCSVFile: null,
    taText: "",
    isAlwaysReject: false,
    currentColor: "",
  };

  constructor() {
    super();
    this.onFormSubmitAddTrigger = this.onFormSubmitAddTrigger.bind(this);
    this.onChangeCSVFile = this.onChangeCSVFile.bind(this);
    this.handleChangeTaText = this.handleChangeTaText.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
  }

  handleOpen() {
    this.setState({ currentCSVFile: null, taText: "", isAlwaysReject: false, currentColor: "" });
  }

  async onFormSubmitAddTrigger(event) {
    event.preventDefault();

    // Gather required fields
    const triggerName = event.target.elements.triggerName.value;
    const hitListTarget = event.target.elements.hitListTarget.value;

    new Promise((resolve, reject) => {
      let asins = [];
      if (this.state.currentCSVFile !== null) {
        var reader = new FileReader();
        reader.onload = function (e) {
          const csvLines = e.target.result.split("\n");
          for (let i = 1; i < csvLines.length; i++) {
            const csvValues = csvLines[i].split(",");
            csvValues[0] !== "" && csvValues[0] !== undefined && asins.push(csvValues[0].replace(/\D/g, ""));
          }
          resolve(asins);
        };
        reader.readAsText(this.state.currentCSVFile);
      } else resolve(asins);
    }).then(async (res) => {
      const arrISBNS = this.state.taText.split("\n");
      const arrISBNsFiltered = arrISBNS.filter((e1) => e1 !== "");
      const isbnList = res.concat(arrISBNsFiltered);
      await this.props.onFormSubmitAddTrigger(
        triggerName,
        hitListTarget,
        isbnList,
        this.state.isAlwaysReject,
        this.state.currentColor
      );
      this.handleOpen();
    });
  }

  onChangeCSVFile = (e) => {
    if (e.target.files[0] !== undefined) {
      this.setState({ currentCSVFile: e.target.files[0] });
    }
  };

  handleChangeTaText = (e) => {
    this.setState({ taText: e.target.value });
  };

  render() {
    const ddOptions = siteColors.map((sc) => {
      return { value: sc, label: <div style={{ width: "20px", height: "20px", background: `${sc}` }}></div> };
    });

    return (
      <Modal
        onEntered={this.handleOpen}
        show={this.props.showAddModal}
        onHide={this.props.handleClose}
        dialogClassName="editModal"
      >
        <Form onSubmit={this.onFormSubmitAddTrigger}>
          <Modal.Header closeButton>
            {this.props.premiumLevel === 1 ? (
              <InputGroup id="ig_triname">
                <InputGroup.Prepend style={{ margin: "7px" }}>
                  <Dropdown
                    options={ddOptions}
                    onChange={(col) => {
                      this.setState({ currentColor: col.value });
                    }}
                    value={this.state.currentColor !== "" ? this.state.currentColor : "#FFFFFF"}
                  />
                </InputGroup.Prepend>
                <Form.Control
                  required
                  type="text"
                  name="triggerName"
                  className="input-text-trigger-name"
                  placeholder="Enter Trigger Name Here"
                />
              </InputGroup>
            ) : (
              <Form.Control
                required
                type="text"
                name="triggerName"
                className="input-text-trigger-name"
                placeholder="Enter Trigger Name Here"
              />
            )}
          </Modal.Header>
          <Modal.Body>
            <div style={{ fontSize: "13px", fontWeight: "500" }}>
              <Row>
                <Col md={12}>
                  <div>
                    <Form.Group controlId="formMinScore">
                      <Form.Label>Target Price</Form.Label>
                      <InputGroup id="ig_asin">
                        <InputGroup.Prepend>
                          <InputGroup.Text id="inputGroupPrepend">$</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control required type="number" step="0.01" name="hitListTarget" />
                      </InputGroup>
                    </Form.Group>
                  </div>

                  <Row style={{ marginTop: "20px" }}>
                    <Col md={12}>
                      <div>
                        <Form.Group controlId="formAudioFile">
                          <Form.Label>Choose .csv File</Form.Label>
                          <Form.Control
                            onChange={this.onChangeCSVFile}
                            type="file"
                            name="csvFile"
                            placeholder="Choose .csv File"
                          />
                        </Form.Group>
                      </div>
                      <div>
                        <div style={{ padding: "0 0 10px 0" }}>AND/OR</div>
                        <Form.Group controlId="exampleForm.ControlTextarea1">
                          <Form.Label>Enter list of all ISBNs in new lines</Form.Label>
                          <Form.Control
                            value={this.state.taText}
                            as="textarea"
                            rows="8"
                            onChange={this.handleChangeTaText}
                          />
                        </Form.Group>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6}>
                      <Form.Check
                        onChange={(e) => this.setState({ isAlwaysReject: e.target.checked })}
                        checked={this.state.isAlwaysReject}
                        type="switch"
                        name="isAlwaysReject"
                        id="always-reject"
                        label="Always Reject?"
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {/* <Button variant="primary" type="submit">
              Save Changes
            </Button> */}

            {this.props.isSavingTrigger === true ? (
              <Spinner animation="border" role="status"></Spinner>
            ) : (
              <Button variant="primary" type="submit">
                Save Changes
              </Button>
            )}
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

export default ModalAddTrigger;
