import React, { Component } from "react";
import { Row, Col, Form, Modal, Button, Spinner } from "react-bootstrap";

class ModalEditNotes extends Component {
  state = {};

  render() {
    return (
      <React.Fragment>
        <Modal
          onEntered={this.handleOpen}
          show={this.props.showEditNotesModal}
          onHide={this.props.handleClose}
          dialogClassName="editModal"
        >
          <Form onSubmit={this.props.onFormSubmitSaveNotes}>
            <Modal.Header closeButton>
              <h3>Notes # {this.props.selectedInvite && this.props.selectedInvite.team_name}</h3>
            </Modal.Header>
            <Modal.Body>
              <Form.Control
                defaultValue={this.props.selectedInvite ? this.props.selectedInvite.notes : ""}
                name="notes"
                as="textarea"
                rows={4}
              />
            </Modal.Body>

            <Modal.Footer>
              {this.props.isSavingNote === true ? (
                <Spinner animation="border" role="status"></Spinner>
              ) : (
                <Button variant="primary" type="submit">
                  Save
                </Button>
              )}
            </Modal.Footer>
          </Form>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ModalEditNotes;
