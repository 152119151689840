import React, { Component } from "react";
import { Row, Col, Form, Modal, Button, InputGroup } from "react-bootstrap";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { siteColors } from "../../../../includes/AppConfig";

class ModalEditTrigger extends Component {
  state = {
    selectedCategories: [],
    currentColor: "",
  };

  constructor() {
    super();
    this.onFormSubmitEditTrigger = this.onFormSubmitEditTrigger.bind(this);
    this.categoryCheckBox = this.categoryCheckBox.bind(this);
    this.setDefaultCategories = this.setDefaultCategories.bind(this);
  }

  onChange(e) {
    let selectedCategoriesClone = this.props.editTrigger.jsonData.categories;
    if (e.target.checked) {
      // add the numerical value of the checkbox to options array
      const indexInCategories = this.props.categoryList.findIndex((i) => {
        return i.id === +e.target.value;
      });
      const catName = this.props.categoryList[indexInCategories].name;
      selectedCategoriesClone.push({ id: +e.target.value, name: catName });
    } else {
      // or remove the value from the unchecked checkbox from the array
      const index = selectedCategoriesClone.indexOf(+e.target.value);
      selectedCategoriesClone.splice(index, 1);
    }
    // update the state with the new array of options
    this.setState({ selectedCategories: selectedCategoriesClone });
  }

  categoryCheckBox(props) {
    return (
      <Form.Group key={"cat" + props.category.id} controlId="formCategories">
        <Form.Check
          defaultChecked={
            props.editTrigger.jsonData !== undefined && props.editTrigger.catids.indexOf(props.category.id) > -1
          }
          disabled={props.disabled}
          type="switch"
          style={{ color: props.category.id === 0 ? "red" : "black" }}
          value={props.category.id}
          onChange={this.onChange.bind(this)}
          id={"cat" + props.category.id}
          label={props.category.name}
        />
      </Form.Group>
    );
  }

  setDefaultCategories() {
    this.setState({
      selectedCategories: this.props.editTrigger.jsonData.categories,
    });
    if ("custom_color" in this.props.editTrigger.jsonData)
      this.setState({
        currentColor: this.props.editTrigger.jsonData.custom_color,
      });
    else
      this.setState({
        currentColor: "",
      });
  }

  onFormSubmitEditTrigger(event) {
    event.preventDefault();

    // Gather required fields
    const triggerName = event.target.elements.triggerName.value;
    const triggerJson = {
      categories: this.state.selectedCategories,
      min_rank: event.target.elements.minRank.value,
      max_rank: event.target.elements.maxRank.value,
      min_escore: event.target.elements.minEScore.value,
      max_escore: event.target.elements.maxEScore.value,
      price_comparison: event.target.elements.priceComparison.value,
      price_val: event.target.elements.priceVal.value,
      custom_color: this.state.currentColor,
    };

    this.props.onFormSubmitEditTrigger(triggerName, triggerJson);
  }
  render() {
    const ddOptions = siteColors.map((sc) => {
      return { value: sc, label: <div style={{ width: "20px", height: "20px", background: `${sc}` }}></div> };
    });

    return (
      <Modal
        show={this.props.showEditModal}
        onHide={this.props.handleClose}
        onEntered={this.setDefaultCategories}
        dialogClassName="editModal"
      >
        <Form onSubmit={this.onFormSubmitEditTrigger}>
          <Modal.Header closeButton>
            {this.props.premiumLevel === 1 ? (
              <InputGroup id="ig_triname">
                <InputGroup.Prepend style={{ margin: "7px" }}>
                  <Dropdown
                    options={ddOptions}
                    onChange={(col) => {
                      this.setState({ currentColor: col.value });
                    }}
                    value={this.state.currentColor !== "" ? this.state.currentColor : "#FFFFFF"}
                  />
                </InputGroup.Prepend>
                <Form.Control
                  required
                  type="text"
                  name="triggerName"
                  className="input-text-trigger-name"
                  defaultValue={this.props.editTrigger.TriggerName}
                  placeholder="Enter Trigger Name Here"
                />
              </InputGroup>
            ) : (
              <Form.Control
                required
                type="text"
                name="triggerName"
                className="input-text-trigger-name"
                defaultValue={this.props.editTrigger.TriggerName}
                placeholder="Enter Trigger Name Here"
              />
            )}
          </Modal.Header>
          <Modal.Body>
            <div style={{ fontSize: "13px", fontWeight: "500" }}>
              <Row>
                <Col md={4}>
                  {this.props.categoryList !== undefined &&
                    this.props.categoryList.length >= 1 &&
                    this.props.categoryList.slice(0, 10).map((category, _index) => {
                      return (
                        <this.categoryCheckBox
                          key={"cr1-" + category.id}
                          category={category}
                          editTrigger={this.props.editTrigger}
                        />
                      );
                    })}
                </Col>
                <Col md={4}>
                  {this.props.categoryList !== undefined &&
                    this.props.categoryList.length >= 1 &&
                    this.props.categoryList.slice(11, 21).map((category, _index) => {
                      return (
                        <this.categoryCheckBox
                          key={"cr2-" + category.id}
                          category={category}
                          editTrigger={this.props.editTrigger}
                        />
                      );
                    })}
                </Col>
                <Col md={4}>
                  {this.props.categoryList !== undefined &&
                    this.props.categoryList.length >= 1 &&
                    this.props.categoryList.slice(22, 32).map((category, _index) => {
                      return (
                        <this.categoryCheckBox
                          key={"cr3-" + category.id}
                          category={category}
                          editTrigger={this.props.editTrigger}
                        />
                      );
                    })}
                </Col>
              </Row>

              <Row style={{ marginTop: "10px", borderTop: "1px solid #ccc", paddingTop: "10px" }}>
                <Col md={3}>
                  <Form.Group controlId="formMinEScore">
                    <Form.Label>Min eScore</Form.Label>
                    <Form.Control
                      required
                      type="number"
                      step="0.01"
                      name="minEScore"
                      placeholder="Min eScore"
                      defaultValue={
                        this.props.editTrigger.jsonData !== undefined && "min_escore" in this.props.editTrigger.jsonData
                          ? this.props.editTrigger.jsonData.min_escore
                          : 0
                      }
                    />
                  </Form.Group>
                </Col>
                <Col md={3} style={{ borderRight: "1px solid #ccc" }}>
                  <Form.Group controlId="formMaxEScore">
                    <Form.Label>Max eScore</Form.Label>
                    <Form.Control
                      required
                      type="number"
                      step="0.01"
                      name="maxEScore"
                      placeholder="Max eScore"
                      defaultValue={
                        this.props.editTrigger.jsonData !== undefined && "max_escore" in this.props.editTrigger.jsonData
                          ? this.props.editTrigger.jsonData.max_escore
                          : 0
                      }
                    />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group controlId="formMinRank">
                    <Form.Label>Min SalesRank</Form.Label>
                    <Form.Control
                      required
                      type="number"
                      step="0.01"
                      name="minRank"
                      placeholder="Min SalesRank"
                      defaultValue={
                        this.props.editTrigger.jsonData !== undefined && "min_rank" in this.props.editTrigger.jsonData
                          ? this.props.editTrigger.jsonData.min_rank
                          : 0
                      }
                    />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group controlId="formMaxRank">
                    <Form.Label>Max SalesRank</Form.Label>
                    <Form.Control
                      required
                      type="number"
                      step="0.01"
                      name="maxRank"
                      placeholder="Max SalesRank"
                      defaultValue={
                        this.props.editTrigger.jsonData !== undefined && "max_rank" in this.props.editTrigger.jsonData
                          ? this.props.editTrigger.jsonData.max_rank
                          : 0
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <Form.Group controlId="formCeiling1Options">
                    <Form.Label>Comparison Type</Form.Label>
                    <Form.Control
                      as="select"
                      name="priceComparison"
                      defaultValue={
                        this.props.editTrigger.jsonData !== undefined &&
                        "price_comparison" in this.props.editTrigger.jsonData
                          ? this.props.editTrigger.jsonData.price_comparison
                          : ""
                      }
                    >
                      <option value="Fixed Price">Fixed Price</option>
                      <option value="Price Per Pound">Price Per Pound</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="formMaxRank">
                    <Form.Label>Price</Form.Label>
                    <Form.Control
                      required
                      type="number"
                      step="0.01"
                      name="priceVal"
                      defaultValue={
                        this.props.editTrigger.jsonData !== undefined && "price_val" in this.props.editTrigger.jsonData
                          ? this.props.editTrigger.jsonData.price_val
                          : 0
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.props.handleClose}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              Save Changes
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

export default ModalEditTrigger;
